import React, { Component } from "react";
import conf from '../../config.json';
import { addHypensAccountNum, numberWithCommas } from '../global/formatter';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { showSpinner } from './../global/loader';
import { withRouter } from "../withRouter";
class ReviewPledge extends Component {

    state = {
        noDataFlag: true,
        disabledFlag: false,
        error_display: false
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        await Auth.currentAuthenticatedUser();
        if (this.props.location.state !== undefined && this.props.location.state !== null)
            this.setState({ noDataFlag: false});
    }
    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    handleRedirect = () => {
        try{
        if (!this.state.noDataFlag) {
            //console.log("submitFunction::");
            this.props.navigate('/makeapledge', {state: {
                'pledgeList': this.props.location.state.pledgeList,
                'totalPledgeAmount': this.props.location.state.totalPledgeAmount,
                'addresssummaryresp': this.props.location.state.addresssummaryresp,
                'addresslookupresp': this.props.location.state.addresslookupresp,
                'selectedAddress': this.props.location.state.selectedAddress,
                'programs': this.props.location.state.programs,
                'fromAccSumm': false,
                'originalState': this.props.location.state.originalState
            }});
        }
    }catch(err) {
        console.log(err);
        this.handleLogOut();
    }
    }

    submitFunction = async event => {
        this.setState({ disabledFlag: true });
        this.checkPledgeFromShadowService();
    }

    checkPledgeFromShadowService() {
        showSpinner(true);
        const sampleHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': conf.accountSearchShadowService.token
        };
        const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "agencypledgeconsolidated");
        let locale= this.props.auth.user["attributes"].locale;
        let zoneinfo = this.props.auth.user["attributes"].zoneinfo;
        let username = this.props.auth["user"].username;
        if(zoneinfo === undefined)
            zoneinfo = 0;
        if(locale === undefined) {
            locale= 0;
            username = String(username).split(".").pop();
        }
        const params = {
            account_num: this.props.location.state.selectedAddress.AccountNum,
            cust_first_nm: String(this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm).replaceAll("'", "''"),
            cust_last_nm: String(this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm).replaceAll("'", "''"),
            username: username,
            agencyid: zoneinfo,
            agency_location_id: locale,
            deposit_amt: this.props.location.state.addresssummaryresp["AmountsDue"].deposit_due_at,
            pledgeinfo: JSON.stringify(this.props.location.state.pledgeList)
        };

        console.log(JSON.stringify(params) + "params");
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: sampleHeaders
                });
                const json_resp = await response.json();
                console.log(JSON.stringify(json_resp) + "response::");
                let array = json_resp.PledgeResponse;
                let flagSuccess = true;
                for (var i = 0; i < array.length; i++) {
                    const subArray = array[i][i];
                    console.log(JSON.stringify(subArray) + "temp::::");
                    for (var j = 0; j < subArray.length; j++) {
                        if (subArray[j].returnCode !== 'OK' || subArray[j].errorMessageCode !== '' || (subArray[j].errorMessageText !== '' && subArray[j].errorMessageText !== "CREATE ASSISTANCE COMMITMENT/PROMISE") && (subArray[j].AgencyPledgeType === 'AddCreditExtension' || subArray[j].AgencyPledgeType === 'AddAgencyPledge')) {
                            flagSuccess = false;
                            break;
                        }
                    }
                }

                if (flagSuccess) {
                    try{
                    this.props.navigate("/confirmationpledge",
                        {state: {
                            'addresssummaryresp': this.props.location.state.addresssummaryresp,
                            'addresslookupresp': this.props.location.state.addresslookupresp,
                            'selectedAddress': this.props.location.state.selectedAddress,
                            'pledgeList': this.props.location.state.pledgeList,
                            'totalPledgeAmount': this.props.location.state.totalPledgeAmount,
                            'dateSubmitted': moment().format("LL")
                        }});
                    }catch(err) {
                        console.log(err);
                        this.handleLogOut();
                    }
                }
                else {
                    this.setState({ disabledFlag: false, error_display: true });
                }
            }
            catch (error) {
                console.log(error);
                this.setState({ error_display: true });
            }
            showSpinner(false);
        })();
    }

    cancelPledge = (event) => {
        try{
        this.props.navigate( '/accountsummary', {state: this.props.location.state.originalState} );
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    render() {
        return (
            <section className="section auth">
                <div id="main" class="container">
                    <h1>Make a Pledge</h1>
                    <div id="cphContentMain_ctl00_Review_Pledge">

                        <div class="col-sm-10 col-md-10 col-xl-10 no-margins">
                            <span id="main_block">

                                <div role="form" class="bordered-form">
                                    {this.state.error_display &&
                                        <div class="row">
                                            <div class="col-12 error mb-30">
                                                <div class="error">
                                                    <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact the Customer Operations Center, at <a href="tel:18009564237" aria-label="8 0 0. 9 5 6. 4 2 3 7">1-800-956-4237</a> for assistance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="review_form_header mb-30">
                                        <h3 class="mb-20">Review your pledge details.</h3>
                                        <p> Unless you  <button class="button-anchor" onClick={this.handleRedirect}>need to make changes</button>, hit Submit Pledge.</p>
                                    </div>
                                    <div class="review_form_subheader">
                                        <div class="sub-block mb-30">
                                            <p class="mb-10 font-weight-bold">Customer Details:</p>
                                            <div class="two-block">
                                                <p class="first">Name:</p>
                                                <p class="second">
                                                    {!this.state.noDataFlag &&
                                                        (<span> {this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="two-block">
                                                <p class="first">Service Address:</p>
                                                <p class="second">
                                                    {!this.state.noDataFlag &&
                                                        (<span>
                                                            {this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} {this.props.location.state.selectedAddress.UnitDesignator} {this.props.location.state.selectedAddress.UnitNumber}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="two-block">
                                                <p class="first"></p>
                                                <p class="second mb-20">
                                                    {!this.state.noDataFlag &&
                                                        (<span>
                                                            {this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="mb-40 two-block">
                                                <p class="first">Account Number:</p>
                                                <p class="second">
                                                    {!this.state.noDataFlag && (<span>{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) + '-' + this.props.location.state.selectedAddress.BillCheckDigitNb}</span>)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review_form_body">
                                        <p class="mb-10 font-weight-bold">Pledge Details:</p>

                                        {!this.state.noDataFlag && (
                                            <div>
                                                {Object.entries(this.props.location.state.pledgeList).map(([index, pledgeInfo]) => (

                                                    <div class="wrapper-block mb-40" key={index}>
                                                        <hr class="divider-sm mb-35" />
                                                        <div class="form-row" id="DivRowSelectProgram">
                                                            <div class="col">
                                                                <div id="agency_select_wrapper">
                                                                    <div class="input-group two-block">
                                                                        <p class="first">Program:</p>
                                                                        <p class="second">{pledgeInfo.selectProgram}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-row" id="DivRowPledgeAmount">
                                                            <div class="col">
                                                                <div id="agency_pledge_amount_wrapper">
                                                                    <div class="input-group two-block">
                                                                        <p class="first">Pledge Amount:</p>
                                                                        <p class="second">${numberWithCommas(pledgeInfo.pledge_amt)}</p>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>

                                                        {pledgeInfo.transactionID && (<div class="form-row clear-both" id="DivRowAgencyTransID">
                                                            <div class="col">
                                                                <div id="agency_tran_ID_wrapper">
                                                                    <div class="input-group two-block highlighter">
                                                                        <p class="first">Agency Transaction ID:</p>
                                                                        <p class="second">{pledgeInfo.transactionID}</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>)}


                                                        {pledgeInfo.dtlcasedocid && (<div class="form-row" id="DivRowCaseDocID">
                                                            <div class="col">
                                                                <div id="case_document_ID_wrapper" class="mb-30">
                                                                    <div class="input-group two-block highlighter">
                                                                        <p class="first">Case/Document ID:</p>
                                                                        <p class="second">{pledgeInfo.dtlcasedocid}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>

                                                ))}
                                            </div>)}

                                        <div class="row mb-10">
                                            <div class="col">
                                                <h4 class="mb-40">Pledge Total: $ {!this.state.noDataFlag && (<span>{numberWithCommas(this.props.location.state.totalPledgeAmount)}</span>)}</h4>
                                                <button class="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.noDataFlag || this.state.disabledFlag}>Submit Pledge</button>
                                            </div>
                                        </div>

                                        <div class="row mb-10">
                                            <div class="col button">
                                                <button class="btn button-without-border" onClick={this.cancelPledge} style={{
                                                    fontSize: '17px', padding: '0 0 17px',
                                                    textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                }}>Cancel Pledge</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </span>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}


export default withRouter(ReviewPledge);
