import * as AgencyRoleConstants from '../constants/AgencyRoleConstants';
import conf from '../../config';

export function addHypensPhonenumber(phone_num) {
    if (phone_num !== '' && phone_num !== undefined && (OnlyNumbers(phone_num)).length === 10) {
        let phone_num_val = OnlyNumbers(phone_num);
        phone_num_val = phone_num_val.slice(0, 3) + "-" + phone_num_val.slice(3, 6) + "-" + phone_num_val.slice(6);
        return phone_num_val;
    }
    return 'N/A';
}
export function addDottedPhonenumber(phone_num) {
    if (phone_num !== '' && phone_num !== undefined && (OnlyNumbers(phone_num)).length === 10) {
        let phone_num_val = OnlyNumbers(phone_num);
        phone_num_val = phone_num_val.slice(0, 3) + "." + phone_num_val.slice(3, 6) + "." + phone_num_val.slice(6);
        return phone_num_val;
    }
    return 'N/A';
}

export function addHypensAccountNum(accNum) {
    if (accNum !== '' && accNum !== undefined) {
        let f_val = OnlyNumbers(accNum);
        return f_val.match(/.{1,3}/g).join("-");
    }
    return accNum;
}

export function OnlyNumbers(num) {
    return num.replace(/\D[^.]/g, "");
}

export function OnlyDigits(num) {
    return num.replace(/\D/g, "");
}

export function translateDate(date) {
    if (date !== '' && date !== undefined) {
        let ye = new Date(date).getUTCFullYear();
        let mo = new Date(date).getUTCMonth() + 1;
        let da = new Date(date).getUTCDate();
        return `${mo}/${da}/${ye}`;
    }
    return date;
}

export function dateWithSlashes(date) {
    if (date !== '' && date !== undefined) {
        let ye = new Date(date).getUTCFullYear();
        let mo = new Date(date).getUTCMonth() + 1;
        let da = new Date(date).getUTCDate();
        return `${mo}/${da}/${ye}`;
    }
    return date;
}

export function dateWithDashes(date) {
    if (date !== '' && date !== undefined) {
        let ye = new Date(date).getUTCFullYear().toString().substr(-2);
        let mo = new Date(date).getUTCMonth() + 1;
        let da = new Date(date).getUTCDate();
        return `${mo}-${da}-${ye}`;
    }
    return date;
}

export function addBusinessDays(d, n) {
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
    return d;
}

export function addCalendarDays(d, n) {
    d = new Date(d.getTime());
    d.setDate(d.getDate() + n);
    return d;
}

export function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export function returnDateTimeWithDashes() {
    var date = new Date;
    let ye = new Date(date).getUTCFullYear();
    let mo = new Date(date).getUTCMonth() + 1;
    let da = new Date(date).getUTCDate();
    let hh = new Date(date).getUTCHours()
    let mm = new Date(date).getUTCMinutes();
    let ss = new Date(date).getUTCSeconds();
    return `${ye}-${addZero(mo)}-${addZero(da)} ${addZero(hh)}:${addZero(mm)}:${addZero(ss)}`;

}

export function numberWithCommasWithLeadingZeroes(x) {
    return x.toString().replace(/^0+/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommas(x) {
    if (x !== '' && x !== undefined)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return x;
}

export function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function zeroFormat(number) {
    if (number == "" || number == null) {
        number = 0;
        return number;
    }
    return number;
}

// DATES
export function toLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON();
}

export function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
}

export function checkIfBetweenTwoDates(date, start, end) {
    let dateFrom = start;
    let dateTo = end;
    let dateCheck = date;
    let from = new Date(dateFrom);
    let to = new Date(dateTo);
    let check = new Date(dateCheck);
    return (check >= from && check <= to);
}

export function checkIfAfterBeginDate(date) {
    let dateBegin = conf.beginDateOHOpenSchedule; //"07/13/2023 00:00:00"; //TODO: Set begin date in config
    let dateCheck = date;
    let begin = new Date(dateBegin);
    let check = new Date(dateCheck);
    return (check >= begin);
}

// STRINGS
export function capitalizeFirstLetter(string) {
    if (string !== undefined && string !== '') {
        string = string.toLowerCase();
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    return string;
}

export function camelCase(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
};

export function validateEmail(email) {
    if (email !== undefined && email !== '') {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return email;
}

export function setAgencyRole(value) {
    if (value === AgencyRoleConstants.AGENCY_USER_SIMPLE_TXT_ROLE)
        return AgencyRoleConstants.AGENCY_USER_ROLE;
    else if (value === AgencyRoleConstants.AGENCY_ADMIN_SIMPLE_TXT_ROLE)
        return AgencyRoleConstants.AGENCY_ADMIN_ROLE;
}

export function reverseAgencyRole(value) {
    if (value === AgencyRoleConstants.AGENCY_USER_ROLE)
        return AgencyRoleConstants.AGENCY_USER_SIMPLE_TXT_ROLE;
    else if (value === AgencyRoleConstants.AGENCY_ADMIN_ROLE)
        return AgencyRoleConstants.AGENCY_ADMIN_SIMPLE_TXT_ROLE;
}


export function uniqueID() {
    return Math.floor(Math.random() * Date.now());
}

export function returnTimestamp() {
    var d = new Date,
        dformat = [(d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft()].join(':');
    return dformat;
}

Number.prototype.padLeft = function (base, chr) {
    var len = (String(base || 10).length - String(this).length) + 1;
    return len > 0 ? new Array(len).join(chr || '0') + this : this;
}

export function formatDateFromDDToMM(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        var day = date.getDate().toString();
        var month = (date.getMonth() + 1).toString();
        // Months use 0 index.

        return (month[1] ? month : '0' + month[0]) + '/' +
            (day[1] ? day : '0' + day[0]) + '/' +
            date.getFullYear();
    }
}

export function formatToMMDDYYYY(inputDate) {
    let input = inputDate + " UTC";
    if (inputDate !== 'N/A') {
        var date = new Date(input);
        if (!isNaN(date.getTime()))
            return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
    return 'N/A';
}

export function getUserDetails(email) {
    const serviceHeader = {
        'Content-Type': conf.agencyDBService.header.contentType,
        'x-api-key': conf.agencyDBService.header.key,
        'Connection': conf.agencyDBService.header.connection
    };
    var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let tableName = ""// "GAA_USER"
    // let userlist = event.userlist
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let formattedquery = "SELECT U.user_id, U.first_name, U.last_name,U.phone, U.email, U.agency_id,U.role, U.token_flag as agency_location_id, L.agency_id, L.name, L.street_address1, L.street_address2, L.city, L.state, L.zip_code, L.phone FROM agency.GAA_USER U INNER JOIN agency.AGENCY_LOCATION L ON U.token_flag::text = L.agency_location_id::text WHERE U.user_id ='" + email + "'";
    console.log(formattedquery)
    const params2 = {
        TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));

    return new Promise((resolve, reject) => {
        fetch(agencyServiceEndpoint, {
            method: 'POST',
            headers: serviceHeader
        })
            .then(res => res.json())
            .then(
                (data) => {
                    console.log(data);
                    if (data.length > 0)
                        resolve(data[0]);
                    else
                        resolve([]);
                },
                (error) => {
                    console.log(error)
                    reject([]);
                }
            );
    });
}

export function filterColumns(data) {
    // Get column names
    const columns = Object.keys(data[0]);

    // Remove by key
    const filterColsByKey = columns.filter(c => c);

    // OR use the below line instead of the above if you want to filter by index
    // columns.shift()

    return filterColsByKey // OR return columns
};