import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { validateEmail } from '../global/formatter';
import { showSpinner } from '../global/loader';
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';


class ForgotPassword extends Component {
  state = {
    DidSubmit: false,
    fields: {
      isDisabledFlag: true,
      email: '',
      errors: {},
      touched: {}
    }
  }

  resendEmailHandler = async event => {
    this.setState({
      DidSubmit: false
    });
  }

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    fields[name] = value;
    fields["touched"][name] = true;
    this.setState({ fields },
      () => { this.validateField(name, value) });
  }

  validateField = (fieldName, value) => {
    let fields = this.state.fields;
    const isEmpty = value.length === 0;
    switch (fieldName) {
      case 'email':
        if (isEmpty)
          fields["errors"]["email"] = "Please enter an email address.";
        else if (!validateEmail(value))
          fields["errors"]["email"] = "Please enter a valid email address.";
        else
          fields["errors"]["email"] = "";
        break;
      default:
        break;
    }
    this.setState({ fields }, () => {
      this.setValidFlag();
    });
  }

  setValidFlag = () => {
    let fields = this.state.fields;
    let flag = true;
    for (var key of Object.keys(fields["errors"])) {
      if (fields["errors"][key] !== '') {
        flag = false;
        break;
      }
    }
    if (!flag)
      fields["isDisabledFlag"] = true;
    else
      fields["isDisabledFlag"] = false;
    this.setState({ fields });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();
    let fields = this.state.fields;
    fields["isDisabledFlag"] = true;
    this.setState({ fields });
    showSpinner(true);
    try {
      await Auth.forgotPassword(this.state.fields.email);
      this.setState({
        DidSubmit: true
      });
      showSpinner(false);
    } catch (error) {
      console.log(error);
      if (error.message == "Username/client id combination not found.")
        fields["errors"]["email"] = "Please enter a valid email address.";
      else
        fields["errors"]["email"] = error.message
      this.setState({ fields });
      showSpinner(false);
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {

    if (this.state.DidSubmit) {
      return (
        <div class="section">
          <div className="container">
            <h1>Forgot Password</h1>
            <div class="bordered-form col-sm-9">
              <div class="row ml-30 mb-20">
                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><div class="float-left mt-1 ml-20"><b>Password reset information is on its way! Please allow a few minutes to receive the email.</b></div>
              </div>
              <div class="row ml-30 mb-10">We sent password reset information and instructions to:</div>
              <div class="row ml-30 mb-10"><b>{this.state.fields.email}</b></div>
              <div class="row ml-30 mb-10"><b>Email incorrect?</b>&nbsp; You must <a href="/register">&nbsp;register again&nbsp; </a> with the correct email.</div>
            </div>
          </div>
        </div>
      );
    } else if (!this.state.DidSubmit) {
      return (
        <section className="section auth">
          <div className="container">
            <h1>Forgot Password</h1>
            <div class="bordered-form col-sm-9">
              <p class="mb-30">
                <b>To reset your password, enter your email.</b>
              </p>
              <div class="form-row mb-35">
                <div class="col-12 col-md-8">
                  <div id="cphContentMain_email_cphContentMain_email_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                    <div class="input-group">
                      <input name="email" type="email" required id='cphContentMain_email' class="form-control" data-field-type="text" value={this.state.fields["email"]} onChange={this.handleChange} placeholder="Email Address" />
                      <label htmlFor='cphContentMain_email'>Email Address*</label>
                    </div>
                    {this.state.fields["errors"]["email"] !== '' && (<span id="cphContentMain_email_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_email" >{this.state.fields["errors"]["email"]}</span>)}
                  </div>
                </div>
              </div>
              <div className="field">
                <p className="control">
                  <button class="btn btn-primary mr-20" onClick={this.forgotPasswordHandler} disabled={this.state.fields["isDisabledFlag"]}>
                    Send reset
                  </button>
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}


export default ForgotPassword;
