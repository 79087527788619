import React, { Component } from "react";
import conf from "../../config.json";
import { addHypensAccountNum, numberWithCommas, dateWithSlashes } from "../global/formatter";
import moment from "moment";
import { Auth } from "aws-amplify";
import { showSpinner } from "./../global/loader";
import { withRouter } from "../withRouter";

const groupName = conf.cognito.USER_POOL_ID + "_aep";
let globalId = 0;
class AddEnergyAssistance extends Component {
	state = {
		noDataFlag: true,
		disabledFlag: false,
		error_display: false,
		totalPledgeAmount: 0.0,
		response: { programs: [] },
		agency_details: {},
		isAccountDisconnected: false,
		disconnectAmt: "",
		disconnectDate: "",
		pledgeInfo: [
			{
				id: globalId++,
				selectProgram: "Energy Assistance",
				setErrorSelectProgram: false,
				setErrorPledgeAmount: false,
				touchAmount: true,
				touchSelect: true,
				flagRemove: false,
				transactionID: "EAP" + moment().format("DDMMYYYY"),
				asstpgmcode: "999",
				apmn_fl: "N",
				apply_credit_fl: "N",
				srdr_fl: "N",
				mfcc_fl: "Y",
				pace_fl: "N",
				pledge_amt: "0",
				auth_remarks: "",
				dtlcasedocid: "",
				statecode: "IN",
				remarkstxt: "",
				macss_code: "HEAP"
			},
		],
	};

	async componentDidMount() {
		window.scrollTo(0, 0);
		await Auth.currentAuthenticatedUser();
		if (this.props.location.state !== undefined && this.props.location.state !== null){
			this.setState({ noDataFlag: false, originalState: this.props.location.state.originalState });
		} else {
			this.setState({ noDataFlag: true, isDisabledFlag: true });
		}
		let userGroup = "";
		if (this.props.auth.user != null) {
			userGroup = this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"];
			if (String(userGroup) === groupName) this.callAgencyAEPAdmin();
			else this.callAgencyInformation();
			this.setDisconnectValues();
		}
	}

	callAgencyAEPAdmin = () => {
		let agency_det = {};
		agency_det["name"] = "ALL";
		agency_det["city"] = "ALL";
		this.setState({
			agency_details: agency_det,
		});
	};

	callAgencyInformation = () => {
		let agency_det = {};
		console.log("this.props: ", this.props);
		agency_det["name"] = this.props.auth.user["attributes"].name;
		agency_det["city"] = this.props.auth.user["attributes"].picture;
		this.setState({
			agency_details: agency_det,
		});
	};

	setDisconnectValues = () => {
		const acct_stat_cd = this.props.location.state.addresssummaryresp.CustomerData.acct_stat_cd;
		const discdate1 = this.props.location.state.selectedAddress.Disconnectdate_1;
		const discamt1 = this.props.location.state.selectedAddress.Disconnectamount_1;
		const discdate2 = this.props.location.state.selectedAddress.Disconnectdate_2;
		const discamt2 = this.props.location.state.selectedAddress.Disconnectamount_2;
		if (acct_stat_cd === "A" || acct_stat_cd === "P" || acct_stat_cd === "N") {
			this.setState({ isAccountDisconnected: true }, () => {
				console.log("discamt1: ", discamt1, " -- discdate1: ", discdate1);
				if (discamt1 > 0 && discamt1 !== "" && discamt1 !== undefined)
					this.setState({
						disconnectAmt: numberWithCommas(discamt1),
						disconnectDate: dateWithSlashes(discdate1),
					});
				else if (discamt2 > 0 && discamt2 !== "" && discamt2 !== undefined)
					this.setState({
						disconnectAmt: numberWithCommas(discamt2),
						disconnectDate: dateWithSlashes(discdate2),
					});
				else this.setState({ isAccountDisconnected: false });
			});
		}
	};

  submitFunction = async (event) => {
		this.setState({ disabledFlag: true });
		this.checkPledgeFromShadowService();
	};

	checkPledgeFromShadowService() {
		showSpinner(true);
		const sampleHeaders = {
			"Content-Type": "application/json",
			"x-api-key": conf.accountSearchShadowService.token,
		};
		const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "agencypledgeconsolidated");
		let locale = this.props.auth.user["attributes"].locale;
		let zoneinfo = this.props.auth.user["attributes"].zoneinfo;
		let username = this.props.auth["user"].username;
		if (zoneinfo === undefined) zoneinfo = 0;
		if (locale === undefined) {
			locale = 0;
			username = String(username).split(".").pop();
		}
		const params = {
			account_num: this.props.location.state.selectedAddress.AccountNum,
			cust_first_nm: String(this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm).replaceAll("'", "''"),
			cust_last_nm: String(this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm).replaceAll("'", "''"),
			username: username,
			agencyid: zoneinfo,
			agency_location_id: locale,
			deposit_amt: this.props.location.state.addresssummaryresp["AmountsDue"].deposit_due_at,
			pledgeinfo: JSON.stringify(this.state.pledgeInfo),
		};
		console.log("params: ", params);

		Object.keys(params).forEach((key) => shadowservicegenericURL.searchParams.append(key, params[key]));
		(async () => {
			try {
				const response = await fetch(shadowservicegenericURL, {
					method: "POST",
					headers: sampleHeaders,
				});
				const json_resp = await response.json();
				let array = json_resp.PledgeResponse;
				let flagSuccess = true;
				for (var i = 0; i < array.length; i++) {
					const subArray = array[i][i];
					console.log("temp:::: ", JSON.stringify(subArray));
					for (var j = 0; j < subArray.length; j++) {
						console.log(
							"subArray[j]: ",
							subArray[j].returnCode,
							" ",
							subArray[j].errorMessageCode,
							" ",
							subArray[j].errorMessageText
						);
						if (
							subArray[j].returnCode !== "OK" ||
							subArray[j].errorMessageCode !== "" ||
							(subArray[j].errorMessageText !== "" &&
								subArray[j].errorMessageText !== "CREATE ASSISTANCE COMMITMENT/PROMISE" &&
								(subArray[j].AgencyPledgeType === "AddCreditExtension" ||
									subArray[j].AgencyPledgeType === "AddAgencyPledge"))
						) {
							flagSuccess = false;
							break;
						}
					}
				}
				console.log("flagSuccess?: ", flagSuccess);

				if (flagSuccess) {
					try {
						this.props.navigate("/epaconfirmation", {state: {
							'addresssummaryresp': this.props.location.state.addresssummaryresp,
							'addresslookupresp': this.props.location.state.addresslookupresp,
							'selectedAddress': this.props.location.state.selectedAddress,
							'selectedDate': this.props.location.state.selectedDate,
							'pledgeList': this.props.location.state.pledgeList,
							'totalPledgeAmount': this.props.location.state.totalPledgeAmount,
							'dateSubmitted': moment().format("LL"),
							'originalState': this.state.originalState
						}});
					} catch (err) {
						console.log("error push to conf page: ", err);
						this.handleLogOut();
					}
				} else {
					this.setState({ disabledFlag: false, error_display: true });
				}
			} catch (error) {
				console.log("error server: ", error);
				this.setState({ error_display: true });
			}
			showSpinner(false);
		})();
	}

  cancelEAP = () => {
    try{
    this.props.navigate('/accountsummary', {state: this.state.originalState});
    }catch(err) {
        console.log(err);
        this.handleLogOut();
    }
  }

	handleLogOut = async () => {
		showSpinner(true);
		try {
			await Auth.signOut({ global: true });
			this.setState({
				hideProfile: true,
				hideManageUsers: true,
				hideReports: true,
				hideAssistance: true,
			});
			this.props.auth.setAuthStatus(false);
			this.props.auth.setUser(null);
			this.props.navigate("/login");
		} catch (error) {
			console.log(error.message);
			await Auth.signOut();
			this.setState({
				hideProfile: true,
				hideManageUsers: true,
				hideReports: true,
				hideAssistance: true,
			});
			this.props.auth.setAuthStatus(false);
			this.props.auth.setUser(null);
			this.props.navigate("/login");
		}
		showSpinner(false);
	};

	render() {
		return (
			<section className="section auth">
				<div id="main">
					<h1>Energy Assistance</h1>
					<div className="col-sm-10 col-md-9 col-lg-8 no-margins">
						<span id="main_block">
							<div role="form" className="bordered-form">
								{this.state.error_display && (
									<div className="row">
										<div className="col-12 error mb-30">
											<div className="error">
												<p className="mb-0">
													We're sorry, something went wrong, please try again. If issue persists please contact the
													Customer Operations Center, at{" "}
													<a href="tel:18009564237" aria-label="8 0 0. 9 5 6. 4 2 3 7">
														1-800-956-4237
													</a>{" "}
													for assistance.
												</p>
											</div>
										</div>
									</div>
								)}
								<div className="review_form_header mb-30">
									<h3>Review customer details.</h3>
								</div>
								<div className="review_form_subheader">
									<div className="sub-block mb-30">
										<p className="mb-10 font-weight-bold">Customer Details:</p>
										<div className="two-block">
											<p className="first">Name:</p>
											<p className="second">
												{!this.state.noDataFlag && (
													<span>
														{" "}
														{this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm}{" "}
														{this.props.location.state.addresslookupresp.CustMiddlenm}{" "}
														{this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm}{" "}
														{this.props.location.state.addresslookupresp.CustSuffixnm}
													</span>
												)}
											</p>
										</div>
										<div className="two-block">
											<p className="first">Service Address:</p>
											<p className="second">
												{!this.state.noDataFlag && (
													<span>
														{" "}
														{this.props.location.state.selectedAddress.HouseNumber}{" "}
														{this.props.location.state.selectedAddress.StreetName}{" "}
														{this.props.location.state.selectedAddress.StreetDsgt}{" "}
														{this.props.location.state.selectedAddress.UnitDesignator}{" "}
														{this.props.location.state.selectedAddress.UnitNumber}
													</span>
												)}
											</p>
										</div>
										<div className="two-block">
											<p className="first"></p>
											<p className="second mb-20">
												{!this.state.noDataFlag && (
													<span>
														{this.props.location.state.selectedAddress.City}{" "}
														{this.props.location.state.selectedAddress.State}{" "}
														{this.props.location.state.selectedAddress.ZipCode}
													</span>
												)}
											</p>
										</div>
										<div className="two-block">
											<p className="first">Account Number:</p>
											<p className="second">
												{!this.state.noDataFlag && (
													<span>
														{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) +
															"-" +
															this.props.location.state.selectedAddress.BillCheckDigitNb}
													</span>
												)}
											</p>
										</div>
										<div className="mb-25 two-block">
											<p className="first">Program:</p>
											<p className="second">Energy Assistance</p>
										</div>
									</div>
								</div>
								<hr className="divider-sm mb-35" />
								<div className="review_form_body">
									<div className="row mb-20">
										<div className="col">
											<button className="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.noDataFlag || this.state.disabledFlag}>
												Add winter moratorium
											</button>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<button className="button-without-border" onClick={this.cancelEAP} style={{ padding: "0" }}>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</span>
					</div>
				</div>
			</section>
		);
	}
}

export default withRouter(AddEnergyAssistance);