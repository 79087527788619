import React, { Component } from "react";
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { addHypensAccountNum, translateDate } from '../global/formatter';
import { showSpinner } from './../global/loader';
import { Auth } from 'aws-amplify';
import { withRouter } from "../withRouter";

class ConfirmationAppointment extends Component {
    state = {
        noDataFlag: true
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state !== undefined && this.props.location.state !== null)
            this.setState({ noDataFlag: false, originalState: this.props.location.state.originalState });
    }

    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    makeAnotherAppointment = () => {
        try{
        this.props.navigate('/accountsummary', { replace: true });
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }

    }

    render() {
        return (
            <div class="container">
                <div>
                    <h1>Schedule Appointment</h1>
                    <div class="col-9 bordered-form wrapper-block">
                        <div class="mb-60">
                            <img class="float-left" src={SuccessMark} alt="Success Mark"></img>
                            <h3 class="float-left ml-3 mt-1">Your appointment has been submitted. Thank you!</h3>
                        </div>

                        <div class="width-adjustment mb-30">
                            <div class="form-row mb-15 clear-both">
                                <div class="col">
                                    <h4 style={{ paddingTop: '20px' }}>Customer Details:</h4>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first">Name:</p>
                                        <p class="second text-align-right">{!this.state.noDataFlag && (<span>{this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}</span>)}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first"> Service Address:</p>
                                        <p class="second text-align-right">{!this.state.noDataFlag && (<span>{this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} </span>)}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first"></p>
                                        <p class="second text-align-right">{!this.state.noDataFlag && (<span>{this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}</span>)}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first">Account Number:</p>
                                        <p class="second text-align-right">{!this.state.noDataFlag && (<span>{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) + '-' + this.props.location.state.selectedAddress.BillCheckDigitNb}</span>)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="divider-sm mb-35" />
                        <div class="width-adjustment">
                            <div class="form-row">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first text-bold">Appointment Date: </p>
                                        <p class="second text-bold text-align-right">{(!this.state.noDataFlag && this.props.location.state.selectedDate !== undefined) && (<span>{translateDate(this.props.location.state.selectedDate)}</span>)}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row mb-50">
                                <div class="col">
                                    <div class="input-group two-block agency-manage display-inline-block">
                                        <p class="first text-bold">Appointment Type: </p>
                                        <p class="second text-bold text-align-right">{(!this.state.noDataFlag && this.props.location.state.appointment !== undefined) && (<span>{(this.props.location.state.appointment)}</span>)}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button class="btn btn-primary" onClick={this.makeAnotherAppointment}>
                            Make another appointment
                         </button>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(ConfirmationAppointment);
