import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from "react-dom";
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import Footer from './components/Footer';
import {Amplify} from 'aws-amplify';
import config from './config';
import * as serviceWorker from './serviceWorker';

const oauth = {
    domain: config.oauth.domain,
    provider: "COGNITO",
    responseType: "code",
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: config.oauth.redirectSignIn,
    redirectSignOut: config.oauth.redirectSignOut,
};

Amplify.configure({
    Auth: {
        oauth: oauth,
        mandatorySignId: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        // identityPoolId: config.cognito.IDENTITY_POOL_ID,
        // identityPoolRegion: config.cognito.REGION,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

const root = document.getElementById("root");
render(
    <React.StrictMode>
        <App />
    </React.StrictMode>, root
);


serviceWorker.unregister();
