import React, { Component } from "react";
import "../Login.css";
import { addHypensPhonenumber, capitalizeFirstLetter, setAgencyRole, uniqueID } from '../global/formatter';
import config from '../../config.json';
import { Auth } from "aws-amplify";
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { showSpinner } from './../global/loader';
import * as AgencyRoleConstants from './../constants/AgencyRoleConstants';
import { withRouter } from "../withRouter";

const serviceHeader = {
    'Content-Type': config.agencyDBService.header.contentType,
    'x-api-key': config.agencyDBService.header.key,
    'Connection': config.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);


class UpdateUserDetails extends Component {

    state = {
        // hideProfile: false,
        isDisabledFlag: true,
        isDisabledFlagLocation: true,
        noDataFlag: true,
        successUpdateFlag: false,
        update_error: false,
        showRole: false,
        showLocation: false,
        roleSuperUser: false,
        locationUpdate: false,
        roleUpdate: false,
        keyIndex: 0,
        hideSelfUser: false,
        keyIndexOriginal: 0,
        locations: [{ name: '', agency_location_id: '', street_address1: '', street_address2: '', city: '', state: '', zip_code: '' }],
        search: {
            location: "",
            role: "",
            errors: {},
            touched: {},
            showDeleteUsersModal: false,
            isDisabledFlagSubmit: true,
            reason_for_disapproval: ""
        }
    }

    setReasonDecline = event => {
        const { value } = event.target;
        let search = this.state.search;
        search["reason_for_disapproval"] = value;
        if (event.target.value !== '')
            search["isDisabledFlagSubmit"] = false;
        else
            search["isDisabledFlagSubmit"] = true;
        this.setState({ search });
    }

    mapApproved = (status) => {
        let result = "";
        switch (status) {
            case 'Y':
                result = "Active";
                break;
            case 'P':
                result = "Pending Email Validation";
                break;
            case 'A':
                result = "Pending Approval";
                break;
            case 'I':
                result = "Inactive";
                break;
            default:
                result = "Pending Approval";
                break;
        }
        return result;
    }

    changeRole = () => {
        this.setState({ roleUpdate: !this.state.roleUpdate });
    }

    changeLocation = () => {
        this.setState({ locationUpdate: !this.state.locationUpdate });
    }

    callLocationDetails = () => {
        const query = "SELECT agency_location_id, name, street_address1, street_address2, city, state, zip_code FROM Agency.agency_location WHERE agency_id IN (SELECT L.agency_id FROM Agency.gaa_user u INNER JOIN Agency.agency_location L ON L.agency_location_id = cast (u.token_flag as int8) WHERE u.user_id = '" + this.props.location.state.user_id + "')";
        this.loadUserData(query);
    }

    loadUserData = (query) => {
        let tableName = ""// "GAA_USER"
        const params = {
            TableName: tableName, CommandType: "CUSTOM", Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log(json_resp);
                if (!String(json_resp.Status).toLowerCase().includes("job failed")) {
                    if (!json_resp.ResponseMetadata) {
                        this.setState({
                            locations: await json_resp
                        }, () => {
                            if(this.state.locations.length === 1) 
                                this.setState({ showLocation: false });
                            else {
                                const index = this.state.locations.indexOf(this.state.locations.find(({ name }) => name === this.state.search["location"]));
                                console.log(index);
                                this.setState({ keyIndexOriginal: index, keyIndex: index });
                            }                          
                        });
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
            showSpinner(false);
        })();
    }

    async componentDidMount() {
        console.log('history: ', this.props.history);
        console.log('location: ', this.props.location);
        console.log('state: ', this.props.location.state);
        showSpinner(true);
        await Auth.currentAuthenticatedUser();
        if (this.props.auth.user != null) {
            const role = this.props.auth.user["attributes"].nickname;
            if (role === AgencyRoleConstants.AGENCY_USER_ROLE)
                this.props.navigate("/accountsummary");
            else if (role === AgencyRoleConstants.AGENCY_ADMIN_ROLE)
                this.setState({ roleSuperUser: false });
            else
                this.setState({ roleSuperUser: true });

            if (this.props.location.state !== undefined && this.props.location.state !== null) {
                console.log(this.props.location.state);
                let search = this.state.search;
                search["role"] = this.props.location.state.role;
                search["location"] = this.props.location.state.location;
                if (this.props.location.state.user_id === this.props.auth["user"].username)
                    this.setState({ hideSelfUser: true });
                this.setState({ noDataFlag: false, search }, () => {
                    if (this.state.roleSuperUser)
                        this.setState({ showLocation: true, showRole: true });
                    else {
                        if (this.state.search["role"] === AgencyRoleConstants.AGENCY_USER_SIMPLE_TXT_ROLE)
                            this.setState({ showLocation: true, showRole: false });
                        else
                            this.setState({ showLocation: false, showRole: false });
                    }
                });
                this.callLocationDetails();
            }
            else
                showSpinner(false);
        }
    }

    handleChange = event => {
        const { name, value } = event.target;
        let search = this.state.search;
        let isDisabledFlag = !this.state.isDisabledFlag;
        search[name] = value;
        search["touched"][name] = true;
        this.setState({ search, isDisabledFlag });
    }

    handleSearchLocation = event => {
        const { value } = event.target;
        console.log(value);
        let search = this.state.search;
        search["location"] = this.state.locations[value].name;
        this.setState({ keyIndex: value, search }, () => {
            if (this.state.keyIndex === this.state.keyIndexOriginal)
                this.setState({ isDisabledFlagLocation: true });
            else
                this.setState({ isDisabledFlagLocation: false });
        });
    }

    deleteUser = () => {
        let search = this.state.search;
        search["showDeleteUsersModal"] = true;
        this.setState({ search });
    }

    closeModal = () => {
        let search = this.state.search;
        search["showDeleteUsersModal"] = false;
        this.setState({ search });
    }

    submitFunction = () => {
        showSpinner(true);
        console.log("submit" + this.state.search["reason_for_disapproval"]);
        let search = this.state.search;
        search["isDisabledFlagSubmit"] = true;
        this.setState({ search });
        this.createRecordDeletedUsers();
    }

    createRecordDeletedUsers = () => {
        let tableName = ""// "GAA_USER"
        agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
        let formattedquery = "INSERT INTO agency.DELETED_USERS SELECT * FROM AGENCY.GAA_USER WHERE user_id = '" + this.props.location.state.user_id + "'"
        console.log(formattedquery + "createRecordDeletedUsers query::::::::::::::");
        const params = {
            TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp) + ":" + json_resp.Status);
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.updateDeletedUsersInfo();
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                this.callError();
                console.log(error);
            }
        })();
    }

    updateDeletedUsersInfo = () => {
        let tableName = ""// "GAA_USER"
        // let userlist = event.userlist
        agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
        let formattedquery = "UPDATE agency.DELETED_USERS SET reason_for_disapproval = '" + String(this.state.search["reason_for_disapproval"]).replaceAll("'","''").trim() + "' where user_id in ('" + this.props.location.state.user_id + "') AND token = '" + this.props.location.state.token + "'"
        console.log(formattedquery + "updateDeletedUsersInfo query::::::::::::");
        const params2 = {
            TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
        }
        Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.deleteUserRecordDecline();
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    deleteUserRecordDecline = () => {
        let tableName = "";// "GAA_USER"
        agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
        let formattedquery = "DELETE from agency.GAA_USER where user_id in ('" + this.props.location.state.user_id + "')";
        console.log(formattedquery);
        const params = { TableName: tableName, CommandType: "CUSTOM", Query: formattedquery };
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log(JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.cognitodeleteuser();
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    cognitodeleteuser = () => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "cognitops");
        const params = { Operation: "DELETEUSER", Username: this.props.location.state.user_id }
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
                        this.callEmailServiceDisapprove();
                    }
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                this.callError();
            }
            showSpinner(false);
        })();
    }

    callEmailServiceDisapprove = () => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "agencyemailsender");
        const body = {
            "HashLink": uniqueID(), "AccountNo": "na", "Template": "disapproveusers", "StreetAddress1": this.props.location.state.street_address1, "StreetAddress2": this.props.location.state.street_address2,
            "City": this.props.location.state.city, "State": this.props.location.state.state, "ZipCode": this.props.location.state.zip_code, "Preheader": "AEP Agency Admin/User Deleted!", "Subject": "AEP Agency Admin/User Deleted!",
            "SubscriberKey": this.props.location.state.user_id + "" + uniqueID(), "EmailAddress": this.props.location.state.user_id, "ChannelMemberID": "10449858", "conformationcode": "0000",
            "AgencyURL": config.oauth.redirectSignIn + "/register", "Agency": this.props.location.state.name, "Name": this.props.location.state.first_name + " " + this.props.location.state.last_name, "Role": this.props.location.state.role,
            "Phone": this.props.location.state.phone, "PendingUserEmail": "", "PasswordResetExt": "", "Reason": this.state.search["reason_for_disapproval"]
        };
        console.log(body);
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: serviceHeader,
                    body: JSON.stringify(body)
                });
                const json_resp = await response.json();
                console.log(json_resp);
                this.closeModal();
                showSpinner(false);
                this.props.navigate("/manageusers", {state: { deleteUserFlag: true }});
            }
            catch (error) {
                console.log(error);
                this.closeModal();
                showSpinner(false);
                this.props.navigate("/manageusers", {state: { deleteUserFlag: true }});
            }
        })();
    }

    submitRole = () => {
        console.log(this.state.search["role"])
        this.changeAttributes(true);
    }

    submitLocation = () => {
        console.log(this.state.search["location"]);
        this.changeAttributes(false);
    }

    ignore_none_field = (field) => {
        if (field == 'None')
            return '';
        else
            return field + " ";
    }

    changeAttributes = (flag) => {
        showSpinner(true);
        const sampleHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': config.accountSearchShadowService.token
        };
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "cognitops");
        let params = { Operation: "UPDATEATTRIBUTES", Username: this.props.location.state.user_id };
        if (flag) {
            params["Userattributes"] = '[{ "Name":"nickname",  "Value": "' + setAgencyRole(this.state.search["role"]) + '" }]';
            params["OldGroupName"] = setAgencyRole(this.props.location.state.role);
        }
        else {
            let address = "";
            address += capitalizeFirstLetter(this.state.locations[this.state.keyIndex]['street_address1']) + " ";
            address += this.ignore_none_field(this.state.locations[this.state.keyIndex]['street_address2']);
            address += capitalizeFirstLetter(this.state.locations[this.state.keyIndex]['city']) + " ";
            address += this.state.locations[this.state.keyIndex]['state'] + " ";
            address += this.state.locations[this.state.keyIndex]['zip_code'];
            console.log(address);
            params["Userattributes"] = '[{ "Name":"locale",  "Value": "' + this.state.locations[this.state.keyIndex].agency_location_id + '" },' +
                '{ "Name":"name",  "Value": "' + this.state.locations[this.state.keyIndex].name + '" },' +
                '{"Name":"picture",  "Value": "' + this.state.locations[this.state.keyIndex].city + '" },' +
                '{"Name":"website",  "Value": "' + this.state.locations[this.state.keyIndex].state + '" },' +
                '{"Name":"address",  "Value": "' + address + '" }]';
        }
        console.log(JSON.stringify(params) + "params");
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: sampleHeaders
                });
                const json_resp = await response.json();
                console.log(JSON.stringify(json_resp) + "response::");
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
                        this.callRDS(flag);
                    }
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    callRDS = (flag) => {
        let tableName = ""// "GAA_USER"
        let formattedquery = "";
        if (flag)
            formattedquery = "UPDATE agency.GAA_USER SET role = '" + setAgencyRole(this.state.search["role"]) + "' where user_id in ('" + this.props.location.state.user_id + "')"
        else
            formattedquery = "UPDATE agency.GAA_USER SET token_flag = '" + this.state.locations[this.state.keyIndex].agency_location_id + "' where user_id in ('" + this.props.location.state.user_id + "')"
        console.log(formattedquery)
        const params2 = {
            TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
        }
        Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                });
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
                        this.props.navigate("/manageusers", {state: { attributeUpdatedFlag: true }});
                        showSpinner(false);
                    }
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    callError = () => {
        this.setState({ update_error: true });
        this.closeModal();
        showSpinner(false);
    }

    render() {
        return (
            <section>
                <div className="container mt-st">
                    <div>
                        <h1>User Details</h1>
                        {this.state.successUpdateFlag &&
                            <div class="mb-10 display-inline-block">
                                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-20">Success! Your information has been updated.</p>
                            </div>
                        }
                        {this.state.update_error &&
                            <div class="row">
                                <div class="col-12 error mb-30">
                                    <div class="error">
                                        <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact the Customer Operations Center for assistance.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <h3 class="clear-both">User Information</h3>
                        <table class="table table-bordered w-75 mb-40">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Name</b>
                                            </div>
                                            <div class="col-4">
                                                {!this.state.noDataFlag && (<span>{this.props.location.state.first_name} {this.props.location.state.last_name}</span>)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Email</b>
                                            </div>
                                            <div class="col-4">
                                                {!this.state.noDataFlag && this.props.location.state.user_id}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Phone Number</b>
                                            </div>
                                            <div class="col-7">
                                                {!this.state.noDataFlag && (addHypensPhonenumber(this.props.location.state.phone))}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="w-75">
                            <div class="row mt-20 mb-10">
                                <div class="col-12">
                                    <h3 className="float-left mb-10">Agency Information</h3>
                                </div>
                            </div>
                        </div>

                        <table class="table table-bordered w-75 mb-30">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Agency</b>
                                            </div>
                                            <div class="col-7">
                                                {!this.state.noDataFlag && (this.props.location.state.name)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Location</b>
                                            </div>
                                            <div class="col-5">
                                                {this.state.locationUpdate ? (<div><div class="form-row mb-20">
                                                    <div class="col-5 col-md-8">
                                                        <div id="agency_location_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                                                            <div className="input-group">
                                                                <select
                                                                    class="form-control"
                                                                    name="location"
                                                                    id="location"
                                                                    placeholder="Location"
                                                                    data-field-type="dropdown"
                                                                    defaultValue={this.state.keyIndex}
                                                                    onChange={this.handleSearchLocation}
                                                                    required
                                                                >
                                                                    {this.state.locations.map((row, key) => (
                                                                        <option key={key} value={key}>
                                                                            {row.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label htmlFor="location" class="align-label">Location</label>
                                                            </div>
                                                            {this.state.search["errors"]["location"] !== '' && (<span id="location_span" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_location" >{this.state.search["errors"]["location"]}</span>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                    <div class="row mb-10">
                                                        <div class="col">
                                                            <button class="btn btn-primary mr-20" onClick={this.submitLocation} disabled={this.state.isDisabledFlagLocation}>Save Changes</button>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-10">
                                                        <div class="col button">
                                                            <button class="btn button-without-border" onClick={this.changeLocation} style={{
                                                                fontSize: '17px', padding: '0 0 17px',
                                                                textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                            }}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>) : !this.state.noDataFlag && this.props.location.state.location}
                                            </div>
                                            <div class="col-2">
                                                {(this.state.showLocation && !this.state.locationUpdate) && (<button type='button' onClick={this.changeLocation} class="btn btn-sso button-anchor btn-nowhite text-bold">Change</button>)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>User Role</b>
                                            </div>
                                            <div class="col-5">
                                                {this.state.roleUpdate ? (<div>
                                                    <div clas="role-group-wrapper">
                                                        <div role="group" aria-labelledby="directionRadio">
                                                            <div class="selector-wrap">
                                                                <span id="rbl_1" data-field-type="radio-button-list" value={this.state.search["role"]} data-required="true" data-required-message="Please choose a value" class="radio" required >
                                                                    <input id="rbl_1_0" type="radio" name="role" value="Agency Admin" onClick={this.handleChange} defaultChecked={this.state.search["role"] === 'Agency Admin'} />
                                                                    <label htmlFor="rbl_1_0">Agency Admin</label>
                                                                    <input id="rbl_1_1" type="radio" name="role" value="Agency User" onClick={this.handleChange} defaultChecked={this.state.search["role"] === 'Agency User'} />
                                                                    <label htmlFor="rbl_1_1">Agency User</label>
                                                                </span>
                                                                {this.state.search["errors"]["role"] !== '' && (<span id="cphContentMain_rbl_1_ctl01" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="rbl_1" >{this.state.search["errors"]["role"]}</span>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-10">
                                                        <div class="col">
                                                            <button class="btn btn-primary mr-20" onClick={this.submitRole} disabled={this.state.isDisabledFlag}>Save Changes</button>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-10">
                                                        <div class="col button">
                                                            <button class="btn button-without-border" onClick={this.changeRole} style={{
                                                                fontSize: '17px', padding: '0 0 17px',
                                                                textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                            }}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>) : !this.state.noDataFlag && this.props.location.state.role}
                                            </div>
                                            <div class="col-2">
                                                {(this.state.showRole && !this.state.roleUpdate) && (<button type='button' onClick={this.changeRole} class="btn btn-sso button-anchor btn-nowhite text-bold">Change</button>)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>User Status</b>
                                            </div>
                                            <div class="col-7">
                                                {!this.state.noDataFlag && (this.mapApproved(this.props.location.state.approved_user_flag))}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="row mt-10 mb-10 pl-15">
                                            <div class="col-5">
                                                <b>Approved by</b>
                                            </div>
                                            <div class="col-7">
                                                {!this.state.noDataFlag && (this.props.location.state.approved_by)}                                         
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {!this.state.hideSelfUser && <button className="btn btn-secondary button-without-margin" onClick={this.deleteUser}>Delete User</button>}
                        {this.state.search["showDeleteUsersModal"] &&
                            (
                                <div class="modal-open">
                                    <div class="modal fade show display-block" id="centeredModal" tabIndex="-1" role="dialog" aria-modal="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                                        <span aria-hidden="true"></span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <h4 class="mb-25">Delete User</h4>
                                                    <p class="mb-1">You are about to delete the user &nbsp;
                                      <b>{this.props.location.state.first_name + " " + this.props.location.state.last_name + "."}</b>
                                                    </p>
                                                    <p class="mb-40">Please briefly explain the reason so that the user can know the reason for removal. This will be included in the email sent to the user.</p>
                                                    <div class="form-row">
                                                        <div class="col">
                                                            <div id="decline_request_wrapper" class="form-group aep-custom contains-textarea">
                                                                <div class="input-group">
                                                                    <textarea name="reason_for_disapproval" rows="2" cols="20" id="reason_for_disapproval" class="form-control" maxLength="200" data-field-type="text" placeholder="200 character limit." value={this.state.search["reason_for_disapproval"]} onChange={this.setReasonDecline}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="button-block col-12">
                                                        <div class="row">
                                                            <button class="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.search["isDisabledFlagSubmit"]}>Delete and send</button>
                                                            <button class="btn button-without-border pl-0" onClick={this.closeModal}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-backdrop fade show"></div>
                                </div>
                            )}
                    </div>
                </div>
            </section>
        )
    }
}


export default withRouter(UpdateUserDetails);