import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { showSpinner } from '../../global/loader';
import SuccessMark from '../../../lib/images/svgs/ICON_circle-check_green.svg';
import { withRouter } from '../../withRouter';


class EmailValidationExpired extends Component {
    state = {
        noDataFlag: true,
        DidSubmit: false,
        fields: {
            isDisabledFlag: false,
            errors: {email: ''}
        }
    }

    sendEmailValidationLink = async event => {
        let fields = this.state.fields;
        fields["isDisabledFlag"] = true;
        this.setState({ fields });
        showSpinner(true);
        try {
            await Auth.forgotPassword(this.props.location.state.username);
            this.setState({
                DidSubmit: true
            });
        } catch (error) {
            console.log(error);
            if (error.message == "Username/client id combination not found.")
                fields["errors"]["email"] = "Please enter a valid email address.";
            else
                fields["errors"]["email"] = error.message
            fields["isDisabledFlag"] = false;
            this.setState({ fields });
        }
        finally {
            showSpinner(false);
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state !== null)
            this.setState({ noDataFlag: false });
        else
            this.props.navigate('/forgotpassword');
    }

    render() {
        if (this.state.DidSubmit) {
            return (
                <div class="section">
                    <div className="container">
                        <h1>Forgot Password</h1>
                        <div class="bordered-form col-sm-9">
                            <div class="row ml-30 mb-20">
                                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><div class="float-left mt-1 ml-20"><b>Password reset information is on its way! Please allow a few minutes to receive the email.</b></div>
                            </div>
                            <div class="row ml-30 mb-10">We sent password reset information and instructions to:</div>
                            <div class="row ml-30 mb-10"><b>{this.props.location.state.username}</b></div>
                            <div class="row ml-30 mb-10"><b>Email incorrect?</b>&nbsp; You must <a href="/register">&nbsp;register again&nbsp; </a> with the correct email.</div>
                        </div>
                    </div>
                </div>
            );
        }
        else if (!this.state.DidSubmit) {
            return (
                <section class="section auth">
                    <div class="container">
                        <div class="row mb-30 header-content">
                            <h2>Link has expired</h2>
                        </div>
                        <div class="mb-50 body-content">
                            <div class="row"><p>For security purposes, the reset password link has expired. Please use the latest link or request a new one.</p></div>
                        </div>
                        <div class="row">
                            <button class="btn btn-primary" onClick={this.sendEmailValidationLink} disabled={this.state.fields["isDisabledFlag"]}>
                                Send new link
                        </button>
                        {this.state.fields["errors"]["email"] !== '' && (<span id="cphContentMain_email_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_email" >{this.state.fields["errors"]["email"]}</span>)}
                        </div>

                    </div>
                </section>
            );
        }
    }
}


export default withRouter(EmailValidationExpired);