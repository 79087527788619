import React, { Component } from 'react';

class AwaitingApprovalRegister extends Component {
    render() {
        return (
            <section class="section auth">
                <div class="container">
                    <div class="row mb-30 header-content">
                        <h2>Awaiting Approval</h2>
                    </div>
                    <div class="mb-20 body-content">
                        <div class="row"><p>Your registration request is currently being reviewed. Once you receive an email</p></div>
                        <div class="row"><p>approving your registration request, you will be able to&nbsp;<a href="/login">login</a>&nbsp;and utilize the AEP</p></div>                    
                        <div class="row mb-25"><p> Agency website to research and assist customers in need.</p></div>
                        <div class="row"><p>You may contact your agency administrator with questions regarding your request.</p></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AwaitingApprovalRegister;