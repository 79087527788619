import React, { Component } from "react";
import conf from '../../config'
import { OnlyDigits, addHypensPhonenumber } from '../global/formatter';
import { showSpinner } from '../global/loader';
import { Auth } from "aws-amplify";
import { PatternFormat } from 'react-number-format';
import { withRouter } from "../withRouter";

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};
const serverError = <div class='error'><h4 class='error'>We're sorry, an error occurred while processing your request.</h4>Please try again. If the problem persists, please contact the Customer Operations Center, at <a href="tel: 18009564237" aria-label="8 0 0. 9 5 6. 4 2 3 7">1-800-956-4237</a> for assistance.</div>;
const invalidPhoneMsg = <span>Please enter a valid 10-digit phone number.</span>;
const noChangeMsg = <span class='error'>There are no data changes. Please make changes to at least one field.</span>

var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.auth.user,
      email: this.props.auth.user.attributes.email,
      given_name: this.props.auth.user.attributes.given_name,
      family_name: this.props.auth.user.attributes.family_name,
      phone_number: this.props.auth.user.attributes.phone_number,
      firstname: this.props.auth.user.attributes.given_name,
      lastname: this.props.auth.user.attributes.family_name,
      phone: this.props.auth.user.attributes.phone_number,
      oldphone: this.props.auth.user.attributes.phone_number,
      newphone: '',
      tbphone: this.props.auth.user.attributes.phone_number.substring(2),
      isError: false,
      errorMsg: ''
    }

    this.handleFirstNameInputChange = this.handleFirstNameInputChange.bind(this);
    this.handleLastNameInputChange = this.handleLastNameInputChange.bind(this);
    this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    console.log('user: ', this.props.auth.user);
    console.log('this.props.auth.user.attributes.phone_number: ', this.props.auth.user.attributes.phone_number);
    console.log('this.state.phone: ', this.state.phone);
    console.log('OnlyDigits(this.props.auth.user.attributes.phone_number): ', OnlyDigits(this.props.auth.user.attributes.phone_number));
    console.log('OnlyDigits(this.state.phone): ', OnlyDigits(this.state.phone));
    console.log('addHypensPhonenumber: ', this.state.tbphone);
  }

  cancel = event => {
    this.props.navigate("/myprofile");
  }

  handleFirstNameInputChange = (event) => {
    this.setState({ errorMsg: '', isError: false }) // clear any error messages
    const cFirst = this.state.firstname;
    var value = event.target.value;
    if (value !== cFirst) {
      this.setState({ firstname: value });
    }
  }

  handleLastNameInputChange = (event) => {
    this.setState({ errorMsg: '', isError: false }) // clear any error messages
    const cLast = this.state.lastname;
    var value = event.target.value;
    if (value !== cLast) {
      this.setState({ lastname: value });
    }
  }

  handlePhoneInputChange = (values) => {
    this.setState({ errorMsg: '', isError: false }) // clear any error messages
    const { value } = values;
    let num = '';
    let tbphone = this.state.tbphone;
    var newphone = this.state.newphone;
    var phone = this.state.phone;
    tbphone = value;
    this.setState({ tbphone });
    if (value != this.state.phone) {
      newphone = value;
      this.setState({ newphone }, () => {
        num = OnlyDigits(newphone); // remove formatting
        phone = '+1' + num; // set database value
        this.setState({ phone });
      });
    }
  }

  handleSubmit = (event) => {
    showSpinner(true);
    const given_name = this.state.given_name;
    const family_name = this.state.family_name;
    const phone_number = this.state.phone_number;
    var firstname = this.state.firstname;
    var lastname = this.state.lastname;
    var phone = this.state.phone;
    var errorMsg = this.state.errorMsg;
    errorMsg = '';
    this.setState({ errorMsg, isError: false }); // clear any error messages

    // if data changed
    if (firstname !== given_name || lastname !== family_name || phone !== phone_number) {
      // if phone is 11-digits (includes 1)
      if (OnlyDigits(phone).length === 11) {
        // if fields have values
        if (firstname !== '' && lastname !== '') {
          this.updateUser();
        }
        // if BOTH names are empty
        else if (firstname === '' && lastname === '') {
          firstname = this.state.given_name;
          lastname = this.state.family_name;
          this.setState({ firstname, lastname }, () => {
            this.updateUser();
          });
        }
        // if ONE name is empty
        else if (firstname === '' || lastname === '') {
          if (firstname === '') {
            firstname = this.state.given_name;
            this.setState({ firstname }, () => {
              this.updateUser();
            });
          }
          else if (lastname === '') {
            lastname = this.state.family_name;
            this.setState({ lastname }, () => {
              this.updateUser();
            });
          }
        }
      }
      // if phone invalid (don't call updateUser)
      else {
        errorMsg = invalidPhoneMsg;
        this.setState({ errorMsg });
        showSpinner(false);
      }
    }
    // if no data changed (don't call updateUser)
    else {
      errorMsg = noChangeMsg;
      this.setState({ errorMsg, isError: true });
      showSpinner(false);
    }
  }

  updateUser = async () => {
    showSpinner(true);
    const given_name = this.state.given_name;
    const family_name = this.state.family_name;
    const phone_number = this.state.phone_number;
    var firstname = this.state.firstname;
    var lastname = this.state.lastname;
    var phone = this.state.phone;
    var errorMsg = this.state.errorMsg;
    errorMsg = '';
    this.setState({ errorMsg, isError: false }); // clear any error messages
    // if data changed
    // must do another check on data change (in cases of fname/lname autofill w/original)
    if (firstname !== given_name || lastname !== family_name || phone !== phone_number) {
      const user = await Auth.currentAuthenticatedUser();
      try {
        await Auth.updateUserAttributes(user, {
          'given_name': firstname,
          'family_name': lastname,
          'phone_number': phone
        });
        this.updateProfile();
      }
      catch (error) {
        errorMsg = serverError;
        this.setState({ errorMsg, isError: true });
        showSpinner(false);
      }
    }
    // if no data changed (don't call updateUser)
    else {
      errorMsg = noChangeMsg;
      this.setState({ errorMsg, isError: true });
      showSpinner(false);
    }
  }

  updateProfile = () => {
    showSpinner(true);
    let tableName = ""// "GAA_USER"
    let timestamp = new Date().toUTCString();
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let formattedquery = "";
    formattedquery = "UPDATE agency.GAA_USER SET first_name = '" + String(this.state.firstname).replaceAll("'","''").trim() + "', last_name = '" + String(this.state.lastname).replaceAll("'","''").trim() + "', phone = '" + this.state.phone + "', last_update_user = '" + this.state.email + "', last_update_timestamp = '" + timestamp + "' WHERE user_id = '" + this.state.email + "'";
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }

    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log("API response: " + JSON.stringify(json_resp));
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
            console.log("User profile updated successfully");
            this.props.navigate("/myprofile", {state: { 'userUpdated': true }});
          }
          else {
            this.reverseCognito();
            showSpinner(false);
            this.setState({ errorMsg: serverError, isError: true });
            console.log("User profile 'updateProfile' fail");
          }
        }
        else {
          this.reverseCognito();
          this.setState({ errorMsg: serverError, isError: true });
          showSpinner(false);
        }
      }
      catch (error) {
        this.reverseCognito();
        this.setState({ errorMsg: serverError, isError: true });
        showSpinner(false);
        console.log(error);
      }
    })();
  }

  // if post to db fails, reverse cognito values
  reverseCognito = async () => {
    showSpinner(true);
    const given_name = this.state.given_name;
    const family_name = this.state.family_name;
    const phone_number = this.state.phone_number;
    var firstname = this.state.firstname;
    var lastname = this.state.lastname;
    var phone = this.state.phone;
    const user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.updateUserAttributes(user, {
        'given_name': given_name,
        'family_name': family_name,
        'phone_number': phone_number
      });
      firstname = given_name;
      lastname = family_name;
      phone = phone_number.substring(2);
      this.setState({ firstname, lastname, phone, tbphone: phone }, () => {
        console.log('reverse states: ', given_name, family_name, phone_number);
      }
      );
    }
    catch (error) {
      console.log(error)
      this.setState({ errorMsg: serverError, isError: true });
    }
  }

  render() {
    return (
      <div class="container mt-st">
        <section>
          <h1>EDIT My Profile</h1>

          {this.state.isError &&
            <div class='mb-4'>{this.state.errorMsg}</div>
          }
          <div class="row">
            <div class="col-md-8">
              <div class="bordered-form">
                <h3 class='mb-4'>You may use the form below to update your information.</h3>

                <div class="form-row mb-3">
                  <div class='col-12'>
                    <h4 class='mb-4 text-normal'>Your Name</h4>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group aep-custom moveable-label">
                      <div class="input-group">
                        <input type="text" id="firstname" value={this.state.firstname} class="form-control" onChange={this.handleFirstNameInputChange} />
                        <label htmlFor='firstname'>First Name</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-5">
                    <div class="form-group aep-custom moveable-label">
                      <div class="input-group">
                        <input type="text" id="lastname" value={this.state.lastname} class="form-control" onChange={this.handleLastNameInputChange} />
                        <label htmlFor="lastname">Last name</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col">
                    <h4 class='text-normal'>Phone Number</h4>
                  </div>
                </div>
                <div class="form-row mb-4">
                  <div class="col-sm-5">
                    <div class="form-group aep-custom moveable-label">
                      <div class="input-group">
                        <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" id='tbphone' class="form-control" data-field-type="number" value={this.state.tbphone} placeholder="Phone Number" onValueChange={(values) => this.handlePhoneInputChange(values)} allowEmptyFormatting />
                        <label htmlFor='tbphone'>Phone Number</label>
                        {this.state.errorMsg === invalidPhoneMsg && (<span id="tbphoneInvalid" class="invalid-feedback validator"> {this.state.errorMsg}</span>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='form-row'>
                  <div class="col-12">
                    <button type='submit' class="btn btn-primary mb-10" onClick={this.handleSubmit}>Save changes</button>
                  </div>
                  <div class="col-12">
                    <a href="/myprofile" onClick={this.cancel}>Cancel</a>
                  </div>
                </div>


              </div>
            </div>

            <div class='col-md-4'>
              <h3>Change your password.</h3>
              <a href='/changepassword' class='btn btn-secondary'>Change my password</a>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(UpdateProfile);


{/* old */ }
{/* <div class="col-9">
              <div class="agency-profile-table">
                <div class="row">
                  <div class="col-sm-3">
                    <p class="text-bold">Your Name</p>
                  </div>
                  <div class="col-sm-9">
                    <div class="form-row">
                      <div class="col-sm-6">
                        <div class="form-group aep-custom moveable-label">
                          <div class="input-group">
                            <input type="text" id="firstname" value={this.state.firstname} class="form-control" onChange={this.handleFirstNameInputChange} />
                            <label htmlFor='firstname'>First Name</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-sm-6">
                        <div class="form-group aep-custom moveable-label">
                          <div class="input-group">
                            <input type="text" id="lastname" value={this.state.lastname} class="form-control" onChange={this.handleLastNameInputChange} />
                            <label htmlFor="lastname">Last name</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">
                    <p class="text-bold">Phone Number</p>
                  </div>
                  <div class="col-sm-9">
                    <div class="form-row mb-4">
                      <div class="col-sm-6">
                        <div class="form-group aep-custom moveable-label">
                          <div class='input-group'>
                            <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" id='tbphone' class="form-control" data-field-type="number" value={this.state.tbphone} onValueChange={(values) => this.handlePhoneInputChange(values)} allowEmptyFormatting />
                            <label htmlFor='tbphone'>Phone Number</label>
                            {this.state.errorMsg !== '' && this.state.errorMsg === invalidPhoneMsg && (<span id="tbphoneInvalid" class="invalid-feedback validator"> {this.state.errorMsg}</span>)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button type='submit' class="btn btn-primary mb-10" onClick={this.handleSubmit}>Save changes</button>
                    </div>
                    <div>
                      <a href="/myprofile" onClick={this.cancel}>Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
