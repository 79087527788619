import React, { Component } from 'react';
import conf from './../config'
import { Auth } from 'aws-amplify';
import { CSVLink } from "react-csv";
import { addDays, subDays } from 'date-fns';
import { Form, FormControl, Table } from 'react-bootstrap';
import StatesList from '../../src/components/global/StatesList';
import MaterialTable from 'material-table';
import * as AgencyRoleConstants from '../../src/components/constants/AgencyRoleConstants';
import { showSpinner } from '../../src/components/global/loader';
import * as formatter from '../../src/components/global/formatter';

const serviceHeader = {
    'Content-Type': conf.agencyDBService.header.contentType,
    'x-api-key': conf.agencyDBService.header.key,
    'Connection': conf.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);

const columns = ['RANGE', 'COUNT', 'AMOUNT'];
var date = new Date();
const today = `${date.getFullYear()}-${date.toLocaleString('en-US', { month: '2-digit' })}-${date.toLocaleString('en-US', {
    day: '2-digit'
})}`;
var d18 = new Date(today);
d18.setMonth(d18.getMonth() - 18);

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAEPAdmin: false,
            isDisabled: false,
            user: this.props.auth.user,
            username: this.props.auth.user.username,
            useragencyid: this.props.auth.user.attributes.zoneinfo,
            useragencyname: this.props.auth.user.attributes.profile,
            userLocations: [{
                'location_id': '',
                'name': ''
            }],
            range: [
                'Today',
                'This Month',
                'Last Month',
                'Year-to-Date'
            ],
            snapshotdata: {
                'count_today': '',
                'count_this_month': '',
                'count_last_month': '',
                'count_ytd': '',
                'amt_today': '',
                'amt_last_month': '',
                'amt_this_month': '',
                'amt_ytd': ''
            },
            statearray: StatesList.statearray,
            agencyarray: [{
                'agency_id': '',
                'agency_name': '',
            }],
            locationarray: [{
                'agency_location_id': '',
                'agency_location_name': ''
            }],
            pledgedata: [
                {
                    'pledge_id': '',
                    'created_date': '',
                    'entered_by': '',
                    'account_number': '',
                    'program_id': '',
                    'deposit_amount': '',
                    'aep_transaction_id': '',
                    'agency_case_document_id': '',
                    'agency_location_id': '',
                }
            ],
            showlocation: false,
            dataflag: false,
            txt_start_date: '',
            txt_end_date: '',
            ddl_agency_id: null,
            ddl_location_id: null,
            ddl_state: null,
            showerror: false,
            errorMsg: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        console.log('REPORTS');
        await Auth.currentAuthenticatedUser();
        let role = '', userGroup = '';
        const groupName = conf.cognito.USER_POOL_ID + '_aep';
        if (this.props.auth.user != null) {
            userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
            role = this.props.auth.user['attributes'].nickname;
            this.setState({ isAEPAdmin: (String(userGroup) === groupName) ? true : false });
            if (String(role) === AgencyRoleConstants.AGENCY_USER_ROLE) {
                this.props.history.goBack();
            }
            else {
                var query = this.getSnapshotQuery();
                await fetch(this.loadSnapshotData(query));
                await fetch(this.getAgencies());
            }
        }
    }

    getSnapshotQuery = () => {
        let query = 'select * from agency.get_pledge_totals(';
        this.state.useragencyid !== null && this.state.useragencyid !== undefined ? query += this.state.useragencyid + ', true)' : query += '0, false)';
        return query;
    }

    loadSnapshotData = (query) => {
        console.log('REPORTS/loadSnapshotData');
        console.log(query);
        showSpinner(true);
        let tableName = ''
        const params = {
            TableName: tableName, CommandType: 'CUSTOMES', Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log('snapshot json: ', json_resp);
                if (!String(json_resp.Status).toLowerCase().includes('job failed')) {
                    this.setState({
                        snapshotdata: json_resp[0]
                    });
                    console.log('REPORTS/snapshotdata: ', this.state.snapshotdata);
                }
            }
            catch (error) {
                console.log(error);
            }
            showSpinner(false);
        })();
    }

    handleChange = (event, flag) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value, errorMsg: '', showerror: false },
            () => {
                if (flag === 's') {
                    fetch(this.getAgenciesByState(value));
                }
                else if (flag === 'a') {
                    if (value === '' || value === null) {
                        this.setState({
                            locationarray: [],
                            showlocation: false
                        })
                    }
                    else fetch(this.getAgencyLocations());
                }
            });
    }

    getAgencies = () => {
        let query = 'SELECT DISTINCT agency_id, name FROM agency.agency ';
        if (!this.state.isAEPAdmin && this.state.useragencyid !== undefined && this.state.useragencyid !== null) {
            query += "WHERE agency_id = '" + this.state.useragencyid + "'"
            this.setState({ ddl_agency_id: this.state.useragencyid }, () => {
                fetch(this.getAgencyLocations());
            });
        }
        else { query += 'ORDER BY name'; }
        showSpinner(true);
        let tableName = ''
        const params = {
            TableName: tableName, CommandType: 'CUSTOMES', Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                if (!String(json_resp.Status).toLowerCase().includes('job failed')) {
                    this.setState({
                        agencyarray: json_resp
                    }, () => {
                        if (this.state.agencyarray.length === 1) {
                            this.getAgencyLocations();
                        }
                    });
                    showSpinner(false);
                    return json_resp;
                }
                else {
                    return;
                }
            }
            catch (error) {
                console.log('REPORTS/getAgencies ' + error);
            }
            showSpinner(false);
        })();
    }

    getAgenciesByState = (state) => {
        this.setState({
            locationarray: [{
                'agency_location_id': '',
                'agency_location_name': ''
            }],
            showlocation: false
        })
        let query = "SELECT DISTINCT a.agency_id, a.name FROM agency.agency a INNER JOIN agency.agency_location l ON a.agency_id = l.agency_id WHERE l.state = '" + state + "' ORDER BY name";
        let tableName = ''
        const params = {
            TableName: tableName, CommandType: 'CUSTOMES', Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                showSpinner(true);
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                if (!String(json_resp.Status).toLowerCase().includes('job failed')) {
                    if (json_resp.length > 0) {
                        var agencyArray = this.state.agencyarray;
                        agencyArray = '';
                        agencyArray = json_resp;
                        this.setState({
                            agencyarray: agencyArray
                        });
                    }
                    showSpinner(false);
                    return json_resp;
                }
                else {
                    showSpinner(false);
                    return;
                }
            }
            catch (error) {
                console.log('REPORTS/getAgencies ' + error);
            }
            showSpinner(false);
        })();
    }

    getAgencyLocations = () => {
        let query = "SELECT DISTINCT agency_location_id, name FROM agency.agency_location WHERE agency_id = '" + this.state.ddl_agency_id + "' ORDER by NAME";
        let tableName = ''
        const params = {
            TableName: tableName, CommandType: 'CUSTOMES', Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                if (!String(json_resp.Status).toLowerCase().includes('job failed')) {
                    this.setState({ locationarray: json_resp }, () => {
                        if (this.state.locationarray.length > 1) {
                            this.setState({ showlocation: true });
                        }
                        else {
                            this.setState({ showlocation: false });
                        }
                    });
                    return json_resp;
                }
                else {
                    return;
                }
            }
            catch (error) {
                console.log('REPORTS/getLocations ' + error);
            }
        })();
    }

    handleSubmit() {
        console.log('REPORTS/handleSubmit');
        var startdate = this.state.txt_start_date;
        var enddate = this.state.txt_end_date;
        var state = this.state.ddl_state;
        var agency = this.state.ddl_agency_id;
        var location = this.state.ddl_location_id;

        if (this.validateDates() === true) {
            // var query = 'SELECT * FROM agency.get_pledges(' + isAEPAdmin + ', ' + startdate + ', ' + enddate + ', ' + state + ', ' + agency + ', ' + location + ')';
            let query = "SELECT DISTINCT ON(p.pledge_id) p.pledge_id, p.account_number, p.amount, case when a.name is null then 'NONE' else a.name end as name, case when l.name is null then 'NONE' else a.name end as location, pr.name as program, p.created_date, p.entered_by, p.aep_transaction_id AS aep_xid, p.agency_transaction_id AS agency_xid, p.agency_case_document_id AS doc_id FROM agency.pledge p LEFT JOIN agency.program pr ON p.program_id = pr.program_id LEFT JOIN agency.agency_location l ON p.agency_location_id = l.agency_location_id LEFT JOIN agency.agency a ON l.agency_id = a.agency_id ";
            if (startdate !== '' && enddate !== '' && startdate !== null && enddate !== null) {
                query = query + "WHERE p.created_date >= '" + startdate + "' AND p.created_date <= '" + enddate + " 23:59:59' ";
            }
            else {
                this.setState({ errorMsg: 'Please select a start date and an end date', showerror: true });
                return;
            }
            if (this.state.isAEPAdmin) {
                if (state !== '' && state !== null) {
                    query = query + "AND l.state = '" + state + "' "
                }
                if (agency !== '' && agency !== null && (location === '' || location === null)) {
                    query = query + "AND a.agency_id = '" + agency + "' "
                }
                if (location !== '' && location !== null) {
                    query = query + "AND l.agency_location_id = '" + location + "' "
                }
            }
            else {
                query += "AND a.agency_id = '" + this.state.useragencyid + "' ";
                if (location !== '' && location !== null) {
                    query = query + "AND l.agency_location_id = '" + location + "' "
                }
            }
            this.runPledgeReport(query);
            console.log(query)
        }
    }

    validateDates = () => {
        this.setState({ errorMsg: '', showerror: false });
        let startdate = this.state.txt_start_date;
        let enddate = this.state.txt_end_date;

        if (startdate !== '' && startdate !== null && enddate !== '' && enddate !== null) {
            // startdate exceeds 18 months in the past
            if (startdate < formatter.toJSONLocal(d18)) {
                console.log('over 18 months');
                this.setState({ errorMsg: 'We\'re sorry, only the prior 18 months of records are available. Please be sure your start date is after ' + d18.toLocaleDateString() + '.', showerror: true });
                return false;
            }
            // startdate AFTER today
            else if (startdate > today) {
                console.log('enddate AFTER today');
                this.setState({ errorMsg: 'Oops! Please be sure your dates are BEFORE or EQUAL to today.', showerror: true });
                return false;
            }
            // enddate AFTER startdate
            else if (enddate < startdate) {
                console.log('startdate AFTER enddate');
                this.setState({ errorMsg: 'Oops! Please be sure your start date is BEFORE your end date.', showerror: true });
                return false;
            }
            // DELETE on or after 2022-11-19 (no longer needed as it will be over 18 months)
            // end date falls after May 20, 2021 (date of release)
            else if (enddate <= new Date('2021-05-19')) {
                console.log('BEFORE 5/20/21');
                this.setState({ errorMsg: 'We\'re sorry, there are no records prior to May 20, 2021. Please choose a later date.', showerror: true });
                return false;
            }
            else return true;
        }
        else {
            this.setState({
                errorMsg: 'Please select a start date and end date.', showerror: true
            })
            return false;
        }
    }

    runPledgeReport(query) {
        let errorMsg = this.state.errorMsg;
        errorMsg = '';
        this.setState({ errorMsg: errorMsg, showerror: false })
        showSpinner(true);
        let tableName = ''
        const params = {
            TableName: tableName, CommandType: 'CUSTOMES', Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key])); (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                if (!String(json_resp.Status).toLowerCase().includes('job failed')) {
                    if (!json_resp.ResponseMetadata) {
                        this.setState({
                            pledgedata: json_resp,
                            dataflag: true
                        });
                        showSpinner(false);
                    }
                    else {
                        errorMsg = 'We could not find any results with the selected filters. Please refine your selection and try again.';
                        this.setState({ errorMsg: errorMsg, showerror: true });
                        showSpinner(false);
                        return;
                    }
                }
            }
            catch (error) {
                console.log(error);
                showSpinner(false);
            }
        })();
    }

    filterColumns(data) {
        // Get column names
        const columns = Object.keys(data[0]);
        // filter by index
        columns.shift()
        return columns
    }

    camelCase(str) {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }

    cancel() {
        this.setState({
            errorMsg: '',
            showerror: false,
            dataflag: false,
            ddl_state: null,
            ddl_agency_id: null,
            ddl_location_id: null
        });
        this.componentDidMount();
    }

    render() {
        const headerStyles = { backgroundColor: '#f1f1f1', fontSize: '1rem', fontWeight: 'bold', padding: '5px 10px' };
        const rowStyles = { fontSize: '1rem' };
        const cellStyles = { padding: '5px 10px' };
        const data = this.state.pledgedata;
        const pledgedataColumns = formatter.filterColumns(data);
        const pledgedataHeaders = pledgedataColumns.map((col) => ({label: formatter.camelCase(col), key: col}));
        const ranges = this.state.range;
        let snapshotHeaders = [
            { label: "Range", key: "range" },
            { label: "Count", key: "count" },
            { label: "Amount", key: "amt" }
        ];
        let snapshotdata = [
            {
                range: 'Today',
                count: this.state.snapshotdata.count_today,
                amt: this.state.snapshotdata.amt_today
            },
            {
                range: 'Last Month',
                count: this.state.snapshotdata.count_this_month,
                amt: this.state.snapshotdata.amt_this_month
            },
            {
                range: 'This Month',
                count: this.state.snapshotdata.count_last_month,
                amt: this.state.snapshotdata.amt_last_month
            },
            {
                range: 'YTD',
                count: this.state.snapshotdata.count_year,
                amt: this.state.snapshotdata.amt_year
            }
        ];
        let colList = '';
        let pledgecols = [
            { title: 'pledge_id', field: 'pledge_id' },
            { title: 'date', field: 'created_date' },
            { title: 'entered_by', field: 'entered_by' },
            { title: 'account#', field: 'account_number' },
            { title: 'amount', field: 'amount' },
            { title: 'agency', field: 'name' },
            { title: 'location', field: 'location' },
            { title: 'program', field: 'program' },
            { title: 'aep_xid', field: 'aep_xid' },
            { title: 'doc_id', field: 'doc_id' }
        ]

        return (
            <section>
                <div>
                    <div class='container mt-st'>
                        <div class={this.state.dataflag === false ? 'row mb-st' : 'row'}>
                            <div class='col-sm-11 col-xl-10'>
                                <h1>Reports</h1>
                            </div>
                        </div>

                        {!this.state.dataflag &&
                            <div class='row'>
                                <div class='col-sm-11 col-md-9 col-lg-7 col-xl-8 order-2 order-lg-1'>
                                    <div class='bordered-form'>
                                        <div class='row'>
                                            <div class='col-xl-11 mb-4'>
                                                {this.state.showerror &&
                                                    <p class='mb-3 error'>{this.state.errorMsg}</p>
                                                }
                                                <h3 class='mb-3'>Please choose the date range for the report you wish to generate.</h3>
                                            </div>
                                        </div>
                                        <div class='form-row mb-4'>
                                            <div class='col-sm-9 col-md-6'>
                                                <div id='datepicker-wrapper' class='form-group moveable-label aep-custom'>
                                                    <div class='input-group'>
                                                        <Form.Control onChange={this.handleChange} type='date' id='txt_start_date' name='txt_start_date' defaultValue={this.state.txt_start_date} min={formatter.toJSONLocal(d18)} max={today} />
                                                        <Form.Label>Start Date</Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='col-sm-9 col-md-6'>
                                                <div class='form-group aep-custom moveable-label'>
                                                    <div class='input-group'>
                                                        <FormControl onChange={this.handleChange} type='date' id='txt_end_date' name='txt_end_date' defaultValue={this.state.txt_end_date} min={formatter.toJSONLocal(d18)} max={today} />
                                                        <Form.Label>End Date</Form.Label>
                                                    </div>
                                                    <span id='' class='invalid-feedback validator error m-0 mb-2' style={{ 'display': 'none' }}></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class='form-row mb-3'>
                                                <div class='col-xl-11'>
                                                    <h4 class='mb-4 text-normal'>{this.state.isAEPAdmin && 'You may further refine your results by selecting the optional fields below.'}{!this.state.isAEPAdmin && this.state.locationarray.length > 1 ? 'You may select a location to further filter the results.' : ''}</h4>
                                                </div>
                                                {this.state.isAEPAdmin &&
                                                    <div class='col-sm-6 col-md-4'>
                                                        <div class='form-group aep-custom moveable-label'>
                                                            <div class='input-group'>
                                                                <FormControl as='select' id='ddl_state' name='ddl_state' selected={this.state.ddl_state} onChange={(e) => this.handleChange(e, 's')}>
                                                                    <option value={this.state.ddl_state} class='blank'>Select state</option>
                                                                    {this.state.statearray.map((o, key) => {
                                                                        return <option key={o.state} value={o.state}>{o.state}</option>;
                                                                    })}</FormControl>
                                                                <label htmlFor='ddl_state'>State</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div class='form-row mb-4'>
                                                <div class='col-sm-9 col-md-6'>
                                                    <div class='form-group aep-custom moveable-label'>
                                                        <div class='input-group'>
                                                            <FormControl as='select' id='ddl_agency_id' name='ddl_agency_id' onChange={(e) => this.handleChange(e, 'a')} disabled={!this.state.isAEPAdmin}>
                                                                <option value='' class='blank'>Select Agency</option>
                                                                {this.state.agencyarray.map((o, key) => {
                                                                    return <option key={o.agency_id} value={o.agency_id} selected={this.state.useragencyid}>{o.name}</option>;
                                                                })}
                                                            </FormControl>
                                                            <label htmlFor='ddlAgency' class={!this.state.isAEPAdmin ? 'd-none' : ''}>Agency</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='col-sm-9 col-md-6'>
                                                    {this.state.showlocation &&
                                                        <div class='form-group aep-custom moveable-label'>
                                                            <div class='input-group'>
                                                                <FormControl as='select' id='ddl_location_id' name='ddl_location_id' onChange={(e) => this.handleChange(e)} disabled={!this.state.showlocation}>
                                                                    <option value='' class='blank'>Select Location</option>
                                                                    {this.state.locationarray.map((o, key) => {
                                                                        return <option key={o.agency_location_id} value={o.agency_location_id}>{o.name}</option>;
                                                                    })}
                                                                </FormControl>
                                                                <label htmlFor='ddlLocation'>Location</label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class='row'>
                                            <div class='col-12'>
                                                <button type='button' onClick={() => this.handleSubmit()} class='btn btn-primary mr-3' disabled={this.state.isDisabled}>Generate report</button><br />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class='col-sm-8 col-md-7 col-lg-5 col-xl-4 order-1 order-lg-2'>
                                    <div class='panel panel-small'>
                                        <div class='panel-header'>
                                            <h2 class='h4'>{!this.state.isAEPAdmin && <span>{this.state.useragencyname}&nbsp;</span>}Pledge Summary{this.state.isAEPAdmin && <span>&nbsp;Totals</span>}
                                                <span>
                                                    <CSVLink data={snapshotdata} headers={snapshotHeaders} filename={"Snapshot.csv"} className='panel-link ml-n2'>Export to Excel</CSVLink>
                                                </span>
                                            </h2>
                                        </div>
                                        <Table class='table table-small panel-table'>
                                            <thead>
                                                <tr>
                                                    {colList = columns.map((col) =>
                                                        <th>{col}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{ranges[0]}</td>
                                                    <td>{formatter.zeroFormat(this.state.snapshotdata.count_today)}</td>
                                                    <td>${formatter.numberWithCommas(formatter.zeroFormat(this.state.snapshotdata.amt_today))}</td>
                                                </tr>
                                                <tr>
                                                    <td>{ranges[1]}</td>
                                                    <td>{formatter.zeroFormat(this.state.snapshotdata.count_this_month)}</td>
                                                    <td>${formatter.numberWithCommas(formatter.zeroFormat(this.state.snapshotdata.amt_this_month))}</td>
                                                </tr>
                                                <tr>
                                                    <td>{ranges[2]}</td>
                                                    <td>{formatter.zeroFormat(this.state.snapshotdata.count_last_month)}</td>
                                                    <td>${formatter.numberWithCommas(formatter.zeroFormat(this.state.snapshotdata.amt_last_month))}</td>
                                                </tr>
                                                <tr>
                                                    <td>{ranges[3]}</td>
                                                    <td>{formatter.zeroFormat(this.state.snapshotdata.count_year)}</td>
                                                    <td>${formatter.numberWithCommas(formatter.zeroFormat(this.state.snapshotdata.amt_year))}</td>
                                                </tr>
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.dataflag === true &&
                            <div class='material-table-css'>
                                <div class='row'>
                                    <div class='col-12 mb-4'>
                                        <MaterialTable
                                            data={data}
                                            title=''
                                            columns={pledgecols}
                                            options={{
                                                search: true,
                                                sorting: true,
                                                headerStyle: headerStyles,
                                                rowStyle: rowStyles
                                            }}
                                        ></MaterialTable>
                                    </div>

                                    <div class='col-12'>
                                        <CSVLink data={data} headers={pledgedataHeaders} filename={"pledgereport.csv"} className='btn btn-primary mr-3'>Export to Excel</CSVLink>
                                        <button type='button' onClick={() => this.cancel()} class='btn btn-secondary'>Search again</button>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section >
        );
    }
}

export default Reports;
