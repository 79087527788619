import React, { Component } from "react";
import "./Login.css";
import conf from './../config';
import { Grid, Segment, Button, Radio, Form } from 'semantic-ui-react';
import { PatternFormat } from 'react-number-format';
import View_All_Logo from '../lib/images/svgs/ICON_arrow_view_all.svg';
import Pdf_Logo from '../lib/images/svgs/ICON_pdf.svg';
import { Tab, Tabs } from 'react-bootstrap';
import { addDottedPhonenumber, addHypensAccountNum,formatToMMDDYYYY, dateWithSlashes, numberWithCommas, capitalizeFirstLetter, numberWithCommasWithLeadingZeroes, checkIfBetweenTwoDates, formatDateFromDDToMM, checkIfAfterBeginDate } from './global/formatter';
import { showSpinner } from './global/loader';
import moment from "moment";
import "./styles/banner.scss";
import Banner from "../components/banner/index";
import "./styles/AccountSummary.scss";
import { Auth } from 'aws-amplify';
import { withRouter } from "./withRouter";
import AccountSummaryContext from "../contexts/AccountSummaryContext";

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};
const isINwinMoratorium = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), conf.startDateINwintermoratorium, conf.endDateINwintermoratorium);
const isNovember = moment().month() === 10; // November is represented by 10
const defaultMsg = <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>;
const acctmissmatchStateMsg = <p class="mb-0">You cannot view an account of a customer not listed in your state. Please adjust search information and try again.</p>;
const ssnmissmatchStateMsg = <p class="mb-0">You cannot view an account of a customer not listed in your state.</p>;
const inactiveMsg = <p class="mb-0">Account is inactive. Please adjust search information.</p>;
const nonresidentialMsg = <p class="mb-0">Non residential account. Please adjust search information</p>;
const pwMsg = <p class="mb-0">Account access requires a security code. Please contact the Customer Operations Center, at <a href="tel:18009564237" aria-label="8 0 0. 9 5 6. 4 2 3 7">1-800-956-4237</a> for assistance.</p>;
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);


class AccountSummary extends Component {
  static contextType = AccountSummaryContext;
  state = {
    key: "account-summary",
    accnum: "",
    errorMsg: "",
    curr_amt_due: 0,
    tdat: "ACCTWEB",
    soapresp: { "AccountNum": "", "FirstName": "", "LastName": ""},
    buildingtypelookup:
    {
      "AP": "Apartment",
      "CN": "Condominium",
      "GA": "Garage",
      "HS": "House",
      "MD": "Modular Home",
      "MI": "Mobile Home",
      "MO": "Mobile Home",
      "NR": "Non-Residential Service",
      "RO": "Residential Outbuilding",
      "RV": "Recreational Vehicle",
      "TS": "Temporary Service",
      "TW": "Town House",
      "": "Unknown"
    },
    accounttypelookup:
    {
      "C": "COMMERCIAL",
      "I": "INDUSTRIAL",
      "O": "OTHER",
      "P": "PUBLIC AUTHORITY",
      "R": "RESIDENTIAL",
      "": "-"
    },
    accountstatuslookup:
    {
      "D": "Disconnected for non payment",
      "A": "Active",
      "N": "New",
      "P": "Pending",
      "F": "Final",
      "C": "Charge-off",
      "L": "Closed",
      "S": "Sold",
      "X": "Cancelled",
      "": "-"
    },
    isAccountDisconnected: false,
    disconnectDate: "N/A",
    disconnectAmt: "N/A",
    keySelectedSearch: "account-number-search",
    banner_display: true,
    search_display: true,
    header_display: true,
    address_display: false,
    multiple_ssn_display: false,
    account_display: false,
    account_lookup_error_display: false,
    ssn_lookup_error_display: false,
    billing_history_display: false,
    showPIPPSection: false,
    show175planstatus: false,
    heatSourceText: "N/A",
    selectedSsnAccount: "",
    showAppointmentFlag: false,
    isINwinterMoratorium: isINwinMoratorium,
    isNovember: isNovember,
    showEnergyAsstFlag: false,
    showPIPPApplicationHold: false,
    showApplicationHold: false,
    showPledgeFlag: true,
    billingHistoryLoaded: false,
    paymenthistoryloaded: false,
    showRecentAssistance: false,
    showAppointmentDisplay: false,
    pledgeList: [],
    appointmentList: [],
    appointmentTypes: [],
    availableappointments: [],
    lastPaymentDate: '',
    lastPaymentAmount: '',
    pendingPayments: 0,
    paymenthistoryresp: {
      "PaymentHistory": [
        {
          "mia_pymt_ts": "",
          "mia_pymt_cshr_cd": "",
          "mia_pymt_ind_cd": "",
          "mia_pymt_at": "",
          "mia_rvrs_cd": ""
        }
      ],
      "ErrorCode": 0
    },
    addresslookupresp: {
      "AddressList": [
        {
          "AccountNum": "",
          "HouseNumber": "",
          "HalfInd": "",
          "StreetName": "",
          "StreetDsgt": "",
          "City": "",
          "State": "",
          "ZipCode": "",
          "CategoryCode": "",
          "CorpInd": "",
          "StatCode": "",
          "TariffCode": "",
          "PwdProtectFl": ""
        }
      ],
      "CustMiddlenm": "",
      "CustSuffixnm": "",
      "ErrorCode": 0
    },
    selectedAddress: {
      "AccountNum": "",
      "HouseNumber": "",
      "HalfInd": "",
      "StreetName": "",
      "StreetDsgt": "",
      "City": "",
      "State": "",
      "ZipCode": "",
      "CategoryCode": "",
      "CorpInd": "",
      "StatCode": "",
      "TariffCode": "",
      "PwdProtectFl": "",
      "Disconnectdate_1": "",
      "Disconnectamount_1": "",
      "Disconnectdate_2": "",
      "Disconnectamount_2": ""
    },
    billingHistoryResp: {
      "BillingHistory": [
        {
          "bill_date": "",
          "totl_bill_at": "",
          "totl_bill_due_at": "",
          "read_date": "",
        }
      ],
      "ErrorCode": 0
    },
    addresssummaryresp: {
      "CustomerData": {
        "cust_num": "",
        "acct_type_cd": "",
        "acct_stat_cd": "",
        "acct_clas_cd": "",
        "turn_on_dt": "",
        "cust_1st_nm": "",
        "cust_last_nm": "",
        "tlph_nb": ""

      },
      "AmountsDue": {
        "acct_bal_at": "",
        "bal_due_at": "",
        "curr_bal_at": "",
        "due_30_days_at": "",
        "due_60_days_at": "",
        "due_90_days_at": "",
        "next_bill_dt": "",
        "bill_at": "",
        "bill_due_dt": "",
        "deposit_due_at": ""
      },
      "CollectionInfo": {
        "disc_past_due_at": "",
        "coll_actv_dt": "",
        "totl_dscn_at": ""
      },
      "AssistanceInfo": {
        "pip_fl": "",
        "pip_stat_cd": "",
        "pip_at": "",
        "pip_past_due_at": "",
        "pip_175_fl": "",
        "pip_drop_dt": "",
        "heat_sorc_type": "",
        "arr_crdt_at": "",
        "pip_reqd_at": "",
        "pip_inactv_rsn": ""
      },
      "ErrorCode": 0
    },
    review: { buttondisabledflag: true, ssnbuttondisabledflag: true },
    errors: {
      cognito: null,
      blankfield: false,
      accountfielderror: '',
      ssnfielderror: '',
      lastnamefielderror: '',
      errorMultipleCorp: '',
      errorMsg: ''
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        accountfielderror: '',
        ssnfielderror: '',
        lastnamefielderror: '',
        errorMsg: ''
      },
      account_lookup_error_display: false,
      ssn_lookup_error_display: false
    });
  };

  handleLogOut = async () => {
    showSpinner(true);
    try {
      await Auth.signOut({ global: true });
      this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.navigate("/login");
    } catch (error) {
      console.log(error.message);
      await Auth.signOut();
      this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.navigate("/login");
    }
    showSpinner(false);
  }

  handlePledge = () => {
    try{
      this.props.navigate("/makeapledge", {state: { 'addresssummaryresp': this.state.addresssummaryresp,'addresslookupresp': this.state.addresslookupresp, 'fromAccSumm': true, 'selectedAddress': this.state.selectedAddress, 'originalState': this.state }});
    } catch(err) {
      console.log(err);
      this.handleLogOut();
  }

  }

  handleAppointment = () => {
    try{
      console.log("Before going to appointmet page - ")
      console.log(this.state.appointmentTypes);
      console.log(this.state.appointmentList);
      
      this.props.navigate("/scheduleappointment", {state: { 'addresssummaryresp': this.state.addresssummaryresp, 'addresslookupresp': this.state.addresslookupresp, 'fromAccSumm': true, 'selectedAddress': this.state.selectedAddress, 'appointmentTypes': this.state.appointmentTypes, 'originalState': this.state}});
    } catch(err) {
      console.log(err);
      this.handleLogOut();
    }
  }

  handleAssistanceProgram = () => {
    try{
      const { setOriginalState } = this.context;
    if (!this.state.noDataFlag) {
        setOriginalState({...this.state})
        this.props.navigate("/addenergyassistance", {state: {'addresssummaryresp': this.state.addresssummaryresp, 'addresslookupresp': this.state.addresslookupresp, 'selectedAddress': this.state.selectedAddress }});
    }}catch(err) {
        console.log(err);
        this.handleLogOut();
    }
  }

  handlePIPPApplicationHold = () => {
    try{
      const { setOriginalState } = this.context;
    if (!this.state.noDataFlag) {
        setOriginalState({...this.state})
        this.props.navigate("/pippapplicationhold", {state: {'addresssummaryresp': this.state.addresssummaryresp, 'addresslookupresp': this.state.addresslookupresp, 'selectedAddress': this.state.selectedAddress }});
    }}catch(err) {
        console.log('this err ',err);
    }
  }

  handleApplicationHold = () => {
    try{
      const { setOriginalState } = this.context;
    if (!this.state.noDataFlag) {
        setOriginalState({...this.state})
        this.props.navigate("/applicationhold", {state: {'addresssummaryresp': this.state.addresssummaryresp, 'addresslookupresp': this.state.addresslookupresp, 'selectedAddress': this.state.selectedAddress }});
    }}catch(err) {
        console.log('application hold error:',err);
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  onValueChange = event => {
    var selectAddress = this.state.addresslookupresp.AddressList[event.target.value];
    var selectSsnAccount = this.state.addresslookupresp.AddressList[event.target.value].AccountNum;
    var userState = this.props.auth.user.attributes.website;
    var acctState = selectSsnAccount.substring(0, 2);
    let errors = this.state.errors;
    errors["errorMultipleCorp"] = ""
    this.setState({
      errorMsg: '',
      ssn_lookup_error_display: false,
      selectedAddress: selectAddress,
      selectedSsnAccount: selectSsnAccount
    }, () => {
      console.log('selectedAddress: ', this.state.selectedAddress);
      let isisPwProtected = this.state.selectedAddress.PwdProtectFl === 'Y';
      let isActive = this.state.selectedAddress.StatCode !== 'S' && this.state.selectedAddress.StatCode !== 'X';
      let isResidential = this.state.selectedAddress.CorpInd === 'R';
      let review = this.state.review;
      if (userState == null || this.isUserState(userState, acctState) == true) {
        if (isActive) {
          if (isResidential) {
            if (!isisPwProtected) {
              review['ssnbuttondisabledflag'] = false;
              this.setState({
                errorMsg: '', ssn_lookup_error_display: false, review
              });
            }
            else {
              review['ssnbuttondisabledflag'] = true;
              this.setState({
                errorMsg: pwMsg, ssn_lookup_error_display: true, review
              });
            }
          }
          else {
            review['ssnbuttondisabledflag'] = true;
            this.setState({
              errorMsg: nonresidentialMsg, ssn_lookup_error_display: true, review
            });
          }
        }
        else {
          review['ssnbuttondisabledflag'] = true;
          this.setState({
            errorMsg: inactiveMsg, ssn_lookup_error_display: true, review
          });
        }
      }
      else {
        review['ssnbuttondisabledflag'] = true;
        this.setState({
          errorMsg: ssnmissmatchStateMsg, ssn_lookup_error_display: true, review
        });
      }
    });
  }
  isNumberKey = event => {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode != 45 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  };

  changeToAccountSearch = async () => {
    let review = this.state.review;
    review['buttondisabledflag'] = false;
    this.setState({ account_display: false, search_display: true, address_display: false, header_display: true, multiple_ssn_display: false, review });
  }

  changeToAccountSearchFromSsn = async () => {
    let review = this.state.review;
    review['ssnbuttondisabledflag'] = false;
    this.setState({ account_display: false, search_display: true, address_display: false, header_display: true, multiple_ssn_display: false, keySelectedSearch: "ssn-search", review });
  }

  changeCustomer = () => {
    /*Reset state to default values*/
    this.setState({ 
      account_display: false, 
      banner_display: true, 
      search_display: true, 
      address_display: false, 
      header_display: true ,
      keySelectedSearch: "account-number-search",
      key: "account-summary",
      showPIPPSection: false,
      show175planstatus: false,
      showAppointmentFlag: false,
      showEnergyAsstFlag: false,
      showPIPPApplicationHold: false,
      showApplicationHold: false,
      showPledgeFlag: true,
      billingHistoryLoaded: false,
      paymenthistoryloaded: false,
      showRecentAssistance: false,
      showAppointmentDisplay: false,
      pledgeList: [],
      appointmentList: [],
      appointmentTypes: [],
      lastPaymentDate: '',
      lastPaymentAmount: '',
      pendingPayments: 0,
      accnum: "",
      ssn: "",
      lastname: ""
    });
  };

  mapCodeToStatus = (status) => {
    let result = 'N/A';
    switch (status) {
      case 'A':
      case 'N':
        result = 'Active';
        break;
      case 'P':
        result = 'Pending';
        break;
      case 'D':
        result = 'Disconnected for non payment';
        break;
      case 'F':
      case 'L':
      case 'C':
        result = 'Final';
        break;
      default:
        result = 'Final';
        break;
    }
    return result;
  }

  componentDidMount() {
    let windowPath = window.location.href;
    if (windowPath.toUpperCase().includes('ACCOUNTSUMMARY'))
      window.scrollTo(0, 500);
    console.log(this.state.addresslookupresp);
    console.log(this.state.addresssummaryresp);
    const { originalState } = this.context;
    if(originalState){
      this.setState({...originalState, showEnergyAsstFlag: false, showPIPPApplicationHold: false, showApplicationHold: false}, function(){
        if(this.state?.selectedAddress?.AccountNum){
          this.callPledgeDetails();
        }
      })
    } else if (this.props.location.state !== undefined) {
      this.setState(this.props.location.state)
    }
    showSpinner(false);
  }

  handleChangeAccountNum = (values) => {
    const { value } = values;
    const isEmpty = value === null;
    let errors = this.state.errors;
    let accnum = this.state.accnum;
    let review = this.state.review;
    accnum = value;
    // errors["accountfield"] = true;
    if (isEmpty) {
      errors["accountfielderror"] = "Please enter the full 11-digit account number";
      review['buttondisabledflag'] = true
    }
    else if (value.length < 11) {
      errors["accountfielderror"] = "Please enter the full 11-digit account number";
      review['buttondisabledflag'] = true
    }
    else {
      errors["accountfielderror"] = "";
      review['buttondisabledflag'] = false
    }
    this.setState({ errors, accnum, review });
  }

  handleChangeSsnNum = (values) => {
    const { value } = values;
    const isEmpty = value === null;
    let errors = this.state.errors;
    let ssn = this.state.ssn;
    let lastname = this.state.lastname;
    let review = this.state.review;
    ssn = value;

    if (isEmpty) {
      errors["ssnfielderror"] = "Please enter the last 4 digits of the SSN number";
    }
    else if (value.length < 4) {
      errors["ssnfielderror"] = "Please enter the last 4 digits of the SSN number";
    }
    else {
      errors["ssnfielderror"] = "";
    }

    if (lastname == null || lastname.length == 0) {
      errors["lastnamefielderror"] = "Please enter a last name";
    }
    else if (lastname.length < 4) {
      errors["lastnamefielderror"] = "Please enter a last name";
    }
    else {
      errors["lastnamefielderror"] = "";
    }

    if (lastname != null && lastname.length > 0 && ssn != null && ssn.length == 4) {
      review['ssnbuttondisabledflag'] = false;
    }
    else {
      review['ssnbuttondisabledflag'] = true;
    }
    this.setState({ errors, ssn, review });
  }

  onLastnameInputChange = event => {
    const isEmpty = event.target.value === null;
    let errors = this.state.errors;
    let ssn = this.state.ssn;
    let lastname = this.state.lastname;
    let review = this.state.review;
    lastname = event.target.value;
    review['ssnbuttondisabledflag'] = true

    if (isEmpty) {
      errors["lastnamefielderror"] = "Please enter a last name";
    }
    else if (event.target.value.length < 1) {
      errors["lastnamefielderror"] = "Please enter a last name";
    }
    else {
      errors["lastnamefielderror"] = "";
    }

    if (ssn == null || ssn.length == 0) {
      errors["ssnfielderror"] = "Please enter the last 4 digits of the SSN number";
    }
    else if (ssn.length < 4) {
      errors["ssnfielderror"] = "Please enter the last 4 digits of the SSN number";
    }
    else {
      errors["ssnfielderror"] = "";
    }

    if (lastname != null && lastname.length > 0 && ssn != null && ssn.length == 4) {
      review['ssnbuttondisabledflag'] = false;
    }
    else {
      review['ssnbuttondisabledflag'] = true;
    }
    this.setState({ errors, lastname, review });
  };


  /// Check Bill whether Bill Account NB is the same State as Agency Admin or user - If not throw an error message. This validation is happening based on OPCO. AEP Admins will not part of this Check.
  isUserState = (userState, acctState) => {
    if (this.props.auth.user.attributes.nickname != null || this.props.auth.user.attributes.address != null) {
      let result = false;
      switch (userState) {
        case 'TN':
          if (acctState == "01")
            return true;
          else
            return false;
        case 'VA':
          if (acctState == "02")
            return true;
          else
            return false;
        case 'WV':
          if (acctState == "06" || acctState == "02")
            return true;
          else
            return false;
        case 'KY':
          if (acctState == "03")
            return true;
          else
            return false;
        case 'IN':
        case 'MI':
          if (acctState == "04")
            return true;
          else
            return false;
        case 'OH':
          if (acctState == "07" || acctState == "10")
            return true;
          else
            return false;
        case 'OK':
          if (acctState == "95")
            return true;
          else
            return false;
        case 'AR':
        case 'LA':
        case 'TX':
          if (acctState == "96")
            return true;
          else
            return false;
        default:
          result = 'Finaled';
          break;
      }
    }
    return true;
  }

  accountSearchFunction = async event => {
    /// This will check whether the Bill account number is in same state as Agency Admin / User.
    /// Using Address Field to get Agency Admin / User state.
    var userState = this.props.auth.user.attributes.website;
    var acctState = this.state.accnum.substring(0, 2);
    event.preventDefault();
    if (this.isUserState(userState, acctState) == true) {
      let review = this.state.review;
      review['buttondisabledflag'] = true
      this.setState({ review });

      console.log("clicked search function::::");
      // Form validation
      this.clearErrorState();
      showSpinner(true);
      const sampleHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': conf.accountSearchShadowService.token
      };
      var url = new URL(conf.shadowServiceGenericURL.url + "accountsearchconsolidated");
      const params = { accnum: this.state.accnum.replace(/\D/g, "") }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      (async () => {
        try {
          const [response] = await Promise.all([
            fetch(url, {
              method: 'POST',
              headers: sampleHeaders
            }),
          ]);
          const json_resp = await response.json();
          console.log("ErrorCode: ", json_resp.ErrorCode);
          if (json_resp.ErrorCode == 0) {
            this.setState({ addresssummaryresp: json_resp.AccountSummary, addresslookupresp: json_resp, selectedAddress: json_resp.AddressList[0] }, function () {
              const isActive = this.state.selectedAddress.StatCode !== 'S' && this.state.selectedAddress.StatCode !== 'X';
              const isPwProtected = this.state.selectedAddress.PwdProtectFl === 'Y';
              console.log("This is from account search (addresslookupresp):- " + this.state.addresslookupresp);
              console.log("addresssummaryresp: ", this.state.addresssummaryresp);
              if (isActive) {
                if (this.state.addresslookupresp.AddressList[0].CorpInd === 'R') {
                  if (!isPwProtected) {
                    this.setState({ address_display: true, banner_display: false, search_display: false, account_lookup_error_display: false, });
                  }
                  else {
                    this.setState({
                      errorMsg: pwMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false
                    });
                  }
                }
                else {
                  let review = this.state.review;
                  review['buttondisabledflag'] = false;
                  this.setState({
                    errorMsg: nonresidentialMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false, review
                  });
                }
              }
              else {
                this.setState({
                  errorMsg: inactiveMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false
                });
              }
            });
          }
          else
            this.setState({ errorMsg: defaultMsg, account_lookup_error_display: true, address_display: false, banner_display: true, search_display: true });
        }
        catch (error) {
          console.log(error);
          let review = this.state.review;
          review['buttondisabledflag'] = false;
          this.setState({ errorMsg: defaultMsg, account_lookup_error_display: true, review });
        }
        showSpinner(false);
      })();
    }
    else {
      let review = this.state.review;
      review['buttondisabledflag'] = true;
      this.setState({ errorMsg: acctmissmatchStateMsg, account_lookup_error_display: true, review });
    }
  }

  SSNSearchFunction = async event => {
    event.preventDefault();
    this.setState({ errorMsg: '' })
    let review = this.state.review;
    review['ssnbuttondisabledflag'] = true
    this.setState({ review });
    // Form validation
    this.clearErrorState();
    showSpinner(true);

    const sampleHeaders = {
      'Content-Type': 'application/json',
      'x-api-key': conf.accountSearchShadowService.token
    };
    var url = new URL(conf.shadowServiceGenericURL.url + "accountsearchconsolidated");
    var urladdresslookup = new URL(conf.addressLookupShadowService.url);
    const params = { ssn: this.state.ssn.replace(/\D/g, ""), lastname: this.state.lastname.toUpperCase() }
    Object.keys(params).forEach(key => urladdresslookup.searchParams.append(key, params[key]));
    (async () => {

      try {
        const response = await fetch(urladdresslookup, {
          method: 'POST',
          headers: sampleHeaders
        })
        const json_resp = await response.json();
        console.log(json_resp);
        if (json_resp.ErrorCode == 0) {
          /// This will check whether the Bill account number is in same state as Agency Admin / User.
          var userState = this.props.auth.user.attributes.website;
          this.setState({ addlistlength: json_resp.AddressList.length });
          if (json_resp.AddressList.length == 1) {
            var acctState = json_resp.AddressList[0].AccountNum.substring(0, 2);
            if (userState == null || this.isUserState(userState, acctState) == true) {
              this.setState({ selectedAddress: json_resp.AddressList[0] });
              const params2 = { accnum: this.state.selectedAddress.AccountNum }
              Object.keys(params2).forEach(key => url.searchParams.append(key, params2[key]));
              (async () => {
                try {
                  const response2 = await fetch(url, {
                    method: 'POST',
                    headers: sampleHeaders
                  })
                  const json_resp2 = await response2.json();
                  console.log(json_resp2);
                  if (json_resp2.ErrorCode == 0) {
                    this.setState({ addresssummaryresp: json_resp2.AccountSummary, addresslookupresp: json_resp2, selectedAddress: json_resp2.AddressList[0] }, () => {
                      const isActive = this.state.selectedAddress.StatCode !== 'S' && this.state.selectedAddress.StatCode !== 'X';
                      const isPwProtected = this.state.selectedAddress.PwdProtectFl === 'Y';
                      console.log(this.state.addresslookupresp.AddressList[0]);
                      console.log(this.state.selectedAddress);
                      console.log(this.state.addresssummaryresp);
                      if (isActive) {
                        if (this.state.addresslookupresp.AddressList[0].CorpInd === 'R') {
                          if (!isPwProtected) {
                            review['ssnbuttondisabledflag'] = false;
                            this.setState({ address_display: true, banner_display: false, search_display: false, account_lookup_error_display: false, review });
                          }
                          else {
                            review['ssnbuttondisabledflag'] = true;
                            this.setState({ errorMsg: pwMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false, review });
                          }
                        }
                        else {
                          review['ssnbuttondisabledflag'] = true;
                          this.setState({ errorMsg: nonresidentialMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false, review });
                        }
                      }
                      else {
                        review['ssnbuttondisabledflag'] = true;
                        this.setState({
                          errorMsg: inactiveMsg, account_lookup_error_display: true, banner_display: true, search_display: true, address_display: false, review
                        });
                      }
                    });
                  }
                  else {
                    review['ssnbuttondisabledflag'] = true;
                    this.setState({ errorMsg: defaultMsg, address_display: false, banner_display: true, search_display: true, account_lookup_error_display: true, review });
                  }
                }
                catch (error) {
                  console.log(error);
                  review['ssnbuttondisabledflag'] = false;
                  this.setState({ errorMsg: defaultMsg, account_lookup_error_display: true, review });
                }
                showSpinner(false);
              })();
            }
            else {
              this.setState({ errorMsg: acctmissmatchStateMsg, address_display: false, banner_display: true, search_display: true, account_lookup_error_display: true });
              showSpinner(false);
            }
          }
          else {
            this.setState({ addresslookupresp: json_resp, selectedAddress: json_resp.AddressList[0], selectedSsnAccount: json_resp.AddressList[0].AccountNum }, () => {
              this.setState({ multiple_ssn_display: true, banner_display: false, search_display: false, account_lookup_error_display: false });
            });
            showSpinner(false);
          }
        }
        else {
          showSpinner(false);
          this.setState({ errorMsg: defaultMsg, address_display: false, banner_display: true, search_display: true, account_lookup_error_display: true });
        }
      }
      catch (error) {
        review['ssnbuttondisabledflag'] = false;
        showSpinner(false);
        this.setState({ errorMsg: defaultMsg, account_lookup_error_display: true, review });
      }

      if (this.state.multiple_ssn_display == true)
        review['ssnbuttondisabledflag'] = true;
      else
        review['ssnbuttondisabledflag'] = false;
      this.setState({ review });
    })();

  }

  accountSummaryFunction = async event => {
    event.preventDefault();
    showSpinner(true);
    console.log("addresslookupresp: ", this.state.addresslookupresp);
    console.log("addresssummaryresp: ", this.state.addresssummaryresp);
    console.log("selectedAddress: ", this.state.selectedAddress);
    const isPwProtected = this.state.addresslookupresp.PwdProtectFl;
    const state = this.state.selectedAddress.State;
    const pip_stat_code = this.state.addresssummaryresp.AssistanceInfo.pip_stat_cd;
    const acct_stat_cd = this.state.addresssummaryresp.CustomerData.acct_stat_cd;
    const discdate1 = this.state.selectedAddress.Disconnectdate_1;
    const discamt1 = this.state.selectedAddress.Disconnectamount_1;
    const discdate2 = this.state.selectedAddress.Disconnectdate_2;
    const discamt2 = this.state.selectedAddress.Disconnectamount_2;
    // winter season
    const winterSeason = checkIfAfterBeginDate(moment().format("MM/DD/YYYY"));
    console.log("winterSeason: ", winterSeason);
    //const winterSeason = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), conf.startDateOHWinterSeason, conf.endDateOHWinterSeason);
    // Winter season 175
    const winterSeason175 = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), conf.startDateOHWinterSeason, conf.endDate175WinterRule);
    console.log(winterSeason);
    if (state === 'OH' && winterSeason175)
      this.state.show175planstatus = true;
    if ((state === 'OH' || state === 'VA') && !(pip_stat_code === '' || pip_stat_code === null || pip_stat_code === undefined))
      this.state.showPIPPSection = true;
    if (this.state.addresssummaryresp.AssistanceInfo.heat_sorc_type === '10')
      this.setState({ heatSourceText: "Gas" });
    else if (this.state.addresssummaryresp.AssistanceInfo.heat_sorc_type === '20')
      this.setState({ heatSourceText: "Electric" });

    console.log(((+this.state.addresssummaryresp.AmountsDue.due_30_days_at + +this.state.addresssummaryresp.AmountsDue.due_60_days_at + +this.state.addresssummaryresp.AmountsDue.due_90_days_at) - +this.state.addresssummaryresp.AmountsDue.bal_due_at).toFixed(2))
    this.setState({ curr_amt_due: (+this.state.addresssummaryresp.AmountsDue.bal_due_at - (+this.state.addresssummaryresp.AmountsDue.due_30_days_at + +this.state.addresssummaryresp.AmountsDue.due_60_days_at + +this.state.addresssummaryresp.AmountsDue.due_90_days_at)).toFixed(2) });
    if (acct_stat_cd === 'A' || acct_stat_cd === 'P' || acct_stat_cd === 'N') {
      this.setState({ isAccountDisconnected: true }, () => {
        console.log(discamt1);
        console.log(discdate1);
        if (discamt1 > 0 && discamt1 !== "" && discamt1 !== undefined)
          this.setState({ disconnectAmt: numberWithCommas(discamt1), disconnectDate: dateWithSlashes(discdate1) });
        else if (discamt2 > 0 && discamt2 !== "" && discamt2 !== undefined)
          this.setState({ disconnectAmt: numberWithCommas(discamt2), disconnectDate: dateWithSlashes(discdate2) });
        else
          this.setState({ isAccountDisconnected: false });
      });
    }

    this.setState({ account_display: true, banner_display: false, search_display: false, address_display: false, header_display: false }, function () {
      this.callbillinghistory();
      this.callAppointmentFlag(state, acct_stat_cd, winterSeason);
      this.callPledgeFlag(acct_stat_cd);
      this.callPledgeDetails();
      this.callPaymentHistory();
    });
  }

  callAppointmentFlag = (state, acct_stat_cd, winterSeason) => {
    console.log(winterSeason);
    // if (state === 'OH' && (acct_stat_cd === 'P' || acct_stat_cd === 'D' || acct_stat_cd === 'N' || acct_stat_cd === 'A') && winterSeason)
    if (state === 'OH' && (acct_stat_cd === 'P' || acct_stat_cd === 'D' || acct_stat_cd === 'N' || acct_stat_cd === 'A')){
      this.setState({ showAppointmentFlag: true })
      this.callAppointmentTypes();
      this.callAppointmentDetails(); 
      }     
  }

  callAppointmentTypes = () => {
    let tableName = ""// "GAA_USER"
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    console.log(this.state.addresslookupresp);
    console.log(this.state.addresssummaryresp);
    console.log(this.state.selectedAddress.AccountNum)
    let formattedquery = "SELECT * FROM agency.get_available_appointments('" + this.state.selectedAddress.AccountNum + "'" + ")";
    //let formattedquery = "SELECT * FROM agency.program where macss_code = 'APTMNT'";
    console.log(formattedquery);
    const params2 = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        if (!response.ok) {
          console.error(`Failed to fetch data. Status code: ${response.status}`);
          return;
        }
        const json_resp = await response.json();
        console.log("Response from RDS:", json_resp);
        let appointmentTypes = json_resp;
        console.log(appointmentTypes);
        this.setState({ appointmentTypes: appointmentTypes});
        console.log("After setting data to state:",this.state.appointmentTypes);
        console.log(this.state.appointmentTypes)
        if (this.state.appointmentTypes.records.length === 0)
        {
          console.log("No Appointments available for this account - Hide Schedule appt flag")
          this.setState({ showAppointmentFlag: false});
        }   
        return;
      }
      catch (error) {
        console.error("An error occurred:", error);
      }
    })();
  }

  callAppointmentDetails = () => {
    let tableName = ""// "GAA_USER"
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    console.log(this.state.addresslookupresp);
    console.log(this.state.addresssummaryresp);
    console.log(this.state.selectedAddress.AccountNum)
    const isAfterBeginDate = checkIfAfterBeginDate(new Date());
    let formattedquery = "SELECT DISTINCT * FROM agency.Appointment WHERE account_number = '" + this.state.selectedAddress.AccountNum + "'";
    console.log(formattedquery);
    const params2 = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log(json_resp);
        // if (json_resp.ResponseMetadata && checkIfBetweenTwoDates(new Date(), conf.startDateOHWinterSeason, conf.endDateOHWinterSeason)) {
        //   this.setState({ showAppointmentFlag: true });
        // }
        if (json_resp.ResponseMetadata && checkIfAfterBeginDate(new Date())) {
          this.setState({ showAppointmentFlag: true });
        }
        else {
          let appointment_details = json_resp;
          let flag = false;
          // if(checkIfBetweenTwoDates(new Date(), conf.startDateOHWinterSeason, conf.endDateOHWinterSeason))
          //   this.setState({ appointmentList: appointment_details, showAppointmentDisplay: true });
          if(isAfterBeginDate)
            this.setState({ appointmentList: appointment_details, showAppointmentDisplay: true });
          else
            this.setState({ appointmentList: appointment_details, showAppointmentDisplay: false });

          for (var key in appointment_details) {
            let temp_date = formatDateFromDDToMM(appointment_details[key]["created_date"]);
            console.log(temp_date);
            // console.log(checkIfBetweenTwoDates(temp_date, conf.startDateOHWinterSeason, conf.endDateOHWinterSeason));
            // if (checkIfBetweenTwoDates(temp_date, conf.startDateOHWinterSeason, conf.endDateOHWinterSeason)) {
            //   flag = true;
            //   break;
            // }
            console.log(checkIfAfterBeginDate(temp_date));
            if (checkIfAfterBeginDate(temp_date)) {
              flag = true;
              break;
            }
          }
          // if (!flag && checkIfBetweenTwoDates(new Date(), conf.startDateOHWinterSeason, conf.endDateOHWinterSeason))
          //   this.setState({ showAppointmentFlag: true });
          if (!flag && isAfterBeginDate)
            this.setState({ showAppointmentFlag: true });
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }

  callPledgeFlag = (acct_stat_cd) => {
    if (acct_stat_cd === 'S' || acct_stat_cd === 'X')
      this.setState({ showPledgeFlag: false });
  }

  callPledgeDetails = () => {
    let tableName = ""// "GAA_USER"
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    console.log(this.state.selectedAddress.AccountNum)
    const {pip_stat_cd} = this.state.addresssummaryresp.AssistanceInfo;
    const {acct_stat_cd} = this.state.addresssummaryresp.CustomerData;
    const {bal_due_at} = this.state.addresssummaryresp.AmountsDue;
    /**Custom query for pledge history which needs to be updated*/
    const params2 = {
      TableName: tableName, CommandType: "PLEDGEHISTORY", AccountNumber: this.state.selectedAddress.AccountNum
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
    (async () => {
      try {
        const state = this.state.selectedAddress.State;
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log("callPledgeDetails json_resp: ", json_resp);
        if (json_resp.Status == undefined) {
          if (json_resp.records == undefined) {
            const sortedList = json_resp.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
            console.log("sortedList: ", sortedList);
            this.setState({ pledgeList: sortedList }, () => {
              if (this.state.pledgeList.length > 0){
                this.setState({ showRecentAssistance: true });
                // Indiana Winter Moratorium
                const hasEAP = this.state.pledgeList.some((pledge) => pledge.agency_transaction_id.includes('EAP'));
                console.log("hasEAP: ", hasEAP);
                if(hasEAP){
                  /**Logic for checking whether there are existing appointments past 1 year for EAP inidana */
                  const eapPledge = this.state.pledgeList.find((pledge) => pledge.agency_transaction_id.includes('EAP'));
                  console.log("eapPledge: ", eapPledge);
                  eapPledge.aep_transaction_id = eapPledge.aep_transaction_id + ' - Winter Moratorium';
                  eapPledge.amount = 'N/A';
                  console.log("eapPledge: ", eapPledge);
                }
                if(hasEAP === false && state === 'IN' && this.state.isINwinterMoratorium){
                  this.setState({ showEnergyAsstFlag: true});
                  console.log("showEnergyAsstFlag callPledgeDetails: ", this.state.showEnergyAsstFlag);
                }
                /**Existing PIPP appointment hold on the account within the last one year*/
                const hasPIPPAccountHold = this.state.pledgeList.some((pledge) => pledge.agency_transaction_id.includes('PIPPAH'));
                if(hasPIPPAccountHold){
                  const pippAHPledge = this.state.pledgeList.find((pledge) => pledge.agency_transaction_id.includes('PIPPAH'));
                  pippAHPledge.aep_transaction_id = pippAHPledge.aep_transaction_id + ' - PIPP Application Hold';
                } else if(!hasPIPPAccountHold && state === 'VA' && pip_stat_cd !== 'A' && (acct_stat_cd === 'N' || acct_stat_cd === 'A') && Number(bal_due_at)>0){
                  this.setState({showPIPPApplicationHold: true});
                }
                /**Existing Indiana appointment hold on the account within the last one year*/
                const hasINAccountHold = this.state.pledgeList.some((pledge) => pledge.agency_transaction_id.includes('INAH'));
                if(hasINAccountHold){
                  const applicationHoldPledge = this.state.pledgeList.find((pledge) => pledge.agency_transaction_id.includes('INAH'));
                  applicationHoldPledge.aep_transaction_id = applicationHoldPledge.aep_transaction_id + ' - Application Hold';
                } else if(!hasINAccountHold && this.state.isNovember && state === 'IN' 
                  && (acct_stat_cd === 'A' || acct_stat_cd === 'N' || acct_stat_cd === 'P' || acct_stat_cd === 'D') && Number(bal_due_at)>0){
                  this.setState({showApplicationHold: true});
                }
              }
            });
          }
          else if(json_resp.records.length === 0 && state === 'IN'){
            /*If winter season*/
            if(this.state.isINwinterMoratorium){
              this.setState({showEnergyAsstFlag: true});
            }
            /*If Account status is Active, New, Pending, Disconnect and Due Amount greater than 0 and isNovember*/
            if((acct_stat_cd === 'A' || acct_stat_cd === 'N' || acct_stat_cd === 'P' || acct_stat_cd === 'D') && Number(bal_due_at)>0 && isNovember){
              this.setState({showApplicationHold: true});
            }
          } else if(json_resp.records.length === 0 && state === 'VA' && pip_stat_cd !== 'A' && (acct_stat_cd === 'N' || acct_stat_cd === 'A') && Number(bal_due_at)>0){
            this.setState({showPIPPApplicationHold: true});
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }

  ssnAccountSummaryFunction = async event => {
    event.preventDefault();
    console.log(this.state.selectedAddress);
    showSpinner(true);
    const sampleHeaders = {
      'Content-Type': 'application/json',
      'x-api-key': conf.accountSearchShadowService.token
    };
    var url = new URL(conf.shadowServiceGenericURL.url + "accountsearchconsolidated");
    const params = { accnum: this.state.selectedSsnAccount }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: sampleHeaders
        })
        const json_resp = await response.json();
        console.log("this is from ssn search:-" + json_resp);
        if (json_resp.ErrorCode == 0) {
          this.setState({ addresssummaryresp: json_resp.AccountSummary,addresslookupresp: json_resp, selectedAddress: json_resp.AddressList[0] }, () => {
            const state = this.state.selectedAddress.State;
            const pip_stat_code = this.state.addresssummaryresp.AssistanceInfo.pip_stat_cd;
            const acct_stat_cd = this.state.addresssummaryresp.CustomerData.acct_stat_cd;
            const winterSeason = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), conf.startDateOHWinterSeason, conf.endDateOHWinterSeason);
            const winterSeason175 = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), conf.startDateOHWinterSeason, conf.endDate175WinterRule);
            const discdate1 = this.state.selectedAddress.Disconnectdate_1;
            const discamt1 = this.state.selectedAddress.Disconnectamount_1;
            const discdate2 = this.state.selectedAddress.Disconnectdate_2;
            const discamt2 = this.state.selectedAddress.Disconnectamount_2;
            console.log(this.state.selectedAddress);
            console.log(this.state.addresslookupresp);
            if (state === 'OH' && winterSeason175)
              this.state.show175planstatus = true;
            if ((state === 'OH' || state === 'VA') && !(pip_stat_code === '' || pip_stat_code === null || pip_stat_code === undefined))
              this.state.showPIPPSection = true;
            if (this.state.addresssummaryresp.AssistanceInfo.heat_sorc_type === '10')
              this.setState({ heatSourceText: "Gas" });
            else if (this.state.addresssummaryresp.AssistanceInfo.heat_sorc_type === '20')
              this.setState({ heatSourceText: "Electric" });

            this.setState({ curr_amt_due: (+this.state.addresssummaryresp.AmountsDue.bal_due_at - (+this.state.addresssummaryresp.AmountsDue.due_30_days_at + +this.state.addresssummaryresp.AmountsDue.due_60_days_at + +this.state.addresssummaryresp.AmountsDue.due_90_days_at)).toFixed(2) });

            if (acct_stat_cd === 'A' || acct_stat_cd === 'P' || acct_stat_cd === 'N') {
              this.setState({ isAccountDisconnected: true }, () => {
                console.log(discamt1);
                console.log(discdate1);
                if (discamt1 > 0 && discamt1 !== "" && discamt1 !== undefined)
                  this.setState({ disconnectAmt: numberWithCommas(discamt1), disconnectDate: dateWithSlashes(discdate1) });
                else if (discamt2 > 0 && discamt2 !== "" && discamt2 !== undefined)
                  this.setState({ disconnectAmt: numberWithCommas(discamt2), disconnectDate: dateWithSlashes(discdate2) });
                else
                  this.setState({ isAccountDisconnected: false });
              });
            }

            this.state.selectedAddress.BillCheckDigitNb = json_resp.AddressList[0].BillCheckDigitNb;
            this.setState({ account_display: true, banner_display: false, search_display: false, address_display: false, header_display: false, multiple_ssn_display: false }, function () {
              this.callAppointmentFlag(state, acct_stat_cd, winterSeason);
              this.callPledgeFlag(acct_stat_cd);
              this.callPledgeDetails();
              this.callbillinghistory();
              this.callPaymentHistory();
            });
          });
        }
        else
          this.setState({ address_display: false, banner_display: true, search_display: true, ssn_lookup_error_display: true });
      }
      catch (error) {
        console.log(error);
        this.setState({ errorMsg: defaultMsg, ssn_lookup_error_display: true });
      }
      showSpinner(false);
    })();
  }

  getLastPaymentDetails = (array) => {
    console.log(array);
    if (array.length > 0) {
      var latestpayment = array.filter(y => y.mia_rvrs_cd == "O")[0]
      console.log(latestpayment);
      console.log(moment(latestpayment.mia_pymt_ts, "YYYY-MM-DD").format("MM/DD/YYYY"));
      console.log(moment(latestpayment.mia_pymt_ts, "YYYY-MM-DD"));
      this.setState({
        lastPaymentDate: moment(latestpayment.mia_pymt_ts, "YYYY-MM-DD").format("MM/DD/YYYY"),
        lastPaymentAmount: Number(this.stringtoPositiveNumber(latestpayment.mia_pymt_at)).toFixed(2)
      });
    }
  }

  viewAllBillingSummary = () => {
    this.setState({ key: "billing-history" });
  }

  viewAllRecentAssistance = () => {
    this.setState({ key: "pledge-history" });
  }

  viewAllRecentAppointments = () => {
    this.setState({ key: "appointment-history" });
  }

  viewAllUsageSummary = () => {
    this.setState({ key: "usage-history" });
  }

  callPaymentHistory = () => {
    const sampleHeaders = {
      'Content-Type': 'application/json',
      'x-api-key': conf.accountSearchShadowService.token
    };
    var url = new URL(conf.shadowServiceGenericURL.url + "paymenthistory");
    const params = { accnum: this.state.selectedAddress.AccountNum };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    (async () => {
      try {
        const [response] = await Promise.all([
          fetch(url, {
            method: 'POST',
            headers: sampleHeaders
          }),
        ]);
        const json_resp = await response.json();
        console.log(json_resp);
        if (json_resp.ErrorCode == 0) {
          var validpaymentHistories = [];
          var date = new Date();
          date.setMonth(date.getMonth() - 12);
          console.log(date);
          var paymentResp = json_resp;
          for (var i = 0; i < paymentResp.PaymentHistory.length; i++)
            validpaymentHistories.push(paymentResp.PaymentHistory[i]);
          this.getLastPaymentDetails(validpaymentHistories);
          if (validpaymentHistories.length > 0) {
            paymentResp.PaymentHistory = validpaymentHistories;
            this.setState({ paymenthistoryresp: paymentResp }, () => {
              let sum = 0;
              console.log(this.state.paymenthistoryresp)
              for (let i = 0; i < this.state.paymenthistoryresp["PaymentHistory"].length; i++) {
                if (this.getPatmentStatus(this.state.paymenthistoryresp["PaymentHistory"][i].mia_pymt_ind_cd,
                  this.state.paymenthistoryresp["PaymentHistory"][i].mia_rvrs_cd,
                  this.state.paymenthistoryresp["PaymentHistory"][i].mia_pymt_cshr_cd) === 'Pending')
                  sum += Number(this.state.paymenthistoryresp["PaymentHistory"][i].mia_pymt_at);
              }
              this.setState({ pendingPayments: sum });
            });
            this.setState({ payment_history_display: true });
          }
          else {
            this.setState({ payment_history_display: false });
          }
        } else {
          this.setState({ payment_history_display: false });
        }
        this.setState({ paymenthistoryloaded: true });
      } catch (error) {
        console.log(error);
      }
      finally {
        showSpinner(false);
      }
    })();
  }

  loadTabs = (key) => {
    if (key == "billing-history" || key == "usage-history" || key == "account-summary" || key == "payment-history" || key == "pledge-history" || key == "appointment-history") {
      this.setState({ key })
      console.log("\n *STARTING* \n");
      console.log(this.state.selectedAddress.AccountNum + this.state.selectedAddress.BillCheckDigitNb)
      this.setState({ key });
    }
  }

  async displayPDF(e, date, subtype) {
    e.preventDefault();
    showSpinner(true);
    console.log("\n *STARTING* \n");
    const sampleHeaders = {
      'Content-Type': 'application/text',
      'x-api-key': conf.accountSearchShadowService.token
    };
    var url = new URL(conf.shadowServiceGenericURL.url + "billingpdf");
    const params = { accnum: this.state.selectedAddress.AccountNum, subtype: subtype, docdate: date };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    (async () => {
      try {
        const [response] = await Promise.all([
          fetch(url, {
            method: 'POST',
            headers: sampleHeaders
          }),
        ]);
        const file = await response.text();
        if (file != "Failed to retrieve PDF") {
          var contentType = 'application/pdf';
          var sliceSize = 512;
          var byteCharacters = atob(file);
          var byteArrays = [];
          for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          var blob = new Blob(byteArrays, { type: contentType });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
          }
          else {
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          }
        } else {
          alert("Unable to retrieve bill")
        }
      } catch (error) {
        console.log(error);
      }
      showSpinner(false);
    })();
  }

  callbillinghistory = () => {
    if (!this.state.billingHistoryLoaded) {
      console.log("\n *STARTING* \n");
      console.log(this.state.selectedAddress.AccountNum + this.state.selectedAddress.BillCheckDigitNb)
      const sampleHeaders = {
        'Content-Type': 'application/json',
        'x-api-key': conf.accountSearchShadowService.token
      };
      var url = new URL(conf.shadowServiceGenericURL.url + "billinghistoryconsolidated");
      const params = { accnum: this.state.selectedAddress.AccountNum };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
      (async () => {
        try {
          const [response] = await Promise.all([
            fetch(url, {
              method: 'POST',
              headers: sampleHeaders
            }),
          ]);
          const json_resp = await response.json();
          if (json_resp.ErrorCode == 0) {
            var validBillingHistories = [];
            var date = new Date();
            date.setMonth(date.getMonth() - 12);
            console.log(date);
            var billResp = await json_resp;
            for (var i = 0; i < billResp.BillingHistory.length; i++) {
              var billDate = new Date(billResp.BillingHistory[i].bill_date);
              if (billDate >= date) {
                validBillingHistories.push(billResp.BillingHistory[i]);
              }
            }
            if (validBillingHistories.length > 0) {
              billResp.BillingHistory = validBillingHistories;

              this.setState({ billingHistoryResp: billResp });
              this.setState({ billing_history_display: true });
            }
            else {
              this.setState({ billing_history_display: false });
            }
          } else {
            this.setState({ billing_history_display: false });
          }
          this.setState({ billingHistoryLoaded: true });
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }

  //Convert String to Number
  stringtoPositiveNumber(n) {
    var numberString = n.toString();
    if (numberString[0] === '-') {
      return numberString.substring(1);
    }
    else {
      return numberString;
    }
  }

  // Define Payment Status
  getPatmentStatus(indicator, reverseCode, cashiercode) {
    var getStatus;
    if (indicator == "R" || indicator == "Y")
      return getStatus = "Pending";
    else if (indicator == "N" && reverseCode == "R")
      return getStatus = "Returned";
    else if (indicator == "N" && reverseCode == "C")
      return getStatus = "Corrected";
    else if (indicator == "N" && reverseCode == "")
      return getStatus = "Energy Assistance";
    else if (indicator == "N" && reverseCode == "O") {
      if (cashiercode == "" || isNaN(cashiercode))
        return getStatus = "Energy Assistance";
      else
        return getStatus = "Processed";
    }
    else
      return getStatus = "Cash";
  }

  render() {
    return (
      <section>
        {/* banner */}
        {this.state.banner_display && <Banner />}

        {/* main content */}
        <div class="container">
          <div class="sum-container"></div>
          {this.state.header_display &&
            <div class="row mt-st mb-40">
              <div class="col-sm-11 col-xl-10">
                <h1>CUSTOMER ASSISTANCE</h1>
                <p class="mb-0">Once you locate a customer's account, you can research information such as their billing, usage, and payment histories. And, if applicable, you can make an assistance pledge to assist that customer.</p>
              </div>
            </div>
          }

          {/* search display */}
          {this.state.search_display &&
            <div class="row">
              <div class="col-sm-11">
                <div class="bordered-form">

                  {/* errors */}
                  {this.state.account_lookup_error_display &&
                    <div class="row">
                      <div class="col-12 error mb-30">
                        <div class="error">{this.state.errorMsg}</div>
                      </div>
                    </div>
                  }

                  <h3 class="mb-40">To locate a customer's account, select a search option:</h3>
                  <div class="row">
                    <div class="col-12 search-tabs">
                      <Tabs activeKey={this.state.keySelectedSearch} onSelect={(k) => this.setState({ keySelectedSearch: k, errorMsg: '', account_lookup_error_display: false })}>
                        <Tab eventKey="account-number-search" title="Account #" className='mt-30'>
                          <form onSubmit={this.accountSearchFunction}>
                            <div class="row mb-20">
                              <div class="col-sm-9 col-md-4">
                                <div id="cphContentMain_accnum_cphContentMain_accnum_wrapper" class="form-group aep-custom moveable-label">
                                  <div class="input-group">
                                    <PatternFormat format="###-###-###-#-#" mask="_" name="accnum" required id='cphContentMain_accnum' class="form-control account-search" data-field-type="number" value={this.state.accnum} placeholder="Account Number" onValueChange={(values) => this.handleChangeAccountNum(values)} />
                                    <label htmlFor='cphContentMain_phone_num'>11-digit Account Number</label>
                                  </div>
                                  {this.state.errors["accountfielderror"] !== '' && (<span id="cphContentMain_accnum_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_accnum" > {this.state.errors["accountfielderror"]}</span>)}
                                </div>
                              </div>
                            </div>
                            {/* {this.state.account_lookup_error_display &&
                              <div class="row">
                                <div class="col-6 error">
                                  <p>Could not locate account</p>
                                </div>
                              </div>
                            } */}
                            <div class="row">
                              <div class="col-6">
                                <button class="btn btn-primary" disabled={this.state.review["buttondisabledflag"]}>Continue</button>
                              </div>
                            </div>
                          </form>
                        </Tab>
                        <Tab eventKey="ssn-search" title="Name + SSN" className='mt-30'>
                          <form onSubmit={this.SSNSearchFunction}>
                            <div class="form-row mb-20">
                              <div class="col-9 col-md-4">
                                <div class="aep-custom form-group">
                                  <div class="input-group">
                                    <PatternFormat
                                      format="####" mask="_"
                                      className="form-control account-search"
                                      id="ssn"
                                      aria-describedby="accHelp"
                                      placeholder="Social Security Number"
                                      value={this.state.ssn}
                                      onValueChange={(values) => this.handleChangeSsnNum(values)}
                                      required
                                    />
                                  </div>
                                  {this.state.errors["ssnfielderror"] !== '' && (<span id="cphContentMain_ssn_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="ssn" > {this.state.errors["ssnfielderror"]}</span>)}
                                </div>
                              </div>
                              <div class="col-9 col-md-4">
                                <div class="aep-custom form-group">
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control account-search"
                                      id="lastname"
                                      aria-describedby="accHelp"
                                      placeholder="Customer Last Name"
                                      defaultValue={this.state.lastname}
                                      onChange={this.onLastnameInputChange}
                                      required
                                    />
                                  </div>
                                  {this.state.errors["lastnamefielderror"] !== '' && (<span id="cphContentMain_lastname_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="lastname" > {this.state.errors["lastnamefielderror"]}</span>)}                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <button class="btn btn-primary" disabled={this.state.review["ssnbuttondisabledflag"]}>Continue</button>
                              </div>
                            </div>
                          </form>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* address display */}
          {this.state.address_display &&
            <div class="row">
              <div class="col-sm-11 col-lg-8">
                <div class="bordered-form">
                  <div class="row">
                    <div class="col-sm-11">
                      {this.state.selectedAddress.AccountNum &&
                        <div>
                          <b>We've located a matching address.</b>
                          <br /><br />
                          For account number <b>{addHypensAccountNum(this.state.selectedAddress.AccountNum)}-{this.state.selectedAddress.BillCheckDigitNb}</b>, the service address is:
                          <br /><br />
                          <b>{this.state.addresssummaryresp.CustomerData.cust_1st_nm} {this.state.addresslookupresp.CustMiddlenm} {this.state.addresssummaryresp.CustomerData.cust_last_nm} {this.state.addresslookupresp.CustSuffixnm}</b>
                          <br />
                          <b>{this.state.selectedAddress.HouseNumber} {this.state.selectedAddress.StreetName} {this.state.selectedAddress.StreetDsgt} {this.state.selectedAddress.UnitDesignator} {this.state.selectedAddress.UnitNumber}</b>
                          <br />
                          <b>{this.state.selectedAddress.City},{this.state.selectedAddress.State} {this.state.selectedAddress.ZipCode}</b>
                        </div>}
                      <br />
                      <p class="mb-30">Is this the correct service address?</p>
                      <div class="d-flex flex-wrap align-items-center justify-content-start">
                        <div class="mb-20 mb-md-0">
                          <button class="btn btn-primary mr-20" onClick={this.accountSummaryFunction}>Yes, continue</button>
                        </div>
                        <div class="mb-20 mb-md-0">
                          <button class="btn btn-secondary" onClick={this.changeToAccountSearch}>No, search again</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {/* multiple match display */}
          {this.state.multiple_ssn_display &&
            <div class="row">
              <div class="col-sm-11 col-xl-8">
                <div class="bordered-form">
                  <div class="row">
                    <div class="col-12">
                      <p>We've located matching addresses.</p>
                      <p>We found {this.state.addlistlength} matches for customer {this.state.lastname} ***{this.state.ssn}. Please select one.</p>
                      <div>
                        <div class="row mb-20">
                          <div class="col-1">
                          </div>
                          <div class="col-4">
                            <b>Address</b>
                          </div>
                          <div class="col-3">
                            <b>Building Code</b>
                          </div>
                          <div class="col-4">
                            <b>Account Status</b>
                          </div>
                        </div>
                      </div>
                      {Object.entries(this.state.addresslookupresp.AddressList).map(([key, customer_address]) => (
                        <div>
                          <div class="row mb-20">
                            <div class="col-1">
                              <input type="radio" value={key} name="ssn-select" onChange={this.onValueChange} /></div>
                            <div class="col-4">
                              {customer_address.HouseNumber} {customer_address.StreetName} {customer_address.StreetDsgt}<br />
                              {customer_address.City}, {customer_address.State} {customer_address.ZipCode}
                            </div>
                            <div class="col-3">
                              {this.state.buildingtypelookup[customer_address.CategoryCode] == null ? "Unknown" : this.state.buildingtypelookup[customer_address.CategoryCode]}
                            </div>
                            <div class="col-4">
                              {this.state.accountstatuslookup[customer_address.StatCode] == null ? "Unknown" : this.state.accountstatuslookup[customer_address.StatCode]}
                            </div>
                            {/* error msg*/}
                            {this.state.ssn_lookup_error_display && this.state.selectedSsnAccount === customer_address.AccountNum &&
                              <div class="col-12" visisble={this.state.selectedAddress}>
                                <div class="row mb-0 mt-10">
                                  <div class="col-1"></div>
                                  <div class="col-10 mr-auto">
                                    <div class="invalid-feedback validator error">{this.state.errorMsg}</div>
                                  </div>
                                </div>
                              </div>}
                          </div>
                        </div>
                      ))}
                      <div class="row mt-st">
                        <div class="col-12">
                          <button class="btn btn-primary mr-20" onClick={this.ssnAccountSummaryFunction} disabled={this.state.review["ssnbuttondisabledflag"]}>Continue</button>
                          <button class="btn btn-secondary" onClick={this.changeToAccountSearchFromSsn}>Search again</button>
                        </div>
                      </div>
                      {this.state.errors["errorMultipleCorp"] !== '' && (<span id="cphContentMain_ssn_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2 mt-20" data-validate-field="ssn" > {this.state.errors["errorMultipleCorp"]}</span>)}
                    </div>
                  </div>
                </div>
              </div>
            </div >
          }

          {/* account display */}
          {this.state.account_display &&
            <div>
              <div class="row mt-st">
                <div class="col-12">
                  <h2>{this.state.addresssummaryresp.CustomerData.cust_1st_nm} {this.state.addresslookupresp.CustMiddlenm} {this.state.addresssummaryresp.CustomerData.cust_last_nm} {this.state.addresslookupresp.CustSuffixnm}</h2>
                </div>
              </div>
              <br />
              <div class="row mb-20">
                <div class="col-md-4 mb-15">
                  <p class="text-bolder mb-10">Service Location:</p>
                  <p class="mb-10 text-uppercase"> {this.state.selectedAddress.HouseNumber} {this.state.selectedAddress.StreetName} {this.state.selectedAddress.StreetDsgt} {this.state.selectedAddress.UnitDesignator} {this.state.selectedAddress.UnitNumber} </p>
                  <p class="text-uppercase">{this.state.selectedAddress.City} {this.state.selectedAddress.State} {this.state.selectedAddress.ZipCode} </p>
                  <p class="mb-10"><span class="text-bolder">Phone Number:</span> {this.state.addresssummaryresp && (addDottedPhonenumber(this.state.addresssummaryresp["CustomerData"].tlph_nb))}</p>
                </div>

                <div class="col-md-4 mb-15 mb-md-0">
                  <p class="mb-10"><span class="text-bolder">Account Number:</span> {addHypensAccountNum(this.state.selectedAddress.AccountNum) + '-' + this.state.selectedAddress.BillCheckDigitNb}</p>
                  <p class="mb-10"><span class="text-bolder">Account Status:</span> {this.state.addresssummaryresp && (this.mapCodeToStatus(this.state.addresssummaryresp["CustomerData"].acct_stat_cd))}</p>
                  <p class="mb-10"><span class="text-bolder">Account Type:</span> {this.state.addresssummaryresp && (this.state.addresssummaryresp["CustomerData"].acct_clas_cd == "R" ? "Residential" : "Non-Residential")}</p>

                </div>

                <div class="col-12 col-sm-11 col-lg-4 text-align-right">
                    <button class="btn btn-secondary mb-20" onClick={this.changeCustomer}>Change Customer</button>
                </div>

              </div>

              <div class="row">
                <div class="col-12">
                  <hr class="divider-sm mb-40"></hr>
                </div>
              </div>
              <div class="row mb-40">
                <div class="col-12">
                  <div class="bordered-form">
                    <div class="row">
                      <div class="col">
                        <b>ACCOUNT OVERVIEW</b>
                        <br />
                        <br />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-md-4 mb-30 mb-sm-0">
                        <p>Total due:</p>
                        <h3>${numberWithCommas((this.state.addresssummaryresp.AmountsDue.bal_due_at - this.state.pendingPayments).toFixed(2))}</h3>
                        {this.state.isAccountDisconnected && (<p> Amount to avoid disconnect: $ {numberWithCommas(this.state.addresssummaryresp.CollectionInfo.totl_dscn_at)}</p>)}
                        <p>Deposit amount due: ${numberWithCommas(this.state.addresssummaryresp.AmountsDue.deposit_due_at)}</p>
                      </div>
                      <div class="col-sm-6 col-md-4">
                        <p>Current bill due:</p>
                        <h3>{this.state.addresssummaryresp.AmountsDue.bill_due_dt !== "" ? dateWithSlashes(this.state.addresssummaryresp.AmountsDue.bill_due_dt) : "N/A"}</h3>
                        {this.state.isAccountDisconnected && (<p>Disconnect date: {this.state.disconnectDate !== "" ? this.state.disconnectDate : "N/A"}</p>)}
                        <p>Next bill date: {this.state.addresssummaryresp.AmountsDue.next_bill_dt !== "" ? dateWithSlashes(this.state.addresssummaryresp.AmountsDue.next_bill_dt) : "N/A"}</p>
                      </div>
                      <div class="col-md-4 align-buttons">
                        <div>{this.state.showPledgeFlag && (<button class="btn btn-primary mb-20 mr-20" onClick={this.handlePledge}>Make a Pledge</button>)}</div>
                        <div>{this.state.showAppointmentFlag && (<button class="btn button-without-border" style={{padding: '0'}} onClick={this.handleAppointment}>Schedule appointment</button>)}</div>
                        {/* Indiana EAP */}
                        <div>{this.state.showEnergyAsstFlag && (<button class="btn button-without-border" style={{padding: '0'}} onClick={this.handleAssistanceProgram}>Add winter moratorium protection</button>)}</div>
                        {/* VA PIPP Application Hold */}
                        <div>{this.state.showPIPPApplicationHold && (<button class="btn button-without-border" style={{padding: '0'}} onClick={this.handlePIPPApplicationHold}>PIPP Application Hold</button>)}</div>
                        {/* Indiana Application Hold */}
                        <div>{this.state.showApplicationHold && (<button class="btn button-without-border" style={{padding: '0'}} onClick={this.handleApplicationHold}>Place application on hold</button>)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-0 acct-summary">
                <div class="col-12">
                  <Tabs activeKey={this.state.key} onSelect={this.loadTabs}>
                    <Tab eventKey="account-summary" title="Account Summary">
                      <h2 class="mb-10">ACCOUNT SUMMARY</h2>
                      {this.state.showRecentAssistance && (
                        <div class="mb-30">
                          <div class="row mb-15 d-flex align-items-center">
                            <div class="col-6">
                              <h4>Pledge Summary</h4>
                            </div>
                            <div class="col-6 text-sm-right">
                              <a class="btn view-all font-weight-bold" onClick={this.viewAllRecentAssistance}>
                                <span class="align-middle mr-2">View All</span>
                                <img src={View_All_Logo} alt="View All Recent Asistance" style={{ width: '18px' }} />
                              </a>
                            </div>
                          </div>

                          <div class="table-row-dividers w-100">
                            <div class="row mb-15">
                              <div class="col-sm-4 col-md-4 col-lg-4">
                                <b>Date Created</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-4">
                                <b>Amount</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-4">
                                <b>Transaction ID</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-4"></div>
                            </div>
                            {this.state.pledgeList.slice(0, 3).map((pledgeInfo, key) =>
                              <div key={key}>
                                <div class="row mb-10">
                                  <div class="col-sm-4 col-md-4 col-lg-4">
                                    {formatToMMDDYYYY(pledgeInfo.created_date)}
                                  </div>
                                  <div class="col-sm-4 col-md-4 col-lg-4">
                                    {pledgeInfo.amount != 'N/A' ? '$' + numberWithCommas(pledgeInfo.amount) : pledgeInfo.amount}
                                  </div>
                                  <div class="col-sm-4 col-md-4 col-lg-4">
                                    {pledgeInfo.aep_transaction_id}
                                  </div>
                                </div>
                                <div class="row mb-10">
                                  <div class="col-12">
                                    <hr class="divider-sm"></hr>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>)}

                      {this.state.showAppointmentDisplay && (
                        <div class="mb-30">
                          <div class="row mb-15 d-flex align-items-center">
                            <div class="col-6">
                              <h4>Appointment Summary</h4>
                            </div>
                            <div class="col-6 text-sm-right">
                              <a class="btn view-all font-weight-bold" onClick={this.viewAllRecentAppointments}>
                                <span class="align-middle mr-2">View All</span>
                                <img src={View_All_Logo} alt="View All Recent Asistance" style={{ width: '18px' }} />
                              </a>
                            </div>
                          </div>
                          <div class="table-row-dividers w-100">
                            <div class="row">
                              <div class="col-sm-4 col-md-4 col-lg-3">
                                <b>Appointment Date</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-3">
                                <b>Agency Location ID</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-3">
                                <b>Appointment ID</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-3">
                                <b>Appointment Type</b>
                              </div>
                              <div class="col-sm-4 col-md-4 col-lg-4"></div>
                            </div>
                            <div class="row mb-20">
                            </div>
                            {this.state.appointmentList.map((appointment, key) =>
                              <div key={key}>
                                <div class="row mb-20">
                                  <div class="col-sm-4 col-md-4 col-lg-3">
                                    {dateWithSlashes(appointment.created_date)}
                                  </div>
                                  <div class="col-sm-4 col-md-4 col-lg-3">
                                    {appointment.agency_location_id}
                                  </div>
                                  <div class="col-sm-4 col-md-4 col-lg-3">
                                    {appointment.appointment_id}
                                  </div>
                                  <div class="col-sm-4 col-md-4 col-lg-3">
                                    {appointment.appointment_type}
                                  </div>
                                </div>
                                <div class="row mb-20">
                                  <div class="col-12">
                                    <hr class="divider-sm"></hr>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="row mb-20 d-flex align-items-center">
                            <div class="col-7">
                              <h4>Billing Summary</h4>
                            </div>
                            <div class="col-5 text-sm-right">
                              <button class="btn view-all font-weight-bold" onClick={this.viewAllBillingSummary}>
                                <span class="align-middle mr-2">View All</span>
                                <img src={View_All_Logo} alt="View All Billing Summary" style={{ width: '18px' }} />
                              </button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              Last Payment Amount:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              {this.state.lastPaymentAmount === undefined || this.state.lastPaymentAmount === '' ? 'N/A' : <span>${numberWithCommas(this.state.lastPaymentAmount)}</span>}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              Last Payment Received:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              {this.state.lastPaymentDate === undefined || this.state.lastPaymentDate === '' ? 'N/A' : <span>{this.state.lastPaymentDate}</span>}
                            </div>
                          </div>
                          {this.state.show175planstatus && (
                            <div class="row">
                              <div class="col-sm-7">
                                $175 Winter Rule Available:
                              </div>
                              <div class="col-sm-5 text-sm-right">
                                {this.state.addresssummaryresp.AssistanceInfo.pip_175_fl === "N" ? "Yes" : "No"}
                              </div>
                            </div>
                          )
                          }
                          <div class="row">
                            <div class="col-sm-7">
                              Account Balance:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              ${numberWithCommas(this.state.addresssummaryresp.AmountsDue.acct_bal_at)}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              Current Amount Due:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              ${numberWithCommas(this.state.curr_amt_due)}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              30 day arrears:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              ${numberWithCommas(this.state.addresssummaryresp.AmountsDue.due_30_days_at)}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              60 day arrears:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              ${numberWithCommas(this.state.addresssummaryresp.AmountsDue.due_60_days_at)}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-7">
                              90 {'>'} day arrears:
                            </div>
                            <div class="col-sm-5 text-sm-right">
                              ${numberWithCommas(this.state.addresssummaryresp.AmountsDue.due_90_days_at)}
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="row mb-20 d-flex align-items-center">
                            <div class="col-7">
                              <h4>Usage Summary</h4>
                            </div>
                            <div class="col-5 text-sm-right">
                              <button class="btn view-all font-weight-bold" onClick={this.viewAllUsageSummary}>
                                <span class="align-middle mr-2">View All</span>
                                <img src={View_All_Logo} alt="View All Usage Summary" style={{ width: '18px' }} />
                              </button>
                            </div>
                          </div>
                          {!this.state.billing_history_display &&
                            <div>
                              <span>No usage history is currently available for this account.</span>
                            </div>
                          }

                          {this.state.billing_history_display &&

                            <div class="table-row-dividers w-100">

                              <div class="row mb-9">
                              </div>
                              {Object.entries(this.state.billingHistoryResp.BillingHistory.filter(usage => usage.bill_kwh_qy != 0)).slice(0, 3).map(([key, billingInfo]) => (
                                <div key={key}>
                                  <div class="row mb-9">
                                    <div class="col-sm-4 text-sm-left">
                                      {dateWithSlashes(billingInfo.read_date)}
                                    </div>
                                    <div class="col-sm-8 text-sm-right">
                                      {numberWithCommasWithLeadingZeroes(billingInfo.bill_kwh_qy)}kWh
                                    </div>
                                  </div>
                                  <div class="row mb-9">

                                  </div>
                                </div>

                              ))}
                            </div>
                          }
                        </div>
                      </div>

                      {this.state.showPIPPSection && (<div class="row mt-40">
                        <div class="col-sm-6">
                          <div class="row mb-20">
                            <div class="col-xl-6 col-sm-6 center-align">
                              <h4>PIPP</h4>
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right"></div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              PIPP Status:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_stat_cd === 'A' && this.state.addresssummaryresp.AssistanceInfo.pip_fl === 'Y') ? "Active" : "Inactive"}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              PIPP Amount:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_at === undefined || this.state.addresssummaryresp.AssistanceInfo.pip_at === '') ? "N/A" : <span>${numberWithCommas(this.state.addresssummaryresp.AssistanceInfo.pip_at)}</span>}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              PIPP Past Due Amount:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_past_due_at === undefined || this.state.addresssummaryresp.AssistanceInfo.pip_past_due_at === '') ? "N/A" : <span>${numberWithCommas(this.state.addresssummaryresp.AssistanceInfo.pip_past_due_at)}</span>}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              Arrearage Credit Amount:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.arr_crdt_at === undefined || this.state.addresssummaryresp.AssistanceInfo.arr_crdt_at === '') ? "N/A" : <span>${numberWithCommas(this.state.addresssummaryresp.AssistanceInfo.arr_crdt_at)}</span>}
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              Drop Date:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_drop_dt === undefined || this.state.addresssummaryresp.AssistanceInfo.pip_drop_dt === '') ? "N/A" : <span>{dateWithSlashes(this.state.addresssummaryresp.AssistanceInfo.pip_drop_dt)}</span>}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              Drop Reason:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_inactv_rsn === undefined || this.state.addresssummaryresp.AssistanceInfo.pip_inactv_rsn === '') ? "N/A" : <span>{capitalizeFirstLetter(this.state.addresssummaryresp.AssistanceInfo.pip_inactv_rsn)}</span>}
                            </div>
                          </div>
                          {this.state.selectedAddress.State === 'VA' && 
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              Reverify Date:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              <span>{this.state.addresssummaryresp.AssistanceInfo.pip_reverify_dt && 
                                  moment(this.state.addresssummaryresp.AssistanceInfo.pip_reverify_dt, "YYYY-MM-DD").format("MM/DD/YYYY")}
                              </span>
                            </div>
                          </div> }
                          {this.state.selectedAddress.State === 'OH' && 
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              PIPP Required Amount:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              {(this.state.addresssummaryresp.AssistanceInfo.pip_reqd_at === undefined || this.state.addresssummaryresp.AssistanceInfo.pip_reqd_at === '') ? "N/A" : <span>${numberWithCommas(this.state.addresssummaryresp.AssistanceInfo.pip_reqd_at)}</span>}
                            </div>
                          </div> }
                          <div class="row">
                            <div class="col-xl-6 col-sm-6">
                              Heat Source Type:
                            </div>
                            <div class="col-xl-6 col-sm-6 text-sm-right">
                              <span>{this.state.heatSourceText}</span>
                            </div>
                          </div>
                        </div>
                      </div>)}

                    </Tab>

                    <Tab eventKey="payment-history" title="Payment History">
                      <h2>PAYMENT HISTORY</h2>
                      <label>Up to 13 months of payment history will be shown, if available.</label>
                      <div class="row mb-20">
                      </div>
                      {!this.state.payment_history_display &&
                        <div>
                          <h3>No payments are currently available for this account.</h3>
                        </div>
                      }

                      {this.state.payment_history_display &&
                        <div class="table-row-dividers w-100">

                          <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              {/* <input type="checkbox" class="users-checkbox" onChange={() => this.selectionAllChanged()}/> */}
                              <b>STATUS</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>DATE</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>AMOUNT</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4"></div>
                          </div>
                          <div class="row mb-20">
                          </div>
                          {Object.entries(this.state.paymenthistoryresp.PaymentHistory).map(([key, PaymentHistory]) => (
                            <div key={key}>
                              <div class="row mb-20">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {this.getPatmentStatus(PaymentHistory.mia_pymt_ind_cd, PaymentHistory.mia_rvrs_cd, PaymentHistory.mia_pymt_cshr_cd)}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {moment(PaymentHistory.mia_pymt_ts, "YYYY-MM-DD").format("MM/DD/YYYY")}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  ${Number(this.stringtoPositiveNumber(PaymentHistory.mia_pymt_at)).toFixed(2)}
                                </div>
                              </div>
                              <div class="row mb-20">
                                <div class="col-12">
                                  <hr class="divider-sm"></hr>
                                </div>
                              </div>
                            </div>
                          ))
                          }
                        </div>
                      }
                    </Tab>

                    <Tab eventKey="billing-history" title="Billing History">
                      <h2>BILLING HISTORY</h2>

                      {!this.state.billing_history_display &&
                        <div>
                          <h3>No billing history available for this account</h3>
                        </div>
                      }

                      {this.state.billing_history_display &&
                        <div class="table-row-dividers w-100">
                          <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              {/* <input type="checkbox" class="users-checkbox" onChange={() => this.selectionAllChanged()}/> */}
                              <b>BILL DATE</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>CURRENT CHARGES</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>AMOUNT DUE</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4"></div>
                          </div>
                          <div class="row mb-20">
                          </div>
                          {Object.entries(this.state.billingHistoryResp.BillingHistory).map(([key, billingInfo]) => (
                            <div key={key}>
                              <div class="row mb-20">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  <a onClick={e => { this.displayPDF(e, billingInfo.bill_date, billingInfo.Bill_mia_info_sub_type) }} href="#">{dateWithSlashes(billingInfo.bill_date)}</a>
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  ${numberWithCommas(Number(billingInfo.totl_bill_at).toFixed(2))}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  ${numberWithCommas(Number(billingInfo.totl_bill_due_at).toFixed(2))}
                                </div>
                              </div>
                              <div class="row mb-20">
                                <div class="col-12">
                                  <hr class="divider-sm"></hr>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </Tab>
                    <Tab eventKey="usage-history" title="Usage History">
                      <h2>USAGE HISTORY</h2>
                      {!this.state.billing_history_display &&
                        <div>
                          <h3>No usage history is currently available for this account.</h3>
                        </div>
                      }
                      {this.state.billing_history_display &&
                        <div class="table-row-dividers w-100">
                          <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>DATE</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>USAGE</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>BILL AMOUNT</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4"></div>
                          </div>
                          <div class="row mb-20">
                          </div>
                          {Object.entries(this.state.billingHistoryResp.BillingHistory.filter(usage => usage.bill_kwh_qy != 0)).map(([key, billingInfo]) => (
                            <div key={key}>
                              <div class="row mb-20">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {dateWithSlashes(billingInfo.read_date)}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {numberWithCommasWithLeadingZeroes(billingInfo.bill_kwh_qy)}kWh
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  ${numberWithCommasWithLeadingZeroes(billingInfo.totl_bill_at)}
                                </div>
                              </div>
                              <div class="row mb-20">
                                <div class="col-12">
                                  <hr class="divider-sm"></hr>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </Tab>
                    <Tab eventKey="pledge-history" title="Pledge History">
                      <h2>PLEDGE HISTORY</h2>
                      {this.state.pledgeList.length == 0 &&
                        <div>
                          <h3>No pledge history is currently available for this account.</h3>
                        </div>
                      }
                      {this.state.pledgeList.length > 0 &&
                        <div class="table-row-dividers w-100">
                          <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>Date Created</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>Amount</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                              <b>Transaction ID</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4"></div>
                          </div>
                          <div class="row mb-20">
                          </div>
                          {this.state.pledgeList.map((pledgeInfo, key) =>
                            <div key={key}>
                              <div class="row mb-20">
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {formatToMMDDYYYY(pledgeInfo.created_date)}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {pledgeInfo.amount !== 'N/A' ? `$${numberWithCommas(pledgeInfo.amount)}`: 'N/A'}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-4">
                                  {pledgeInfo.aep_transaction_id}
                                </div>
                              </div>
                              <div class="row mb-20">
                                <div class="col-12">
                                  <hr class="divider-sm"></hr>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </Tab>
                    {this.state.showAppointmentDisplay && (<Tab eventKey="appointment-history" title="Appointment History">
                      <h2>APPOINTMENT HISTORY</h2>
                      {this.state.appointmentList.length == 0 &&
                        <div>
                          <h3>No Appointment history is currently available for this account.</h3>
                        </div>
                      }
                      {this.state.appointmentList.length > 0 &&
                        <div class="table-row-dividers w-100">
                          <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-3">
                              <b>Appointment Date</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-3">
                              <b>Agency Location ID</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-3">
                              <b>Appointment ID</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-3">
                              <b>Appointment Type</b>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4"></div>
                          </div>
                          <div class="row mb-20">
                          </div>
                          {this.state.appointmentList.map((appointment, key) =>
                            <div key={key}>
                              <div class="row mb-20">
                                <div class="col-sm-4 col-md-4 col-lg-3">
                                  {dateWithSlashes(appointment.created_date)}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3">
                                  {appointment.agency_location_id}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3">
                                  {appointment.appointment_id}
                                </div>
                                <div class="col-sm-4 col-md-4 col-lg-3">
                                  {appointment.appointment_type}
                                </div>
                              </div>
                              <div class="row mb-20">
                                <div class="col-12">
                                  <hr class="divider-sm"></hr>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </Tab>)}
                  </Tabs>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    );
  }
}


export default withRouter(AccountSummary);
