import React, { forwardRef } from "react";
import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

  export function withRouter(Component) {
    const componentWithRouterProp = forwardRef((props, ref) => {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          ref={ref}
          {...props}
          {...{ location, navigate, params }}
        />
      );
    })
  
    return componentWithRouterProp;
  }