import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Information_Show_Logo from '../../lib/images/svgs/ICON_info_link.svg';
import zxcvbn from 'zxcvbn';
import { showSpinner } from './../global/loader';
import { getUserDetails, uniqueID } from './../global/formatter';
import conf from './../../config';
import { withRouter } from '../withRouter';

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};

class ChangePassword extends Component {
  state = {
    fields: {
      password: "", confirm_password: "", oldPassword: "", isDisabledFlag: true, userinfo: [],
      showPasswordModal: false, strength: 0, strengthText: "", passwordLength: 0, errors: {}, touched: {}
    }
  }

  strengthClass = ['strength-meter mt-2'].join(' ').trim();

  showInfo = () => {
    let fields = this.state.fields;
    fields["showPasswordModal"] = true;
    this.setState({ fields });
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser();
    if(this.props.auth.user != null) {
      return new Promise((resolve, reject) => {
        getUserDetails(this.props.auth["user"].username).then(
          (data) => {
            console.log(data)
            this.setState({ userinfo: data }, () => {
              console.log(this.state.userinfo);
              resolve(data)
            });
          },
          (error) => {
            console.log(error);
            showSpinner(false);
            reject(error);
          }
        );
      });
    }   
  }

  closeModal = () => {
    let fields = this.state.fields;
    fields["showPasswordModal"] = false;
    this.setState({ fields });
  }

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    fields["passwordFinal"]= '';
    fields[name] = value;
    fields["touched"][name] = true;
    this.setState({ fields },
      () => { this.validateField(name, value) });
  }

  callEmailForgotPassword = (userinfo) => {
    const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "agencyemailsender");
    let body="";
    console.log(userinfo);
    if(!userinfo.length) {
      body = { "HashLink": uniqueID(),"AccountNo": "na", "Template": "passwordchanged","StreetAddress1": this.props.auth["user"]["attributes"].address,"StreetAddress2": "na",
      "City": "na","State": "na","ZipCode":"na","Preheader": "Your AEP Agency Password has been reset!","Subject": "Your AEP Agency Password has been reset!",
      "SubscriberKey": this.props.auth["user"]["attributes"].email+""+uniqueID(),"EmailAddress": this.props.auth["user"]["attributes"].email, "ChannelMemberID": "10449858","conformationcode": "0000", 
      "AgencyURL": conf.oauth.redirectSignIn+"/forgotpassword","Agency": this.props.auth["user"]["attributes"].profile,"Name": this.props.auth["user"]["attributes"].given_name+" "+this.props.auth["user"]["attributes"].family_name,"Role": this.props.auth["user"]["attributes"].nickname,
      "Phone": this.props.auth["user"]["attributes"].phone_number,"PendingUserEmail": "","PasswordResetExt": "", "Reason": "" };
    }
    else {
      body = { "HashLink": uniqueID(),"AccountNo": "na", "Template": "passwordchanged","StreetAddress1": userinfo["street_address1"],"StreetAddress2": userinfo["street_address2"],
      "City": userinfo["city"],"State": userinfo["state"],"ZipCode": userinfo["zip_code"],"Preheader": "Your AEP Agency Password has been reset!","Subject": "Your AEP Agency Password has been reset!",
      "SubscriberKey": userinfo["user_id"]+""+uniqueID(),"EmailAddress": userinfo["user_id"], "ChannelMemberID": "10449858","conformationcode": "0000", 
      "AgencyURL": conf.oauth.redirectSignIn+"/forgotpassword","Agency": userinfo["name"],"Name": userinfo["first_name"]+" "+userinfo["last_name"],"Role": userinfo["role"],
      "Phone": userinfo["phone"],"PendingUserEmail": "","PasswordResetExt": "", "Reason": "" };
    }
    console.log(body);
    var password_error = false;
    (async () => {
      try {
        const response = await fetch(shadowservicegenericURL, {
          method: 'POST',
          headers: serviceHeader,
          body: JSON.stringify(body)
        });
        const json_resp = await response.json();
        console.log(json_resp);
      }
      catch (error) {
        console.log(error);
        password_error = true;     
      }
      finally {
        this.props.navigate("/myprofile", {state: { 'passwordUpdated': !password_error, 'password_error': password_error }});    
      }
    })();
  }

  handleSubmit = async event => {
    event.preventDefault();
    showSpinner(true);
    let fields = this.state.fields;
    fields["isDisabledFlag"] = true;
    this.setState({ fields });

    new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(
          (data) => {
            console.log(data)
            Auth.changePassword(data, this.state.fields.oldPassword, this.state.fields.password).then(
              (data) => {
                this.callEmailForgotPassword(this.state.userinfo);
                showSpinner(false);
                resolve(data);
              },
              (error) => {
                console.log(error);
                fields["isDisabledFlag"] = false;
                fields["passwordFinal"] = "We're sorry. We are unable to reset your password. Please make sure you have entered your old password correctly or try again after some time.";
                this.setState({ fields });
                showSpinner(false);
                reject(error);
              }
            )
          },
          (error) => {
            console.log(error);
            fields["isDisabledFlag"] = false;
            fields["passwordFinal"] = "We're sorry. We are unable to reset your password. Please make sure you have entered your old password correctly or try again after some time.";
            this.setState({ fields });
            showSpinner(false);
            reject(error);
          }
        );
    });
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  cancel = event => {
    this.props.navigate("/myprofile");
  }

  validateField = (fieldName, value) => {
    let fields = this.state.fields;
    const isEmpty = value.length === 0;
    switch (fieldName) {
      case 'oldPassword':
        if (isEmpty)
          fields["errors"]["oldPassword"] = "Please enter an old password.";
        else
          fields["errors"]["oldPassword"] = "";
        break;
      case 'password':
        const score = zxcvbn(value).score;
        fields["passwordLength"] = value.length;
        fields["strength"] = score;
        fields["strengthText"] = this.mapStrengthToText(score);
        if (!isEmpty && this.state.fields["confirm_password"].length !== 0 && this.state.fields["confirm_password"] !== value)
          fields["errors"]["confirm_password"] = "The passwords entered don't match.";
        else if (this.state.fields["confirm_password"] === value)
          fields["errors"]["confirm_password"] = "";
        if (isEmpty)
          fields["errors"]["password"] = "Please enter a password.";
        else if (value.length < 8)
          fields["errors"]["password"] = "Password must have at least 8 characters.";
        else if (this.letterCount(value) < 8)
          fields["errors"]["password"] = "Passwords must have at least 8 characters; you entered " + this.letterCount(value);
        //". Characters repeated more than 2 consecutive times count as 2 toward the minimum requirement.";
        //commented in case in future, we need to customzie this error message to add more information.
        else if (score === 0 || score === 1)
          fields["errors"]["password"] = "This password is too common. Please use more characters, phrases or numbers.";
        else if (!this.validatePassword(value))
          fields["errors"]["password"] = "This password doesn't meet the requirements. Please click the icon on the right for more information.";
        else
          fields["errors"]["password"] = "";
        break;
      case 'confirm_password':
        if (isEmpty)
          fields["errors"]["confirm_password"] = "Please enter a confirm password.";
        else if (this.state.fields["password"] !== value)
          fields["errors"]["confirm_password"] = "The passwords entered don't match.";
        else
          fields["errors"]["confirm_password"] = "";
        break;
      default:
        break;
    }
    this.setState({ fields }, () => {
      this.setValidFlag();
    });
  }

  mapStrengthToText = (score) => {
    let temp = "";
    switch (score) {
      case 0:
        temp = "Poor";
        break;
      case 1:
        temp = "Weak";
        break;
      case 2:
        temp = "Medium";
        break;
      case 3:
        temp = "Better";
        break;
      case 4:
        temp = "Strong";
        break;
      default:
        break;
    }
    return temp;
  }

  setValidFlag = () => {
    let fields = this.state.fields;
    let flag = true;
    for (var key of Object.keys(fields["errors"])) {
      if (fields["errors"][key] !== '') {
        flag = false;
        break;
      }
    }
    if (!flag)
      fields["isDisabledFlag"] = true;
    else
      fields["isDisabledFlag"] = false;
    this.setState({ fields });
  }

  letterCount = (str) => {//<8 consecutive letter count
    const s = str.toLowerCase().match(/([a-z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{}|~])\1*/g) || [];
    let count = 0;
    s.map(function (itm) {
      count += itm.length > 2 ? 2 : Number(itm.length);
      return itm.length;
    });
    return count;
  }

  validatePassword = (value) => {
    if (value.indexOf(' ') >= 0) { //check for consecutive spaces
      const regexCheckSpaces = /\s\s/;
      if (value.charAt(0) === ' ' || value.slice(-1) === ' ' || regexCheckSpaces.test(String(value)))
        return false;
    }
    else { //alphanumeric and special characters check
      const regexAlphaNumericSpecialChar = /^[a-z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{}|~]+$/i;
      if (!regexAlphaNumericSpecialChar.test(String(value)))
        return false;
    }
    return true;
  }

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1>Change Password</h1>
          <div>
            <div class="form-row password-wrapper mb-30">
              <div class="col-5 col-md-5">
                <div id="cphContentMain_password_cphContentMain_password_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                  <div class="input-group">
                    <input type="password" name="oldPassword" required id='cphContentMain_oldPassword' class="form-control" data-field-type="text" value={this.state.fields["oldPassword"]} placeholder="Old Password" onChange={this.handleChange} />
                    <label htmlFor='cphContentMain_password'>Old Password*</label>
                  </div>
                  {this.state.fields["errors"]["oldPassword"] !== '' && (<span id="cphContentMain_oldPassword_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_oldPassword" >{this.state.fields["errors"]["oldPassword"]}</span>)}
                </div>
              </div>
            </div>

            <div class="form-row password-wrapper mb-25">
              <div class="col-5 col-md-5">
                <div id="cphContentMain_password_cphContentMain_password_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                  <div class="input-group">
                    <input type="password" name="password" required id='cphContentMain_password' class="form-control" data-field-type="text" value={this.state.fields["password"]} placeholder="Password" onChange={this.handleChange} />
                    <label htmlFor='cphContentMain_password'>New Password*</label>
                  </div>
                  {this.state.fields["errors"]["password"] !== '' && (<span id="cphContentMain_password_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_password" >{this.state.fields["errors"]["password"]}</span>)}
                </div>
              </div>
              <img src={Information_Show_Logo} alt="Password Info" class="mb-10" style={{ width: '20px', cursor: 'pointer' }} onClick={this.showInfo} />
            </div>

            {this.state.fields["showPasswordModal"] &&
              (
                <div class="modal-open">
                  <div class="modal fade show" id="centeredModal" tabIndex="-1" role="dialog" aria-modal="true" style={{ display: 'block' }}>
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                            <span aria-hidden="true"></span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <h4>Password Requirements</h4><br />
                          <p>
                            Your password must have at least 8 characters.
                                                                            <br /> <br />
                                                                            Repeated consecutive characters count as two toward the minimum requirement, regardless of the number of actual repetitions. <br /><br />
                                                                            Your password can't begin or end with a space. It can't have two or more consecutive spaces. <br /><br />
                                                                            Your password may only consist of letters, numbers, or the following special characters: <br /> <br />
                                                                            &#33; &#34; &#35; &#36; &#37; &#38; &#39; &#40; &#41; &#42; &#43; &#44; - &#46; &#47; &#58; &#59; &#60; &#61; &#62; &#63; &#64; &#91; &#92; &#93; &#94; &#95; &#96; &#123; &#124;  &#125; ~

                                                                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-backdrop fade show"></div>
                </div>
              )}

            <div class="strength-wrapper col-md-5 col-5 mb-30" style={{ paddingLeft: 0, paddingRight: 10 }}>
              {this.state.fields["passwordLength"] > 0 && (
                <div class="strength-wrapper">
                  <div className={this.strengthClass}>
                    <div className="strength-meter-fill" data-strength={this.state.fields["strength"]}></div>
                  </div>
                  <p className="text-align-right text-bold">{this.state.fields["strengthText"]}</p>
                </div>)}
            </div>

            <div class="form-row mb-20 confirm-password-wrapper">
              <div class="col-5 col-md-5">
                <div id="cphContentMain_confirm_password_cphContentMain_confirm_password_wrapper" class="form-group aep-custom moveable-label">
                  <div class="input-group">
                    <input type="password" name="confirm_password" required id='cphContentMain_confirm_password' class="form-control" data-field-type="text" value={this.state.fields["confirm_password"]} placeholder="Confirm Password" onChange={this.handleChange} />
                    <label htmlFor='cphContentMain_confirm_password'>Confirm New Password*</label>
                  </div>
                  {this.state.fields["errors"]["confirm_password"] !== '' && (<span id="cphContentMain_confirm_password_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_confirm_password" > {this.state.fields["errors"]["confirm_password"]}</span>)}
                </div>
              </div>
            </div>
            <div>
              <button class="btn btn-primary mr-20" onClick={this.handleSubmit} disabled={this.state.fields["isDisabledFlag"] || Object.keys(this.state.fields["touched"]).length !== 3}>
                Save Changes
              </button>
              <div class="row mb-10 mt-10">
                <div class="col button">
                  <button class="btn button-without-border" onClick={this.cancel} style={{
                    fontSize: '17px', padding: '0 0 17px',
                    textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                  }}>Cancel</button>
                </div>
              </div>
              {this.state.fields["passwordFinal"] !== '' && (<span id="cphContentMain_passwordFinal_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_passwordFinal" >{this.state.fields["passwordFinal"]}</span>)}
            </div>
          </div>
        </div>
      </section>
    );
  }
}


export default withRouter(ChangePassword);