import { useState } from "react"
import AccountSummaryContext from "../../contexts/AccountSummaryContext";

const AccountSummaryProvider = ({ children }) => {
    const [originalState, setOriginalState] = useState(null);

    return (
        <AccountSummaryContext.Provider value={{originalState, setOriginalState}}>
            {children}
        </AccountSummaryContext.Provider>
    )
}

export default AccountSummaryProvider;