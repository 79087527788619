import React, { Component } from "react";
import { returnDateTimeWithDashes, dateWithSlashes } from '../global/formatter';
import config from '../../config.json';
import { showSpinner } from '../global/loader';
import { withRouter } from "../withRouter";

const noChangeMsg = <span class='error'>There are no data changes. Please make changes to at least one field.</span>
const defaultMsg = <p class="mb-0 error">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>;
const serviceHeader = {
    'Content-Type': config.agencyDBService.header.contentType,
    'x-api-key': config.agencyDBService.header.key,
    'Connection': config.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
class UpdateAgencyName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agencyName: this.props.location.state.agencyInfo.name,
            agency_id: this.props.location.state.agencyInfo.agency_id,
            agencyStatus: this.props.location.state.agencyInfo.status,
            agencyType: this.props.location.state.agencyInfo.agency_type,
            createdBy: this.props.location.state.agencyInfo.created_by,
            createDate: dateWithSlashes(this.props.location.state.agencyInfo.created_date),
            errorMsg: '',
            disabledFlag: false,
            isError: true
        }
    }

    handleagencyName = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const agencyName = this.state.agencyName;
        var value = event.target.value;
        if (value !== agencyName) {
            this.setState({ agencyName: value });
        }
    }

    handleSubmit = (event) => {
        var agencyName = this.state.agencyName;
        var agency_id = this.state.agency_id;
        var errorMsg = this.state.errorMsg;

        if (agencyName !== "") {
            showSpinner(true);
            this.UpdateAgencydetails();
            this.setState({ isError: false, disabledFlag: true });
        }
        else {
            errorMsg = noChangeMsg;
            this.setState({ errorMsg, isError: true, disabledFlag: false });
            showSpinner(false);
        }
    }

    callCognitoBulk = () => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "cognitops");
        const params = { Operation: "UPDATEATTRIBUTESBULK", ChangeType: "Agency", agency_id: this.state.agency_id };
        params["Userattributes"] = '[{ "Name":"profile",  "Value": "' + this.state.agencyName + '" }]';
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.props.navigate("/ManageAgencies", {state: { 'agencyUpdated': true }});
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
            finally {
                showSpinner(false);
            }
        })();
    }

    UpdateAgencydetails = () => {
        let tableName = ""// "agency"
        let formattedquery = "";
        formattedquery = "UPDATE Agency.agency SET name = '" + String(this.state.agencyName).replaceAll("'","''").trim() + "',  last_update_user = '" + this.state.username + "' , last_update_timestamp = '" + returnDateTimeWithDashes() + "'  where agency_id = '" + this.state.agency_id + "'"
        console.log(formattedquery)
        const params2 = {
            TableName: tableName, CommandType: "CUSTOMES", Query: formattedquery
        }
        Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                });
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.callCognitoBulk();
                    else
                        this.callError();
                }
                else
                    this.callError();
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    callError = () => {
        this.setState({ errorMsg: defaultMsg, isError: true, disabledFlag: false });
        showSpinner(false);
    }

    cancel = (event) => {
        this.props.navigate("/manageagencies");
    }

    componentDidMount = () => {
        let username = this.props.auth.user.signInUserSession.accessToken.payload.username.split('.')[2];
        this.setState({ username });

        let agencyType, agencyStatus;
        switch (this.props.location.state.agencyInfo.agency_type) {
            case 'G':
                agencyType = 'Government';
                this.setState({ agencyType })
                break;
            case 'P':
                agencyType = 'Private';
                this.setState({ agencyType })
                break;
            default:
                agencyType = 'N/A';
                this.setState({ agencyType })
                break;
        }
        switch (this.props.location.state.agencyInfo.status) {
            case 'A':
                agencyStatus = 'Active';
                this.setState({ agencyStatus })
                break;
            case 'I':
                agencyStatus = 'Inactive';
                this.setState({ agencyStatus })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <section>
                <div class='container mt-st'>
                    <h1>UPDATE AGENCY NAME</h1>

                    {this.state.isError &&
                        <div class='mb-4'>{this.state.errorMsg}</div>
                    }
                    <div class="row">
                        <div class="col-md-8">
                            <div class="bordered-form agency-form">
                                <h3 class='mb-4'>You may update the agency name in the field below.</h3>

                                <div class="form-row mb-3">
                                    <div class="col-sm-9">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbagency" value={this.state.agencyName} class="form-control" onChange={this.handleagencyName} />
                                                <label htmlFor='tbagency'>Agency Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h4 class='mb-4'>Additional agency details</h4>
                                <div class="form-row">
                                    <div class="col-sm-6">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbagencystatus" value={this.state.agencyStatus} class="form-control" readOnly />
                                                <label htmlFor="tbagencystatus">Agency Status</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbagencytype" value={this.state.agencyType} class="form-control" readOnly />
                                                <label htmlFor='tbagencytype'>Agency Type</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row mb-4">
                                    <div class="col-sm-6">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbcreatedby" value={this.state.createdBy} class="form-control" readOnly />
                                                <label htmlFor="tbcreatedby">Created By</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbcreateddate" value={this.state.createDate} class="form-control" readOnly />
                                                <label htmlFor="tbcreateddate">Created Date</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='form-row'>
                                    <div class="col-12">
                                        <button type='submit' class="btn btn-primary mb-10" onClick={this.handleSubmit} disabled={this.state.isError || this.state.disabledFlag}>Save changes</button>
                                    </div>
                                    <div class="col-12">
                                        <a href="/ManageAgencies" onClick={this.cancel}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4"></div>
                    </div>


                </div>

            </section>
        )
    }
}


export default withRouter(UpdateAgencyName);