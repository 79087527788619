import React, { Component } from "react";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import "../auth/Login.scss";
import { showSpinner } from './../global/loader';
import config from '../../config.json';
import { returnDateTimeWithDashes } from '../global/formatter';
import * as AgencyRoleConstants from '../constants/AgencyRoleConstants';
import { withRouter } from "../withRouter";

const serviceHeader = {
  'Content-Type': config.agencyDBService.header.contentType,
  'x-api-key': config.agencyDBService.header.key,
  'Connection': config.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      cbRemember: false,
      LoginAttempts: 0,
      group: "",
      errors: {
        cognito: null,
        blankfield: false,
      },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // 03/23/21[cmi] added to handle the change on the inputs
  // sets the value of the key pair {name:value} of the inputs
  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    console.log("initialized");
    showSpinner(false);
    this.setState({
      LoginAttempts: 0,
    });
    if (localStorage.getItem("rememberUser") !== '' && localStorage.getItem("rememberUser") !== null)
      this.setState({ cbRemember: true, email: localStorage.getItem("rememberUser") });
    else
      this.setState({ cbRemember: false, email: '' });
  }

  loginSSO = async event => {
    console.log("CLiekd sso");
    event.preventDefault();
    this.clearErrorState();
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    }, async () => {
      // AWS Cognito integration here
      try {
        const user = await Auth.federatedSignIn({ provider: "aep" });
        if (this.props.auth.AuthStatus) {
          console.log(" Login object");
          console.log(JSON.stringify(user));

          this.setState({
            group: "AEPCorporate",
          });

          this.props.auth.setAuthStatus(true);

          this.props.auth.setUser(user);
          this.props.navigate("/");
          this.props.history.setState(this.state);
        }
      }
      catch (error) {
        console.log(error)
        let err = null;
        !error.message ? err = { "message": error } : err = error;
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err
          }
        })
      }
    });
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      },
    });
  };

  handleUserSignIn = () => {
    this.setState({ setAuthStatus: true, setUser: true });
  };

  signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    } catch (error) {
      console.log('error signing out: ', error);
      await Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
    }
    finally {
      this.props.navigate("/awaitingapproval");
    }
  }


  handleSubmit = async event => {
    event.preventDefault();
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        // LoginAttempts: "1",
        errors: { ...this.state.errors, ...error },
      });
    }
    else {
      showSpinner(true);
      // AWS Cognito integration here
      console.log("AWS Cognito integration");
      if (this.state.cbRemember)
        localStorage.setItem('rememberUser', this.state.email);
      else
        localStorage.setItem('rememberUser', '');
      try {
        const user = await Auth.signIn(String(this.state.email).trim(), this.state.password)
        console.log('user token in checkLogin: ', user.signInUserSession.accessToken.jwtToken); // Retrieves the token.
        let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
        console.log("here is the group: " + userGroup);
        if (userGroup == undefined)
          this.signOut();
        else {
          this.setState({
            group: userGroup
          });
          console.log(JSON.stringify(user))
          this.props.auth.setAuthStatus(true);
          this.props.auth.setUser(user);
          this.callUpdateLastLoginTime(user.username);
        }
      }
      catch (error) {
        console.log(error);
        let err = null;
        console.log("number: " + parseInt(this.state.LoginAttempts));
        let attempts = parseInt(this.state.LoginAttempts) + 1;
        this.setState({
          LoginAttempts: attempts,
        });
        !error.message ? err = { "message": error } : err = error;
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: err
          }
        })
      }
      showSpinner(false);
    }
  };

  callUpdateLastLoginTime = (username) => {
    let tableName = ""// "GAA_USER"
    let formattedquery = "";
    formattedquery = "UPDATE agency.GAA_USER SET last_login = '" + returnDateTimeWithDashes() + "' where user_id = '" + username + "'"
    console.log(formattedquery)
    const params2 = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log("this is response from api call: " + JSON.stringify(json_resp));
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
            console.log("Last Login Updated Successfully");
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }


  render() {
    return (
      <div id="loginFormContainer">
        <div id="bannerContainer" class="banner-container login-banner-container">
          <div id="bannerImage" class="login-bg-image crop-center">
            <div class="container px-0 px-sm-30 px-lg-60">
              <div class="row d-md-flex align-items-center" id="bannerContent">
                <div class="col-sm-6 col-md-7 banner-contents mt-md-100 mb-md-n100">
                  <h1 class="banner-title mb-3">
                    <span class="dark-red d-none d-md-block">
                      Agency Customer Assistance</span>
                  </h1>
                  <h4 class="banner-description pr-md-100">
                    <span class="d-none d-md-block dark-gray">
                      Allowing you to help customers who are having trouble
                      paying their American Electric Power bills.</span>
                  </h4>
                </div>
                <div class="d-block d-sm-none col-sm-6"></div>
                <div class="col-sm-6 col-md-5 mt-n30 mb-30 mb-sm-0 mt-sm-0">
                  <section class="section auth">
                    <div class="form-red">
                      <FormErrors formerrors={this.state.errors} />
                      <form onSubmit={this.handleSubmit}>
                        <h3 class="mb-20 mb-md-30">Log in to assist customers</h3>
                        <div class="form-row mb-20">
                          <div class="col-12">
                            <div class="form-group aep-custom" style={{ marginBottom: 0 }}>
                              <div class="input-group">
                                <input
                                  type="text"
                                  id="tbEmail"
                                  name="email"
                                  class="form-control aep-custom"
                                  value={this.state.email}
                                  onChange={this.handleChange}
                                  placeholder="Email"
                                  required
                                />
                                <label htmlFor="tbEmail">Email</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 d-none">
                            <div></div>
                          </div>
                        </div>

                        <div class="form-row mb-10 mb-md-20">
                          <div class="col-12">
                            <div class="form-group aep-custom" style={{ marginBottom: 0 }}>
                              <div class="input-group">
                                <input
                                  type="password"
                                  id="tbPassword"
                                  name="password"
                                  class="form-control"
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  placeholder="Password"
                                  required
                                />
                                <label htmlFor="tbPassword">Password</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="col-12 d-flex align-items-center">
                            <div class="selector-wrap">
                              <div class="checkbox">
                                <input name="cbRemember" type="checkbox" id="cbRemember" class="has-value" onChange={this.handleChange} value={this.state.cbRemember} checked={this.state.cbRemember} />
                                <label htmlFor="cbRemember">Remember me</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row d-flex">
                          <div class="col-8">
                            <div class="field">
                              <p class="control">
                                <a href="/forgotpassword">Forgot password?</a>
                              </p>
                              <p class="control">
                                <a href="/register">Register</a>
                              </p>
                            </div>
                          </div>
                          <div class="col-4 text-right">
                            <button class="btn btn-secondary-white mr-0">
                              GO
                            </button>
                          </div>
                        </div>

                        <div class="white-border my-10 my-md-20"></div>
                        <div class="form-row">
                          <div class="col-sm-11">
                            <div class="field">
                              <div class="control">
                                <p class="mb-0 d-flex align-items-center"><span class="text-bolder">AEP Admins</span>&nbsp;must&nbsp;
                                  <button type='button' onClick={this.loginSSO} class="btn btn-sso button-anchor text-bolder">log in with their SSO</button></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container d-block d-md-none mt-sm-30 mt-md-0">
          <div class="row">
            <div class="col-sm-11 mb-st">
              <h1 class="banner-title dark-red mb-3">
                Agency Customer Assistance
              </h1>
              <h4 class="banner-description dark-gray mb-3">
                Allowing you to help customers who are having trouble paying
                their American Electric Power bills.
              </h4>
            </div>

          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(LogIn);