import React, { Component } from "react";
import '../aeputilities-styles/styles/scss/site.scss'
import { Auth } from 'aws-amplify';
import * as AgencyRoleConstants from './constants/AgencyRoleConstants';
import config from '../config.json';

class Footer extends Component {
  constructor(props) {
    super(props);
    //this.state = { Member_SiteDomain: 'AEP'};
    this.state = { hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true };
  }

  callRole = () => {
    if (this.props.auth["isAuthenticated"]) {
      let role = "";
      if (this.props.auth.user != null)
        role = this.props.auth.user["attributes"].nickname;
      this.setState({
        hideManageUsers: (role === AgencyRoleConstants.AGENCY_USER_ROLE), hideReports: false, hideAssistance: false, hideProfile: false
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.auth["isAuthenticated"] !== this.props.auth["isAuthenticated"]) {
      const user = await Auth.currentAuthenticatedUser();
      this.callRole();
    }
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser();
    let userGroup = "";
    if (this.props.auth.user !== null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      const groupName = config.cognito.USER_POOL_ID + '_aep';
      if (String(userGroup) === AgencyRoleConstants.AGENCY_ADMIN_ROLE) {
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: false, hideManageUsers: false
        });
      }
      else if (String(userGroup) === AgencyRoleConstants.AGENCY_USER_ROLE) {
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: false, hideManageUsers: true
        });
      }
      else if (String(userGroup) === groupName) {
        this.setState({
          hideProfile: true, hideAssistance: false, hideReports: false, hideManageUsers: false
        });
      }
      else {
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: false, hideManageUsers: true
        });
      }
    }
  }

  render() {
    const Member_SteDomain = 'AEP'
    // this.setState({Member_SiteDomain: Member_SteDomain})
    return (
      <footer class="footer-nav">
        <div class="background-red">
          <div class="container">
            <ul class="footer">
              <li><a href="#"></a></li>
            </ul>
          </div>
        </div>
        <div class="sub-footer">
          <div class="container">

            <div class="row">
              <div class="col footer-links">
                <a class="d-none d-md-inline-block" href="https://www.aep.com">Visit aep.com</a>
              </div>
              {this.props.auth.user != null &&
                <div class="col footer-links">
                  <a href="/accountsummary" title="Customer Assistance" hidden={this.state.hideAssistance}>Customer Assistance</a>
                  <span class="footer-pipe" hidden={this.state.hideReports}>|</span>
                  <a href="/reports" title="Reports" hidden={this.state.hideReports}>Reports</a>
                  <span class="footer-pipe" hidden={this.state.hideManageUsers}>|</span>
                  <a href="/reviewregistrationrequests" title="Agencies & Users" hidden={this.state.hideManageUsers}>Agencies & Users</a>
                  <span class="footer-pipe" hidden={this.state.hideProfile}>|</span>
                  <a href="/myprofile" title="My Profile" hidden={this.state.hideProfile}>My Profile</a>
                </div>
              }
            </div>

            <div class="row">
              <div class="col-lg-9">
                <div class="disclaimer">
                  <span>Use of this site constitutes acceptance of the <a href="https://www.aep.com/terms" title="AEP Terms and Conditions">AEP Terms and Conditions</a> |  <a href="https://www.aep.com/privacy" title="View our Privacy Policy">View our Privacy Policy</a>.</span>
                  <span>© 1996-2020 American Electric Power. All Rights Reserved.</span>
                </div>
              </div>
              <div id="ctl05_footerSocialContainer" class="col-lg-3 d-flex justify-content-center justify-content-lg-end social-media">
                <a id="ctl05_footerInstagram" class="instagram" href="https://www.instagram.com/appalachianpowerco/" target="_blank"></a>
                <a id="ctl05_footerTwitter" class="twitter" href="https://twitter.com/intent/user?screen_name=AppalachianPowe" target="_blank"></a>
                <a id="ctl05_footerFacebook" class="facebook" href="https://www.facebook.com/AppalachianPower" target="_blank"></a>
                <a id="ctl05_footerYoutube" class="youtube" href="https://www.youtube.com/user/APCOtv" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>

      </footer>
    );
  }
}

export default Footer;