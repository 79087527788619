import React, { Component } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { withStyles } from '@mui/styles';
import { PatternFormat } from 'react-number-format';
import Find_Agency_Logo from '../../../lib/images/svgs/ICON_find_agency.svg';
import Confirm_Agency_Logo from '../../../lib/images/svgs/ICON_confirm_agency.svg';
import Review_Info_Logo from '../../../lib/images/svgs/ICON_review_info.svg';
import Verify_Email_Logo from '../../../lib/images/svgs/ICON_verify_email.svg';
import Account_Access_Logo from '../../../lib/images/svgs/ICON_account_access.svg';
import Information_Show_Logo from '../../../lib/images/svgs/ICON_info_link.svg';
// import Search_Agency_State_Logo from '../../../lib/images/svgs/ICON_agency_search_state.svg';
// import Search_Agency_Name_Logo from '../../../lib/images/svgs/ICON_agency_search_name.svg';
import zxcvbn from 'zxcvbn';
import { validateEmail, setAgencyRole } from '../../global/formatter';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { addHypensPhonenumber } from '../../global/formatter';
import FormErrors from "../../FormErrors";
import { Auth } from "aws-amplify";
import config from './../../../config';
import { showSpinner } from './../../global/loader';
// import { Tab, Tabs } from 'react-bootstrap';
import { ReactComponent as IconAutoComplete } from "../../../lib/images/svgs/ICON_address_auto_complete.svg";
import { Box } from '@mui/material';

const sampleHeaders = {
    'Content-Type': 'application/json',
    'x-api-key': config.accountSearchShadowService.token
};


class Register extends Component {
    state = {
        activeStep: 0,
        prevStep: -1,
        steps: this.getSteps(),
        nextButtonText: 'Continue',
        fields: {
            first_name: "", last_name: "", phone_num: "", agency_role: "", email: "", password: "", confirm_password: "",
            showPasswordModal: false, strength: 0, strengthText: "", passwordLength: 0, errors: {}, touched: {}, isDisabledRegisterInfoFlag: true
        },
        search: {
            agency_name: "", errors: {},
            // options: [
            // { values: 'Agency Name - Location 2' + '1234 Sesame Street Suite 3A', name: 'Spy Name - Location 2', address: '1234 Sesame Street Suite 3A' },
            // { values: 'Agency Name - Location 3' + '1234 Teek Street Suite 4A', name: 'Detective Name - Location 3', address: '1234 Teek Street Suite 4A' }],
            options: [],
            //options: [{name: '', address: '', values: ''}],
            isDisabledSearchFlag: true, touched: {}, searchTextfield: '', storeAgencyInfoToSendService: {}
        },
        review: {
            errorsCommon: { cognito: null },
            isDisabledReviewRegisterFlag: false
        }
    };
    strengthClass = ['strength-meter mt-2'].join(' ').trim();
    ColorlibConnector = withStyles({
        active: {
            '& $line': {
                backgroundImage:
                    'linear-gradient(95deg,#76aa00, 0%, #76aa00 50%, #93d400 100%)',
            },
        },
        completed: {
            '& $line': {
                backgroundImage:
                    'linear-gradient(95deg,#76aa00, 0%, #76aa00 50%, #93d400 100%)',
            },
        },
        line: {
            height: 3,
            marginTop: 5,
            borderRadius: 1
        },
    })(StepConnector);

    getSteps() {
        return ['Register Info', 'Find Agency', 'Confirm Agency', 'Review Info', 'Verify Email & Approval'];
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleNext = () => {
        const activeStep = this.state.activeStep;
        let flag = false;
        let submitFlag = false;
        switch (activeStep) {
            case 0:
                let fields = this.state.fields;
                let flagError = true;
                for (var key of Object.keys(fields["errors"])) {
                    if (fields["errors"][key] !== '') {
                        flagError = false;
                        break;
                    }
                }
                if (flagError && Object.keys(this.state.fields["touched"]).length === 7)
                    flag = true;
                break;
            case 1:
                if (this.state.search["errors"]["searchTextfield"] === '' && Object.keys(this.state.search["touched"]).length === 1)
                    flag = true;
                break;
            case 2:
            case 4:
                flag = true;
                break;
            case 3:
                submitFlag = true;
                flag = true;
                break;
            default:
                break;
        }
        console.log(this.state.activeStep + "::" + this.state.prevStep);
        if (this.state.activeStep < this.state.prevStep) {
            if (flag && !submitFlag) {
                this.setState({ activeStep: this.state.prevStep }, () => {
                    this.evaluateButtonText();
                });
            }
            else if (submitFlag) {
                showSpinner(true);
                console.log("submit updated");
                this.setSubmitButtonStatus(true);
                this.callSubmitCognito();
            }
        }
        else {
            if (flag && !submitFlag)
                this.setState({ activeStep: this.state.activeStep + 1, prevStep: this.state.prevStep + 1 }, () => {
                    this.evaluateButtonText();
                });
            else if (submitFlag) {
                showSpinner(true);
                console.log("submit regular");
                this.setSubmitButtonStatus(true);
                this.callSubmitCognito();
            }
        }

    };

    setSubmitButtonStatus = (flag) => {
        let review = this.state.review;
        review["isDisabledReviewRegisterFlag"] = flag;
        this.setState({ review });
    }

    callSubmitCognito = async () => {
        try {
             //email: String(this.state.fields["email"]).toLowerCase().trim(),
            await Auth.signUp({
                username: String(this.state.fields["email"]).trim(),
                password: this.state.fields["password"],
                attributes: {
                    email: String(this.state.fields["email"]).trim(),                
                    phone_number: '+1' + String(this.state.fields["phone_num"]).trim(),
                    address: this.state.search["storeAgencyInfoToSendService"].address,
                    profile: this.state.search["storeAgencyInfoToSendService"].name,
                    zoneinfo: this.state.search["storeAgencyInfoToSendService"].agency_id,
                    locale: this.state.search["storeAgencyInfoToSendService"].agency_location_id,
                    nickname: setAgencyRole(this.state.fields["agency_role"]),
                    given_name: String(this.state.fields["first_name"]).trim(),
                    family_name: String(this.state.fields["last_name"]).trim(),
                    name: this.state.search["storeAgencyInfoToSendService"].location,
                    picture: this.state.search["storeAgencyInfoToSendService"].city,
                    website: this.state.search["storeAgencyInfoToSendService"].state
                }
            });
            this.setState({ activeStep: this.state.activeStep + 1, prevStep: this.state.prevStep + 1 }, () => {
                this.evaluateButtonText();
                showSpinner(false);
            });
        }
        catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;
            let review = this.state.review;
            review["errorsCommon"].cognito = err;
            review["isDisabledReviewRegisterFlag"] = false;
            this.setState({ review });
            showSpinner(false);
        }
    }

    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1, prevStep: this.state.prevStep - 1 });
    };

    mapStrengthToText = (score) => {
        let temp = "";
        switch (score) {
            case 0:
                temp = "Poor";
                break;
            case 1:
                temp = "Weak";
                break;
            case 2:
                temp = "Medium";
                break;
            case 3:
                temp = "Better";
                break;
            case 4:
                temp = "Strong";
                break;
            default:
                break;
        }
        return temp;
    }

    handleChange = event => {
        const { name, value } = event.target;
        let fields = this.state.fields;
        fields[name] = value;
        fields["touched"][name] = true;
        //console.log(value+"checked");
        this.setState({ fields },
            () => { this.validateField(name, value) });
    }

    validatePassword = (value) => {
        if (value.indexOf(' ') >= 0) { //check for consecutive spaces
            const regexCheckSpaces = /\s\s/;
            if (value.charAt(0) === ' ' || value.slice(-1) === ' ' || regexCheckSpaces.test(String(value)))
                return false;
        }
        else { //alphanumeric and special characters check
            const regexAlphaNumericSpecialChar = /^[a-z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{}|~]+$/i;
            if (!regexAlphaNumericSpecialChar.test(String(value)))
                return false;
        }
        return true;
    }

    letterCount = (str) => {//<8 consecutive letter count
        const s = str.toLowerCase().match(/([a-z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{}|~])\1*/g) || [];
        let count = 0;
        s.map(function (itm) {
            count += itm.length > 2 ? 2 : Number(itm.length);
            return itm.length;
        });
        return count;
    }

    setValidFlag = () => {
        let fields = this.state.fields;
        let flag = true;
        for (var key of Object.keys(fields["errors"])) {
            if (fields["errors"][key] !== '') {
                flag = false;
                break;
            }
        }
        if (!flag)
            fields["isDisabledRegisterInfoFlag"] = true;
        else
            fields["isDisabledRegisterInfoFlag"] = false;
        this.setState({ fields });
    }

    validateField = (fieldName, value) => {
        let fields = this.state.fields;
        const isEmpty = value.length === 0;
        switch (fieldName) {
            case 'email':
                if (isEmpty)
                    fields["errors"]["email"] = "Please enter an email address.";
                else if (!validateEmail(value))
                    fields["errors"]["email"] = "Please enter a valid email address.";
                else
                    fields["errors"]["email"] = "";
                break;
            case 'password':
                const score = zxcvbn(value).score;
                fields["passwordLength"] = value.length;
                fields["strength"] = score;
                fields["strengthText"] = this.mapStrengthToText(score);
                if (!isEmpty && this.state.fields["confirm_password"].length !== 0 && this.state.fields["confirm_password"] !== value)
                    fields["errors"]["confirm_password"] = "The passwords entered don't match.";
                else if (this.state.fields["confirm_password"] === value)
                    fields["errors"]["confirm_password"] = "";

                if (isEmpty)
                    fields["errors"]["password"] = "Please enter a password.";
                else if (value.length < 8)
                    fields["errors"]["password"] = "Password must have at least 8 characters.";
                else if (this.letterCount(value) < 8)
                    fields["errors"]["password"] = "Passwords must have at least 8 characters; you entered " + this.letterCount(value);
                //". Characters repeated more than 2 consecutive times count as 2 toward the minimum requirement.";
                //commented in case in future, we need to customzie this error message to add more information.
                else if (score === 0 || score === 1)
                    fields["errors"]["password"] = "This password is too common. Please use more characters, phrases or numbers.";
                else if (!this.validatePassword(value))
                    fields["errors"]["password"] = "This password doesn't meet the requirements. Please click the icon on the right for more information.";
                else
                    fields["errors"]["password"] = "";
                break;
            case 'first_name':
                if (isEmpty)
                    fields["errors"]["first_name"] = "Please enter a first name.";
                else
                    fields["errors"]["first_name"] = "";
                break;
            case 'last_name':
                if (isEmpty)
                    fields["errors"]["last_name"] = "Please enter a last name.";
                else
                    fields["errors"]["last_name"] = "";
                break;
            case 'phone_num':
                if (isEmpty)
                    fields["errors"]["phone_num"] = "Please enter a phone number.";
                else if (value.length < 10)
                    fields["errors"]["phone_num"] = "Please enter 10 digits";
                else
                    fields["errors"]["phone_num"] = "";
                break;
            case 'confirm_password':
                if (isEmpty)
                    fields["errors"]["confirm_password"] = "Please enter a confirm password.";
                else if (this.state.fields["password"] !== value)
                    fields["errors"]["confirm_password"] = "The passwords entered don't match.";
                else
                    fields["errors"]["confirm_password"] = "";
                break;
            case 'agency_role':
                if (isEmpty)
                    fields["errors"]["agency_role"] = "Please select an agency role.";
                else
                    fields["errors"]["agency_role"] = "";
                break;
            default:
                break;
        }
        this.setState({ fields }, () => {
            //console.log(this.state.fields["agency_role"]+"checked");
            this.setValidFlag();
        });
    }

    handleChangePhoneNum = (values) => {
        const { value } = values;
        let fields = this.state.fields;
        fields["phone_num"] = value;
        fields["touched"]["phone_num"] = true;
        this.setState({ fields },
            () => { this.validateField("phone_num", value) });
    }

    showInfo = () => {
        let fields = this.state.fields;
        fields["showPasswordModal"] = true;
        this.setState({ fields });
    }

    closeModal = () => {
        let fields = this.state.fields;
        fields["showPasswordModal"] = false;
        this.setState({ fields });
    }

    handleSearchTextFieldAgencyName = async event => {
        let search = this.state.search;
        const { value } = event.target;
        search["searchTextfield"] = value;
        console.log("inisde" + value + '::' + value.length);
        if (value.length >= 3) {
            showSpinner(true);
            try {
                const result = await this.callElasticSearch(value);
                console.log(result + "result");
                if (result !== null)
                    search["options"] = result;
                else
                    search["options"] = [];
                this.setState({ search },
                    function () {
                    console.log(this.state.search["searchTextfield"] + "search value in textfield is :::::::::::");
                    showSpinner(false);
                    });
                }
            catch(err) {
                console.log(err);
                search["options"] = [];
                this.setState({ search },
                    function () {
                    console.log(this.state.search["searchTextfield"] + "search value in textfield is :::::::::::");
                    showSpinner(false);
                });
            }        
        }
        else {
            search["options"] = [];
            this.setState({ search },
                function () {
                    console.log(this.state.search["searchTextfield"] + "search value in textfield is :::::::::::");
                    showSpinner(false);
                });
        }
    }

    handleSearchName = (newValue) => {
        let search = this.state.search;
        if (newValue !== null && newValue !== undefined) {
            search["searchTextfield"] = newValue.name + " - " + newValue.location + " @ " + newValue.address;
            search["storeAgencyInfoToSendService"] = newValue;
            //console.log("inisde handle search name"+ JSON.stringify(newValue));
            search["touched"]["searchTextfield"] = true;
        }
        else {
            search["searchTextfield"] = '';
            search["options"] = [];
            search["storeAgencyInfoToSendService"] = newValue;
        }
        this.setState({ search },
            () => { this.validateSearch("searchTextfield", newValue); });
    }

    callElasticSearch = async (address) => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "searchAddresses");
        const params = { "address": address }
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        return new Promise((resolve, reject) => {
            fetch(shadowservicegenericURL, {
                method: 'POST',
                headers: sampleHeaders
            })
                .then(res => res.json())
                .then(
                    (data) => {
                        console.log(data);
                        let addresses = [];
                        if (data['hits']) {
                            var autocomplete_array = data['hits']['hits']
                            autocomplete_array.forEach(function (option) {
                                let obj = {};
                                obj["address"] = option['_source']['address'];
                                obj["agency_location_id"] = option['_source']['agency_location_id'];
                                obj["agency_id"] = option['_source']['agency_id'];
                                obj["name"] = option['_source']['name'];
                                obj["location"] = option['_source']['location'];
                                obj["street_address1"] = option['_source']['street_address1'];
                                obj["street_address2"] = option['_source']['street_address2'];
                                obj["city"] = option['_source']['city'];
                                obj["state"] = option['_source']['state'];
                                obj["zip_code"] = option['_source']['zip_code'];
                                obj["status"] = option['_source']['status'];
                                obj["user_access_review_date"] = option['_source']['user_access_review_date'];
                                obj["created_date"] = option['_source']['created_date'];
                                obj["last_update_user"] = option['_source']['last_update_user'];
                                obj["last_update_timestamp"] = option['_source']['last_update_timestamp'];
                                obj["phone"] = option['_source']['phone'];
                                addresses.push(obj)
                            });
                        }
                        resolve(addresses);
                    },
                    (error) => {
                        console.log(error)
                        reject([]);
                    }
                );
        });
    }

    validateSearch = (fieldName, value) => {
        let search = this.state.search;
        const isEmpty = value === null;
        switch (fieldName) {
            case 'searchTextfield':
                if (isEmpty)
                    search["errors"]["searchTextfield"] = "Please enter an agency name.";
                else
                    search["errors"]["searchTextfield"] = "";
                break;
            default:
                break;
        }
        this.setState({ search }, () => {
            this.setValidFlagSearch();
        });
        console.log(JSON.stringify(this.state.search["storeAgencyInfoToSendService"]) + "storeAgencyInfoToSendService")
    }

    setValidFlagSearch = () => {
        let search = this.state.search;
        let flag = true;
        for (var key of Object.keys(search["errors"])) {
            if (search["errors"][key] !== '') {
                flag = false;
                break;
            }
        }
        if (!flag)
            search["isDisabledSearchFlag"] = true;
        else
            search["isDisabledSearchFlag"] = false;
        this.setState({ search });
    }

    isNumberKey = event => {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode !== 46 && charCode !== 45 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;
        return true;
    };

    editDetails = event => {
        console.log(JSON.stringify(this.state.fields));
        let review = this.state.review;
        review["errorsCommon"].cognito = null;
        this.setState({ activeStep: 0, prevStep: this.state.activeStep, review }, () => {
            this.evaluateButtonText();
        });
    }

    editSearch = event => {
        let review = this.state.review;
        review["errorsCommon"].cognito = null;
        this.setState({ activeStep: 1, prevStep: this.state.activeStep, review }, () => {
            this.evaluateButtonText();
        });
    }

    evaluateButtonText = () => {
        let text = "";
        console.log(this.state.activeStep + "::" + this.state.prevStep);
        if (this.state.activeStep === 3)
            text = 'Submit Registration';
        else if (this.state.prevStep > this.state.activeStep)
            text = 'Return to Review';
        else if (this.state.activeStep === 2)
            text = 'Yes, continue';
        else
            text = 'Continue';
        this.setState({ nextButtonText: text });
    }

    getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div class="step-wrapper row">
                        <div role="form" class="bordered-form col-sm-9">
                            <form noValidate={true} onSubmit={e => { e.preventDefault(); }}>
                                <div class="col-12">
                                    <h4 class="mb-40">First, let us know who you are.</h4>
                                    <div class="wrapper-block">
                                        <div class="form-row mb-20">
                                            <div class="col-12 col-sm-6">
                                                <div id="cphContentMain_first_name_cphContentMain_first_name_wrapper" class="form-group aep-custom moveable-label">
                                                    <div class="input-group">
                                                        <input name="first_name" required type="text" id='cphContentMain_first_name' class="form-control" data-field-type="text" value={this.state.fields["first_name"]} onChange={this.handleChange} placeholder="First Name" />
                                                        <label htmlFor='cphContentMain_first_name'>First Name*</label>
                                                    </div>
                                                    {this.state.fields["errors"]["first_name"] !== '' && (<span id="cphContentMain_first_name_ctl02" className="error invalid-feedback validator font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_first_name" >{this.state.fields["errors"]["first_name"]}</span>)}
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-6">
                                                <div id="cphContentMain_last_name_cphContentMain_last_name_wrapper" class="form-group aep-custom moveable-label">
                                                    <div class="input-group">
                                                        <input name="last_name" type="text" required id='cphContentMain_last_name' class="form-control" data-field-type="text" value={this.state.fields["last_name"]} onChange={this.handleChange} placeholder="Last Name" />
                                                        <label htmlFor='cphContentMain_last_name'>Last Name*</label>
                                                    </div>
                                                    {this.state.fields["errors"]["last_name"] !== '' && (<span id="cphContentMain_last_name_ctl02" className="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_last_name" >{this.state.fields["errors"]["last_name"]}</span>)}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row mb-35">
                                            <div class="col-12 col-md-8">
                                                <div id="cphContentMain_email_cphContentMain_email_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                                                    <div class="input-group">
                                                        <input name="email" type="email" required id='cphContentMain_email' class="form-control" data-field-type="text" value={this.state.fields["email"]} onChange={this.handleChange} placeholder="Email Address" />
                                                        <label htmlFor='cphContentMain_email'>Email Address*</label>
                                                    </div>
                                                    {this.state.fields["errors"]["email"] !== '' && (<span id="cphContentMain_email_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_email" >{this.state.fields["errors"]["email"]}</span>)}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row mb-20">
                                            <div class="col-12 col-md-5">
                                                <div id="cphContentMain_phone_num_cphContentMain_phone_num_wrapper" class="form-group aep-custom moveable-label">
                                                    <div class="input-group">
                                                        <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" required id='cphContentMain_phone_num' class="form-control" data-field-type="number" value={this.state.fields["phone_num"]} placeholder="Phone Number" onValueChange={(values) => this.handleChangePhoneNum(values)} />
                                                        <label htmlFor='cphContentMain_phone_num'>Phone Number*</label>
                                                    </div>
                                                    {this.state.fields["errors"]["phone_num"] !== '' && (<span id="cphContentMain_phone_num_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_phone_num" > {this.state.fields["errors"]["phone_num"]}</span>)}
                                                </div>
                                            </div>
                                        </div>

                                        <div clas="role-group-wrapper">
                                            <h4 class="mb-20">Which role are you registering for? *</h4>
                                            <div role="group" aria-labelledby="directionRadio">
                                                <div class="selector-wrap">
                                                    <div id="rbl_1" data-field-type="radio-button-list" value={this.state.fields["agency_role"]} data-required="true" data-required-message="Please choose a value" class="radio" required >
                                                        <input id="rbl_1_1" type="radio" name="agency_role" value="Agency User" onClick={this.handleChange} defaultChecked={this.state.fields["agency_role"] === 'Agency User'} />
                                                        <label htmlFor="rbl_1_1">Agency User (Only need to provide assistance to customers.) </label>
                                                        <br />
                                                        <input id="rbl_1_0" type="radio" name="agency_role" value="Agency Admin" onClick={this.handleChange} defaultChecked={this.state.fields["agency_role"] === 'Agency Admin'} />
                                                        <label htmlFor="rbl_1_0">Agency Admin (Manage Agency Users and provide assistance.)</label>
                                                        <br />
                                                    </div>
                                                    {this.state.fields["errors"]["agency_role"] && (<span id="cphContentMain_rbl_1_ctl01" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="rbl_1" >{this.state.fields["errors"]["agency_role"]}</span>)}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="password-group-wrapper">
                                            <h4 class="mb-20">Create a password.</h4>
                                            <div class="form-row mb-20 user-id-wrapper">
                                                <div class="col-12 col-md-6">
                                                    <div id="cphContentMain_user_id_cphContentMain_user_id_wrapper" class="form-group aep-custom moveable-label">
                                                        <div class="input-group">
                                                            <input type="text" name="user_id" id='cphContentMain_user_id' class="form-control" data-field-type="text" value={this.state.fields["email"]} placeholder="User ID" readOnly />
                                                            <label htmlFor='cphContentMain_user_id'>User ID</label>
                                                        </div>
                                                        <p style={{ fontSize: '1rem' }}>User ID will be the email address entered above.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row password-wrapper">
                                                <div class="col-12 col-md-6">
                                                    <div id="cphContentMain_password_cphContentMain_password_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                                                        <div class="input-group">
                                                            <input type="password" name="password" required id='cphContentMain_password' class="form-control" data-field-type="text" value={this.state.fields["password"]} placeholder="Password" onChange={this.handleChange} />
                                                            <label htmlFor='cphContentMain_password'>Password*</label>
                                                        </div>
                                                        {this.state.fields["errors"]["password"] && (<span id="cphContentMain_password_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_password" >{this.state.fields["errors"]["password"]}</span>)}
                                                    </div>
                                                </div>

                                                <div class="passwd-info-block mt-12 ml-15">
                                                    <img src={Information_Show_Logo} alt="Password Info" style={{ width: '20px', cursor: 'pointer' }} onClick={this.showInfo} />
                                                    {this.state.fields["showPasswordModal"] &&
                                                        (
                                                            <div class="modal-open">
                                                                <div class="modal fade show" id="centeredModal" tabIndex="-1" role="dialog" aria-modal="true" style={{ display: 'block' }}>
                                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                                        <div class="modal-content">
                                                                            <div class="modal-header">
                                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                                                                    <span aria-hidden="true"></span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="modal-body">
                                                                                <h4>Password Requirements</h4><br />
                                                                                <p>
                                                                                    Your password must have at least 8 characters.
                                                                            <br /> <br />
                                                                            Repeated consecutive characters count as two toward the minimum requirement, regardless of the number of actual repetitions. <br /><br />
                                                                            Your password can't begin or end with a space. It can't have two or more consecutive spaces. <br /><br />
                                                                            Your password may only consist of letters, numbers, or the following special characters: <br /> <br />
                                                                            &#33; &#34; &#35; &#36; &#37; &#38; &#39; &#40; &#41; &#42; &#43; &#44; - &#46; &#47; &#58; &#59; &#60; &#61; &#62; &#63; &#64; &#91; &#92; &#93; &#94; &#95; &#96; &#123; &#124;  &#125; ~

                                                                        </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-backdrop fade show"></div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>

                                            <div class="strength-wrapper col-md-6 col-12 mb-30" style={{ paddingLeft: 0, paddingRight: 10 }}>
                                                {this.state.fields["passwordLength"] > 0 && (
                                                    <div class="strength-wrapper">
                                                        <div className={this.strengthClass}>
                                                            <div className="strength-meter-fill" data-strength={this.state.fields["strength"]}></div>
                                                        </div>
                                                        <p className="text-align-right text-bold">{this.state.fields["strengthText"]}</p>
                                                    </div>)}
                                            </div>

                                            <div class="form-row mb-20 confirm-password-wrapper">
                                                <div class="col-12 col-md-6">
                                                    <div id="cphContentMain_confirm_password_cphContentMain_confirm_password_wrapper" class="form-group aep-custom moveable-label">
                                                        <div class="input-group">
                                                            <input type="password" name="confirm_password" required id='cphContentMain_confirm_password' class="form-control" data-field-type="text" value={this.state.fields["confirm_password"]} placeholder="Confirm Password" onChange={this.handleChange} />
                                                            <label htmlFor='cphContentMain_confirm_password'>Confirm Password*</label>
                                                        </div>
                                                        {this.state.fields["errors"]["confirm_password"] && (<span id="cphContentMain_confirm_password_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_confirm_password" > {this.state.fields["errors"]["confirm_password"]}</span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-block col-12">
                                    {this.state.activeStep === this.state.steps.length ? null : (
                                        <div class="row">
                                            <div class="col">
                                                {this.state.activeStep === 0 ? null : (<button class="btn btn-secondary mr-20" onClick={this.handleBack} >Previous</button>)}
                                                <button class="btn btn-primary" onClick={this.handleNext} disabled={this.state.fields["isDisabledRegisterInfoFlag"] || Object.keys(this.state.fields["touched"]).length !== 7}>
                                                    {this.state.nextButtonText}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>

                        <div role="form" class="bordered-form col-sm-3 register-right-section">
                            <div class="center-align-flex">
                                <div class="text-align-center">
                                    <div class="image-poster mb-10">
                                        <img src={Find_Agency_Logo} alt="Find Agency" style={{ width: '48px' }} />
                                    </div>
                                    <div class="image-side-block">
                                        <span class="text-uppercase text-bolder">Next Step:</span>
                                        <p>Find Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div class="step-wrapper row">
                        <div role="form" class="bordered-form col-sm-9">
                            <form noValidate onSubmit={e => { e.preventDefault(); }}>
                                <div class="col-12">
                                    <div class="wrapper-block search-tabs agency">
                                        <h4 class="mb-15">First, let's find your Agency.</h4>
                                        <p class="mb-35">If you can't locate your agency, contact your administrator.</p>
                                        <div class="form-row mb-30">
                                            <div class="col-5 col-md-8">
                                                <div id="agency_name_wrapper" class="form-group aep-custom moveable-label agency-name-wrapper" style={{ marginBottom: 0 }}>
                                                    <div class="input-group">
                                                        <Autocomplete
                                                            id="agency-name"
                                                            style={{ width: '100%' }}
                                                            name="agency_name"
                                                            options={this.state.search["options"]}
                                                            autoHighlight
                                                            clearText=''
                                                            clearOnEscape={false}
                                                            value={this.state.search["searchTextfield"]}
                                                            onChange={(event, newValue) => {
                                                                this.handleSearchName(newValue);
                                                            }}
                                                            getOptionLabel={option => option.name ? (option.name + " - " + option.location + " @ " + option.address) : option}
                                                            isOptionEqualToValue={(option, value) => option.username === value.username}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" className='MuiAutocomplete-option'>
                                                                    <IconAutoComplete />
                                                                    <div {...props} class="ml-10">
                                                                        <span class="text-bold">{option.name}</span>
                                                                        <span class="text-bold">-&nbsp;{option.location}&nbsp;</span>
                                                                        <p>{option.address}</p>
                                                                    </div>
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Agency Name"
                                                                    variant="outlined"
                                                                    name="Search Text Field"
                                                                    value={this.state.search["searchTextfield"]}
                                                                    onChange={
                                                                        this.handleSearchTextFieldAgencyName
                                                                    }
                                                                    inputProps={{
                                                                        ...params.inputProps // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {this.state.search["errors"]["searchTextfield"] !== '' && (<span id="agency_name_span" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_agency_name" >{this.state.search["errors"]["searchTextfield"]}</span>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="button-block col-12">
                                    {this.state.activeStep === this.state.steps.length ? null : (
                                        <div class="row">
                                            <div class="col">
                                                {this.state.activeStep > this.state.prevStep && (<button class="btn btn-secondary mr-20" onClick={this.handleBack}>Previous</button>)}
                                                <button class="btn btn-primary" onClick={this.handleNext} disabled={this.state.search["isDisabledSearchFlag"] || Object.keys(this.state.search["touched"]).length !== 1}>
                                                    {this.state.nextButtonText}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                        <div role="form" class="bordered-form col-sm-3 register-right-section">
                            <div class="center-align-flex">
                                <div class="text-align-center">
                                    <div class="image-poster mb-10">
                                        <img src={Confirm_Agency_Logo} alt="Confirm Agency" style={{ width: '48px' }} />
                                    </div>
                                    <div class="image-side-block">
                                        <span class="text-uppercase text-bolder">Next Step:</span>
                                        <p>Confirm Agency</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div class="step-wrapper row">
                        <div role="form" class="bordered-form col-sm-9">
                            <div class="col-12">
                                <div class="wrapper-block">
                                    <h4 class="mb-35">We've located a matching agency.</h4>
                                    <p class="text-bold text-uppercase mb-0">{this.state.search["storeAgencyInfoToSendService"].name}</p>
                                    <p class="text-bold text-uppercase mb-0">{this.state.search["storeAgencyInfoToSendService"].location}</p>
                                    <p class="text-bold text-uppercase mb-0">{this.state.search["storeAgencyInfoToSendService"].street_address1}&nbsp;{this.state.search["storeAgencyInfoToSendService"].street_address2 !== 'None' && (this.state.search["storeAgencyInfoToSendService"].street_address2)}</p>
                                    <p class="text-bold text-uppercase mb-30">{this.state.search["storeAgencyInfoToSendService"].city}&nbsp;{this.state.search["storeAgencyInfoToSendService"].state}&nbsp;{this.state.search["storeAgencyInfoToSendService"].zip_code}</p>
                                </div>
                                <p class="mb-40">Is this the correct agency?</p>
                            </div>
                            <div class="button-block col-12">
                                {this.state.activeStep === this.state.steps.length ? null : (
                                    <div class="row">
                                        <div class="col">
                                            <button class="btn btn-secondary mr-20" onClick={this.handleBack}>No, search again</button>
                                            <button class="btn btn-primary" onClick={this.handleNext}>
                                                {this.state.nextButtonText}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div role="form" class="bordered-form col-sm-3 register-right-section">
                            <div class="center-align-flex">
                                <div class="text-align-center">
                                    <div class="image-poster mb-10">
                                        <img src={Review_Info_Logo} alt="Review Info" style={{ width: '48px' }} />
                                    </div>
                                    <div class="image-side-block">
                                        <span class="text-uppercase text-bolder">Next Step:</span>
                                        <p>Review Info</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
            case 3:
                return (<div class="step-wrapper row">
                    <div role="form" class="bordered-form col-sm-9 agency-review">
                        <div class="col-12">
                            <div class="wrapper-block">
                                <div class="review_form_header width-adjustment">
                                    <h4 class="mb-15">Review your registration details.</h4>
                                    <p class="mb-35">Unless you need to make changes, hit submit registration.</p>
                                </div>
                                <div class="wrapper-body">
                                    <div class="review_form_subheader width-adjustment">
                                        <div class="sub-block mb-20">
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first text-bold">Account Details:</p>
                                                <p class="second text-align-right">
                                                    <button class="btn button-without-border" onClick={this.editDetails} style={{
                                                        padding: '0', marginLeft: 0, marginTop: '-3px', marginRight: 0, fontSize: '15px',
                                                        textTransform: 'none', lineHeight: 'initial', borderWidth: 0
                                                    }}>Edit</button>
                                                </p>
                                            </div>
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first">Name:</p>
                                                <p class="second text-align-right">
                                                    <span> {this.state.fields["first_name"]}&nbsp;{this.state.fields["last_name"]} </span>
                                                </p>
                                            </div>
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first">Role:</p>
                                                <p class="second text-align-right">
                                                    <span> {this.state.fields["agency_role"]}</span>
                                                </p>
                                            </div>
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first">Email:</p>
                                                <p class="second text-align-right">
                                                    <span>{this.state.fields["email"]} </span>
                                                </p>
                                            </div>
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first">Phone:</p>
                                                <p class="second text-align-right">
                                                    <span>{addHypensPhonenumber(this.state.fields["phone_num"])}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="divider-sm mb-30" />
                                    <div class="review_form_subheader width-adjustment">
                                        <div class="sub-block mb-20">
                                            <div class="two-block agency-register display-inline-block">
                                                <p class="first text-bold">Agency:</p>
                                                <p class="second text-align-right">
                                                    <button class="btn button-without-border" onClick={this.editSearch} style={{
                                                        fontSize: '15px', padding: '0', marginLeft: 0, marginTop: '-3px', marginRight: 0,
                                                        textTransform: 'none', lineHeight: 'initial', borderWidth: 0
                                                    }}>Edit</button>
                                                </p>
                                            </div>
                                            <div class="two-block agency-register reverse display-inline-block">
                                                <p class="first">
                                                    <span class="display-inline-block">{this.state.search["storeAgencyInfoToSendService"].name}</span>
                                                    <span class="display-inline-block">{this.state.search["storeAgencyInfoToSendService"].location}</span>
                                                    <br />
                                                    <span class="display-inline-block">{this.state.search["storeAgencyInfoToSendService"].street_address1}&nbsp;</span>
                                                    <span class="display-inline-block">{this.state.search["storeAgencyInfoToSendService"].street_address2 !== 'None' && (this.state.search["storeAgencyInfoToSendService"].street_address2)}</span>
                                                    <span class="display-inline-block">{this.state.search["storeAgencyInfoToSendService"].city}&nbsp;{this.state.search["storeAgencyInfoToSendService"].state}&nbsp;{this.state.search["storeAgencyInfoToSendService"].zip_code}
                                                    </span>
                                                </p>
                                                <p class="second">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="button-block col-12">
                            {this.state.activeStep === this.state.steps.length ? null : (
                                <div class="row">
                                    <div class="col">
                                        <button class="btn btn-primary" onClick={this.handleNext} disabled={this.state.review["isDisabledReviewRegisterFlag"]}>
                                            {this.state.nextButtonText}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <FormErrors formerrors={this.state.review["errorsCommon"]} />
                        </div>
                    </div>
                    <div role="form" class="bordered-form col-sm-3 register-right-section">
                        <div class="center-align-flex">
                            <div class="text-align-center">
                                <div class="image-poster mb-10">
                                    <img src={Verify_Email_Logo} alt="Verify Email &amp; Approval" style={{ width: '48px' }} />
                                </div>
                                <div class="image-side-block">
                                    <span class="text-uppercase text-bolder">Next Step:</span>
                                    <p>Verify Email &amp; Approval</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
            case 4:
                return (<div class="step-wrapper row">
                    <div role="form" class="bordered-form col-sm-9">
                        <div class="col-12">
                            <h4 class="mb-40">You're almost done!</h4>
                            <div class="wrapper-block mb-15">
                                <p>Next, please verify your email address by selecting the link in the message we sent to </p>
                                <span class="text-bold"> {this.state.fields["email"]}.</span>
                            </div>
                            <div class="wrapper-sub-block">
                                <p> <span class="text-bold mr-2">Email incorrect?</span>
                            You must <a href="/register">register again </a>with the correct email.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div role="form" class="bordered-form col-sm-3 register-right-section">
                        <div class="center-align-flex">
                            <div class="text-align-center">
                                <div class="image-poster mb-10">
                                    <img src={Account_Access_Logo} alt="Account Access" style={{ width: '48px' }} />
                                </div>
                                <div class="image-side-block">
                                    <span class="text-uppercase text-bolder">Next Step:</span>
                                    <p>Account Access</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>);
            default:
                return 'N/A';
        }
    }

    render() {
        return (
            <section class="section auth">

                <div class="container">
                    <div class="row mb-20">
                        <h2>Register an Online Account</h2>
                    </div>
                    <Stepper activeStep={this.state.activeStep} alternativeLabel connector={<this.ColorlibConnector />}>
                        {this.state.steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {this.state.activeStep === this.state.steps.length ? null : (
                        <div class="step-content">
                            {this.getStepContent(this.state.activeStep)}
                        </div>
                    )}
                </div>
            </section>
        )
    }
}

export default Register;

// tab code for future purposes
/* <Tabs defaultActiveKey="agency-state-search">
                                        <Tab eventKey="agency-state-search" title={
                                            <div>
                                                <img src={Search_Agency_State_Logo} alt="Agency Search by State" style={{ width: '25px', marginRight: '5px' }} />
                                                <span>State</span>
                                            </div>}>
                                            <form onSubmit={this.agencyStateSearchFunction} class="mt-20">
                                                <div class="row mb-20">
                                                    <div class="col-5">
                                                        <div className="aep-custom form-group">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="accnum"
                                                                    aria-describedby="accHelp"
                                                                    placeholder="Account Number"
                                                                    value={this.state.accnum}
                                                                    onChange={this.onInputChange}
                                                                    onKeyPress={this.isNumberKey}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.account_lookup_error_display &&
                                                    <div className="row">
                                                        <div className="col-6 error">
                                                            <p>Could not locate account</p>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button className="btn btn-primary">
                                                            Continue
                                                                </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </Tab>
                                        <Tab eventKey="agency-number-search" title={
                                            <div>
                                                <img src={Search_Agency_State_Logo} alt="Agency Search by State" style={{ width: '25px', marginRight: '5px' }} />
                                                <span>Agency #</span>
                                            </div>
                                        }>
                                            <form onSubmit={this.agencyNumberSearchFunction} class="mt-20">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <div className="aep-custom form-group">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="ssn"
                                                                    aria-describedby="accHelp"
                                                                    placeholder="Social Security Number"
                                                                    value={this.state.ssn}
                                                                    onChange={this.onInputChange}
                                                                    onKeyPress={this.isNumberKey}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div className="aep-custom form-group">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="lastname"
                                                                    aria-describedby="accHelp"
                                                                    placeholder="Customer Last Name"
                                                                    value={this.state.lastname}
                                                                    onChange={this.onInputChange}
                                                                    onKeyPress={this.isNumberKey}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.ssn_lookup_error_display &&
                                                    <div className="row">
                                                        <div className="col-6 error">
                                                            <p>Could not locate matching accounts</p>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-6">
                                                        <button className="btn btn-primary">
                                                            Continue
                                                                </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </Tab>
                                        <Tab eventKey="agency-name-search" title={
                                            <div>
                                                <img src={Search_Agency_Name_Logo} alt="Agency Search by Name" style={{ width: '25px', marginRight: '5px' }} />
                                                <span>Agency Name</span>
                                            </div>
                                        }>
                                            <form onSubmit={this.agencyNameSearchFunction} class="mt-20">
                                                <div class="form-row mb-30">
                                                    <div class="col-5 col-md-8">
                                                        <div id="agency_name_wrapper" class="form-group aep-custom moveable-label agency-name-wrapper" style={{ marginBottom: 0 }}>
                                                            <div className="input-group">
                                                                <Select
                                                                    class="form-control"
                                                                    name="agency_name"
                                                                    id="agency_name"
                                                                    placeholder="Agency Name"
                                                                    defaultValue={this.state.search["agency_name"]}
                                                                    onChange={this.handleSearchName}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    required
                                                                    options={this.state.search["options"]}
                                                                    components={{ SingleValue: this.customSingleValue, Option: this.IconOption }}
                                                                />

                                                            </div>
                                                            {this.state.search["errors"]["agency_name"] !== '' && (<span id="agency_name_span" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_agency_name" >{this.state.search["errors"]["agency_name"]}</span>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </Tab>
                                    </Tabs> */